import { ID, Slug, ResourceMeta, Timestamp, Tag, ImageCreate } from "types/common"
import { User, UserID } from "types/user"
import { EventID, NormalizedEvent, Event } from "types/events"
import { NormalizedProfile } from "types/user"
import { ProviderApprovalStatus } from "constants/providers"

export type ProviderID = ID
// export type ProviderApprovalStatus = "pending" | "unapproved" | "denied" | "approved"

export interface ProviderBase {
  name?: string
  slug?: Slug
  description?: string
  website?: string
  // location?: Location
  tags?: Tag[]
  join_code?: string
}
export interface ProviderCreate extends ProviderBase {
  name: string
  image?: ImageCreate
  location?: Location
  allows_registrations?: boolean // Do we actually need this?
}
export interface ProviderUpdate extends ProviderBase {
  image?: ImageCreate
  location?: Location
}
export interface ProviderCore extends ProviderBase {
  id: ProviderID
  name: string
  slug: Slug
  owner: User | UserID
  // description: string
  image?: string
  imgix_src?: string
  location?: Location
  details_submitted?: boolean
  is_active: boolean
  verified: boolean
  approval_status: ProviderApprovalStatus
  created_at: Timestamp
  updated_at: Timestamp
}
export interface ProviderStandard extends ProviderCore {
  admin?: User[] | UserID[]
  members?: User[] | UserID[]
  // events?: Event[] | EventID[]
  // stripe_account?: StripeAccount | StripeID
}
export interface ProviderTruncated extends ProviderStandard {
  owner: UserID
  admin?: UserID[]
  members?: UserID[]
  events?: EventID[]
  // stripe_account?: StripeID
}
export interface NormalizedProvider extends ProviderStandard {
  owner: UserID
  admin?: UserID[]
  members?: UserID[]
  events?: EventID[]
  // stripe_account?: StripeID
}
export interface NormalizedProviderWithEvents extends Omit<NormalizedProvider, "events"> {
  // events?: Array<EventID | Event>
  events?: Array<NormalizedEvent>
}
export interface NormalizedProviderWithEventsAndProfiles
  extends Omit<NormalizedProviderWithEvents, "admin" | "members" | "owner"> {
  admin: NormalizedProfile[]
  members: NormalizedProfile[]
  owner: NormalizedProfile
}
export interface ProviderFull extends ProviderStandard {
  owner: User
  admin?: User[]
  members?: User[]
  events?: Event[]
  // stripe_account?: StripeAccount
}
export type Provider = ProviderFull | NormalizedProvider
// export type Provider = Partial<ProviderFull>

export type ProviderProcessingState = "failed" | null

export interface ProvidersState {
  isLoading: boolean
  creatingState: "creating" | "created" | ProviderProcessingState
  fetchingState: "fetching" | "fetched" | ProviderProcessingState
  updatingState: "updating" | "updated" | ProviderProcessingState
  deletingState: "deleting" | "deleted" | ProviderProcessingState
  error: Error
  data: Record<ProviderID, NormalizedProvider>
  providerSlugToIdLookup: Record<Slug, ProviderID>
  providerSlugToMetaLookup: Record<Slug, ResourceMeta>
}

export enum ProvidersActionType {
  CLEAR_USER_PROVIDER_STATE = "@@providers/CLEAR_USER_PROVIDER_STATE",

  CREATE_PROVIDER = "@@providers/CREATE_PROVIDER",
  CREATE_PROVIDER_SUCCESS = "@@providers/CREATE_PROVIDER_SUCCESS",
  CREATE_PROVIDER_FAILURE = "@@providers/CREATE_PROVIDER_FAILURE",

  FETCH_PROVIDER = "@@providers/FETCH_PROVIDER",
  FETCH_PROVIDER_SUCCESS = "@@providers/FETCH_PROVIDER_SUCCESS",
  FETCH_PROVIDER_FAILURE = "@@providers/FETCH_PROVIDER_FAILURE",

  FETCH_PROVIDER_BY_ID = "@@providers/FETCH_PROVIDER_BY_ID",
  FETCH_PROVIDER_BY_ID_SUCCESS = "@@providers/FETCH_PROVIDER_BY_ID_SUCCESS",
  FETCH_PROVIDER_BY_ID_FAILURE = "@@providers/FETCH_PROVIDER_BY_ID_FAILURE",

  UPDATE_PROVIDER = "@@providers/UPDATE_PROVIDER",
  UPDATE_PROVIDER_SUCCESS = "@@providers/UPDATE_PROVIDER_SUCCESS",
  UPDATE_PROVIDER_FAILURE = "@@providers/UPDATE_PROVIDER_FAILURE",

  SOFT_DELETE_PROVIDER = "@@providers/SOFT_DELETE_PROVIDER",
  SOFT_DELETE_PROVIDER_SUCCESS = "@@providers/SOFT_DELETE_PROVIDER_SUCCESS",
  SOFT_DELETE_PROVIDER_FAILURE = "@@providers/SOFT_DELETE_PROVIDER_FAILURE",

  FETCH_USER_PROVIDERS = "@@providers/FETCH_USER_PROVIDERS",
  FETCH_USER_PROVIDERS_SUCCESS = "@@providers/FETCH_USER_PROVIDERS_SUCCESS",
  FETCH_USER_PROVIDERS_FAILURE = "@@providers/FETCH_USER_PROVIDERS_FAILURE",

  FETCH_PROVIDERS_FROM_SLUG_LIST = "@@providers/FETCH_PROVIDERS_FROM_SLUG_LIST",
  FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS = "@@providers/FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS",
  FETCH_PROVIDERS_FROM_SLUG_LIST_FAILURE = "@@providers/FETCH_PROVIDERS_FROM_SLUG_LIST_FAILURE",

  CLEAR_PROVIDER = "@@providers/CLEAR_PD_PROVIDER",
  RESET_PROCESSING_STATE = "@@providers/RESET_PROCESSING_STATE",
  SET_PROVIDER_AS_CURRENT_PROVIDER = "@@providers/SET_PROVIDER_AS_CURRENT_PROVIDER",
  SET_PROVIDER_FOUR_OH_FOUR_SLUG = "@@providers/SET_PROVIDER_FOUR_OH_FOUR_SLUG",

  USER_PROVIDERS_NEEDS_REFRESH = "@@providers/USER_PROVIDERS_NEEDS_REFRESH",
}

// GENERIC PROVIDER ACTIONS
export interface IClearUserProviderState {
  type: ProvidersActionType.CLEAR_USER_PROVIDER_STATE
}
export interface IClearProvider {
  type: ProvidersActionType.CLEAR_PROVIDER
}
export interface IResetProcessingState {
  type: ProvidersActionType.RESET_PROCESSING_STATE
}
export interface ISetProviderAsCurrentProvider {
  type: ProvidersActionType.SET_PROVIDER_AS_CURRENT_PROVIDER
  data: Provider
}
export interface ISetProviderFourOhFourSlug {
  type: ProvidersActionType.SET_PROVIDER_FOUR_OH_FOUR_SLUG
  slug: string
}
export interface IUserProvidersNeedRefresh {
  type: ProvidersActionType.USER_PROVIDERS_NEEDS_REFRESH
}
export type GenericProviderAction =
  | IClearUserProviderState
  | IClearProvider
  | IResetProcessingState
  | ISetProviderAsCurrentProvider
  | ISetProviderFourOhFourSlug
  | IUserProvidersNeedRefresh

// CREATE PROVIDER
export interface ICreateProvider {
  type: ProvidersActionType.CREATE_PROVIDER
}
export interface ICreateProviderSuccess {
  type: ProvidersActionType.CREATE_PROVIDER_SUCCESS
  data: Provider
}
export interface ICreateProviderFailure {
  type: ProvidersActionType.CREATE_PROVIDER_FAILURE
  error: Error
}
export type CreateProviderAction = ICreateProvider | ICreateProviderSuccess | ICreateProviderFailure

// FETCH PROVIDER
export interface IFetchProvider {
  type: ProvidersActionType.FETCH_PROVIDER
}
export interface IFetchProviderSuccess {
  type: ProvidersActionType.FETCH_PROVIDER_SUCCESS
  data: Provider
}
export interface IFetchProviderFailure {
  type: ProvidersActionType.FETCH_PROVIDER_FAILURE
  error: Error
}
export interface IFetchProviderById {
  type: ProvidersActionType.FETCH_PROVIDER_BY_ID
}
export interface IFetchProviderByIdSuccess {
  type: ProvidersActionType.FETCH_PROVIDER_BY_ID_SUCCESS
  data: Provider
}
export interface IFetchProviderByIdFailure {
  type: ProvidersActionType.FETCH_PROVIDER_BY_ID_FAILURE
  error: Error
}
export type FetchProviderAction =
  | IFetchProvider
  | IFetchProviderSuccess
  | IFetchProviderFailure
  | IFetchProviderById
  | IFetchProviderByIdSuccess
  | IFetchProviderByIdFailure

// FETCH MULTIPLE PROVIDERS ACTIONS
export interface IFetchProvidersFromSlugList {
  type: ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST
}
export interface IFetchProvidersFromSlugListSuccess {
  type: ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS
  data: Provider[]
}
export interface IFetchProvidersFromSlugListFailure {
  type: ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_FAILURE
  error: Error
}
export type FetchProvidersFromSlugListAction =
  | IFetchProvidersFromSlugList
  | IFetchProvidersFromSlugListSuccess
  | IFetchProvidersFromSlugListFailure

// UPDATE PROVIDER
export interface IUpdateProvider {
  type: ProvidersActionType.UPDATE_PROVIDER
}
export interface IUpdateProviderSuccess {
  type: ProvidersActionType.UPDATE_PROVIDER_SUCCESS
  data: Provider
}
export interface IUpdateProviderFailure {
  type: ProvidersActionType.UPDATE_PROVIDER_FAILURE
  error: Error
}
export type UpdateProviderAction = IUpdateProvider | IUpdateProviderSuccess | IUpdateProviderFailure

// SOFT DELETE PROVIDER
export interface ISoftDeleteProvider {
  type: ProvidersActionType.SOFT_DELETE_PROVIDER
}
export interface ISoftDeleteProviderSuccess {
  type: ProvidersActionType.SOFT_DELETE_PROVIDER_SUCCESS
  data: Provider
}
export interface ISoftDeleteProviderFailure {
  type: ProvidersActionType.SOFT_DELETE_PROVIDER_FAILURE
  error: Error
}
export type SoftDeleteProviderAction = ISoftDeleteProvider | ISoftDeleteProviderSuccess | ISoftDeleteProviderFailure

// FETCH USER PROVIDERS
export interface IFetchUserProviders {
  type: ProvidersActionType.FETCH_USER_PROVIDERS
}
export interface IFetchUserProvidersSuccess {
  type: ProvidersActionType.FETCH_USER_PROVIDERS_SUCCESS
  data: Provider[]
}
export interface IFetchUserProvidersFailure {
  type: ProvidersActionType.FETCH_USER_PROVIDERS_FAILURE
  error: Error
}
export type FetchUserProvidersAction = IFetchUserProviders | IFetchUserProvidersSuccess | IFetchUserProvidersFailure

export type ProvidersAction =
  | GenericProviderAction
  | CreateProviderAction
  | FetchProviderAction
  | UpdateProviderAction
  | SoftDeleteProviderAction
  | FetchUserProvidersAction
  | FetchProvidersFromSlugListAction

export type SingleProviderSuccessAction = IFetchProviderSuccess | ICreateProviderSuccess | IUpdateProviderSuccess
// | ISoftDeleteProviderSuccess

export type SingleProviderFailureAction = IFetchProviderFailure | ICreateProviderFailure | IUpdateProviderFailure
// | ISoftDeleteProviderFailure

export type MultipleProvidersSuccessAction = IFetchProvidersFromSlugListSuccess
