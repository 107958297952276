import * as R from "ramda"
import { useRouter } from "next/router"
import { Card } from "components"
import styled from "styled-components"
// import media from "utils/mediaQueryTemplates"

import { exploreOptions } from "constants/data-explorer"

import { URL_TREE, constructPathFromUrlTree } from "utils/urls"

const DataExplorerOptionsCard = styled(Card)`
  padding: 2rem;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  width: 900px;
  margin: 0 auto;
  max-width: var(--mobile-width);
`

const KeyPadGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  margin-top: 1rem;
`

const KeyPadItem = styled.div<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 1rem;
  border: solid 1px var(--background-color-very-light-blue);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-align: center;

  &:hover {
    box-shadow: var(--elevation-2);
  }
  &:active {
    transform: translateY(1px);
  }

  ${(props) =>
    props.$isActive
      ? `
  box-shadow: var(--elevation-2);
`
      : null}
`

export default function DataExplorerKeyPad() {
  const router = useRouter()

  return (
    <DataExplorerOptionsCard>
      <h3>What are you interested in?</h3>
      <KeyPadGrid>
        {exploreOptions.map((opt) => (
          <KeyPadItem
            role="button"
            key={opt.path}
            onClick={() => router.push(`${constructPathFromUrlTree(URL_TREE.data_explorer)}${opt.path}/`)}
            $isActive={R.includes(opt.path, router.pathname)}
          >
            {/* <i>{opt.icon}</i> */}
            <span>{opt.label}</span>
          </KeyPadItem>
        ))}
      </KeyPadGrid>
      <div id="teacher-compensation-viz" />
    </DataExplorerOptionsCard>
  )
}
