import { useState, SyntheticEvent } from "react"
import {
  Button,
  Card,
  // IconQuestionnaireCheck,
  // Input,
  // Spinner,
  IconEmail,
  IconUserCircle,
  ReactHookFormInput,
  ReactHookFormTextArea,
  Spacer,
  Spinner,
} from "components"
import { useForm } from "react-hook-form"

import styled from "styled-components"

import { sleep } from "utils/async"

// import media from "utils/mediaQueryTemplates"

const ContactUsCard = styled(Card)`
  padding: 2rem;
  display: flex;
  flex-direction: column;

  width: 40rem;
  max-width: 95vw;

  margin: 0 1rem;
  border-radius: 0.25rem;
  position: relative;
  z-index: var(--z-index-small);
  border: solid 1px rgb(222, 222, 222);

  & form {
    display: flex;
    flex-direction: column;

    & button {
      margin-top: 20px;
    }
  }
`
const StyledUserCircle = styled((props) => <IconUserCircle {...props} />)`
  height: 24px;
  width: 24px;
`
const StyledH4 = styled.h4`
  position: relative;
  z-index: var(--z-index-small);
`

interface ContactUsFormState {
  email: string
  name: string
  message: string
}

export default function ContactUsForm() {
  // const [name, setName] = useState("")
  // const [email, setEmail] = useState("")
  // const [message, setMessage] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [, handlers] = useToasts()
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<ContactUsFormState>({
    mode: "onBlur",
  })

  const handleOnError = async (errors) => {
    await sleep(500)
    console.log({ errors })
  }

  const handleOnSubmit = async (e: SyntheticEvent) => {
    setIsLoading(true)

    await sleep(500)

    setTimeout(() => {
      setIsLoading(false)
      setSubmitted(true)
    }, 1000)

    // console.log("submit contact us form")
    // setState({ ...state, isLoading: true })

    // setTimeout(() => setState({ ...state, submitted: true, isLoading: false }), 1000)
  }

  if (isLoading) {
    return (
      <ContactUsCard>
        <Spinner />
      </ContactUsCard>
    )
  }

  const renderContactUsCardContents = () => {
    if (submitted) {
      return (
        <section>
          {/* <IconQuestionnaireCheck /> */}
          <h2>Thanks for reaching out!</h2>
          <Spacer size="md" />
          <p>Expect a response from us as soon as possible.</p>
        </section>
      )
    }

    return (
      <>
        <StyledH4>We'd love to hear from you.</StyledH4>
        <form onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
          <Spacer size="xs" />
          <ReactHookFormInput
            fullWidth
            name="name"
            type="text"
            label="Name"
            iconLeft={<StyledUserCircle strokeWidth={4} stroke="var(--font-color-subdued)" />}
            register={{ ...register("name", { required: "Name is required" }) }}
            providedError={errors?.name?.message}
          />
          <Spacer size="xs" />
          <ReactHookFormInput
            fullWidth
            name="email"
            type="email"
            label="Email"
            iconLeft={<IconEmail stroke="var(--font-color-subdued)" />}
            register={{ ...register("email", { required: "Email is required" }) }}
            providedError={errors?.email?.message}
          />
          <Spacer size="lg" />
          <ReactHookFormTextArea
            name="message"
            label="What can we do for you?"
            register={{ ...register("message", { required: "Leave a message" }) }}
            resize="none"
            providedError={errors?.message?.message}
          />
          <Button type="submit">Submit</Button>
        </form>
      </>
    )
  }

  return (
    <ContactUsCard>
      <>{renderContactUsCardContents()}</>
    </ContactUsCard>
  )
}
