// import { useState, Fragment } from "react"
import Link from "next/link"
import { motion } from "framer-motion"
import { useReviewCreateForm } from "hooks/reviews/useReviewCreateForm"
import { Button, Expander, IconSuccess, IconComment, SmallRedText, Spacer, Stars, StarsInput } from "components"
import styled from "styled-components"

import { URLS } from "utils/urls"

import { Event } from "types/events"
import { Review } from "types/reviews"

const ReviewBoxInput = styled.div<{ $isFocused: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 200px;
  background: white;
  border: solid 1px rgb(222, 222, 222);
  width: 100%;
  padding: 1rem 2rem;
  border-radius: var(--border-radius-md);
  position: relative;
  z-index: 1;

  & svg {
    transform: translateY(0);
    transition: all 0.3s ease;
  }

  & textarea {
    transform: translateY(2rem);
    margin-left: 1rem;
    flex: 1;
    border: none;
    background: transparent;
    outline: none !important;
    resize: none;
    height: 100px;
    transition: all 0.3s ease;
  }

  ${(props) =>
    props.$isFocused
      ? `
  & svg {
    transform: translateY(-3rem);
  }
  & textarea {
    transform: translateY(-1rem);
  }
`
      : ``}
`

const SubmissionContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
`
const ErrorContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
`
const StyledExpander = styled((props) => <Expander {...props} />)`
  width: calc(100% - 2rem);
  position: relative;
  z-index: 0;
  border: solid 1px rgb(222, 222, 222);
`
const TopExpander = styled((props) => <StyledExpander {...props} />)`
  margin: 0 auto;
  margin-bottom: -2px;
  padding-bottom: 0px;
`
const BotExpander = styled((props) => <StyledExpander {...props} />)`
  margin: -10px auto;
  padding-top: 10px;
`
const ReviewSuccessText = styled.p`
  font-size: 26px;
  margin-left: 10px;
`

interface IReviewBox {
  event?: Event
  userReviewForEvent?: Review
}
const ReviewBox: React.FC<IReviewBox> = ({ event, userReviewForEvent }) => {
  const {
    submitReview,
    isCreating,
    reviewText,
    setReviewText,
    headline,
    setHeadline,
    stars,
    setStars,
    isFocused,
    isExpanded,
    handleOnBlur,
    handleOnFocus,
    errors,
    setErrors,
  } = useReviewCreateForm({ event })

  const handleOnStarChange = (rating: number) => {
    setErrors((e) => ({ ...e, stars: null }))
    setStars(rating)
  }

  const handleOnTextChange = (text: string) => {
    setErrors((e) => ({ ...e, reviewText: null }))
    setReviewText(text)
  }

  const handleOnSubmit = () => {
    if (!stars) {
      setErrors((e) => ({ ...e, stars: `Please include a rating 1 through 5.` }))
      return
    }
    if (!reviewText) {
      setErrors((e) => ({ ...e, reviewText: `Please include a review.` }))
      return
    }
    const reviewCreate = {
      rating: stars,
      comment: reviewText,
      headline,
    }

    submitReview(reviewCreate)
  }

  const hasErrors = errors?.stars || errors?.reviewText

  const renderError = () =>
    hasErrors ? <SmallRedText>{errors?.stars || errors?.reviewText}</SmallRedText> : <SmallRedText>&nbsp;</SmallRedText>

  if (Boolean(userReviewForEvent)) {
    return (
      <ReviewBoxInput $isFocused={false}>
        <IconSuccess stroke="var(--success-300)" />
        <ReviewSuccessText>
          Review submitted. View it <Link href={URLS.reviews_by_id(userReviewForEvent.id)}>here</Link>.
        </ReviewSuccessText>
      </ReviewBoxInput>
    )
  }

  return (
    <>
      <TopExpander isExpanded={hasErrors}>
        <ErrorContainer>{renderError()}</ErrorContainer>
      </TopExpander>
      <ReviewBoxInput $isFocused={isFocused}>
        <IconComment />
        <textarea
          name="review"
          value={reviewText}
          onChange={(e) => handleOnTextChange(e.target.value)}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          // type="text"
          placeholder="Write a review..."
        />
      </ReviewBoxInput>
      <BotExpander isExpanded={isExpanded}>
        <SubmissionContainer>
          <StarsInput value={stars} setValue={handleOnStarChange} max={5} name="event-review-box" />
          <Button isLoading={isCreating} buttonType="primary" onClick={handleOnSubmit}>
            Submit
          </Button>
        </SubmissionContainer>
      </BotExpander>
    </>
  )
}

export default ReviewBox
