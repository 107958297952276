import * as R from "ramda"

import { Profile, ProfileID } from "types/user"
import { NormalizedProvider, NormalizedProviderWithEvents, Provider, ProviderFull } from "types/providers"
import { isProviderInstance } from "utils/providers"
// import { User } from "types/auth"
import { User, NormalizedProfile } from "types/user"

import { isStringOrNumber } from "utils/parseUtils"

const formatter = new Intl.NumberFormat("en-US", {
  // style: "currency",
  // currency: "USD",
})

export const formatFollowerAndFollowingCounts = (count) => {
  if (count >= 10 ** 9) {
    return String(count)[0] + "." + String(count)[1] + String(count)[2] + "B"
  }
  if (count >= 10 ** 8) {
    return String(count)[0] + String(count)[1] + String(count)[2] + "." + String(count)[3] + "m"
  }
  if (count >= 10 ** 7) {
    return String(count)[0] + String(count)[1] + "." + String(count)[2] + "m"
  }
  if (count >= 10 ** 6) {
    return String(count)[0] + "." + String(count)[1] + String(count)[2] + "m"
  }
  if (count >= 10 ** 5) {
    return String(count)[0] + String(count)[1] + String(count)[2] + "." + String(count)[3] + "k"
  }
  if (count >= 10 ** 4) {
    return String(count)[0] + String(count)[1] + "." + String(count)[2] + "k"
  }
  if (count >= 10 ** 3) {
    return String(count)[0] + "." + String(count)[1] + "k"
  }

  return formatter.format(count)
}

export const isProfileInstance = (profile: any): profile is Profile => {
  if (!profile) return false

  if (isStringOrNumber(profile)) return false

  if (
    R.all(
      (p) => profile.hasOwnProperty(p) // checks that profile contains all required
    )(["id", "user_id", "bio", "is_social", "email", "username"])
  ) {
    return true
  }

  return false
}

export const normalizeProfile = (profile: Profile): NormalizedProfile => {
  return {
    ...profile,
    event_previews: R.map((e) => e.id, profile.event_previews || []),
  }
}

export const extractProfileIdsFromNormalizedProvider = (
  provider: NormalizedProvider | NormalizedProviderWithEvents
): { admin: ProfileID[]; members: ProfileID[]; owner: ProfileID } => {
  const values = R.pick(["admin", "members", "owner"], provider)

  return {
    admin: values.admin ?? [],
    members: values.members ?? [],
    owner: values.owner,
  }
}

export const extractListOfProfileIdsFromNormalizedProvider = (
  provider: NormalizedProvider | NormalizedProviderWithEvents
): ProfileID[] => {
  const { admin, members, owner } = extractProfileIdsFromNormalizedProvider(provider)

  return [...admin, ...members, owner]
}

export const extractUserProfilesFromProvider = (
  provider: Provider | NormalizedProvider | NormalizedProviderWithEvents
): Profile[] => {
  if (!isProviderInstance(provider)) return []

  return R.filter(
    (p) => isProfileInstance(p),
    R.map(
      (user) => (isStringOrNumber(user.profile) ? null : user.profile),
      // extract a list of users from admin and members properties
      R.concat(
        R.values(R.pick(["admin", "members"], provider)).flat(),
        // concat them with the provider owner in an array
        [R.prop("owner", provider)]
      ) as User[] // cast them as user objects
    )
  )
}

export const extractNormalizedUserProfilesFromProvider = (provider: ProviderFull): NormalizedProfile[] => {
  return R.map((profile) => normalizeProfile(profile), extractUserProfilesFromProvider(provider))
}
