import styled from "styled-components"

const SearchFiltersWrapper = styled.div``

export default function SearchFilters() {
  return (
    <SearchFiltersWrapper>
      <>SearchFilters</>
    </SearchFiltersWrapper>
  )
}
