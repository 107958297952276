// import { useState } from "react"
import { useRouter } from "next/router"
import { Chip, Card, MarkdownDisplay } from "components"
import { EventImage } from "views"
import { truncate } from "utils/format"
import { formatDate } from "utils/dates"
import { hsl as d3Hsl } from "d3-color"
import styled from "styled-components"

import { URLS } from "utils/urls"

import { ChipType } from "types/ui"

const EventFeedCardWrapper = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: row;
  min-height: 250px;
  max-height: 275px;
  padding: 0.5rem 1rem;
  cursor: pointer;
`
const StyledEventImage = styled((props) => <EventImage {...props} />)`
  flex: 1;
  max-height: 200px;
  max-width: 200px;
`
const EventFeedCardBody = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  margin-left: 20px;

  & > h3 {
    margin-top: 1rem;
  }
  & p {
    font-size: 16px;
  }
`
const EventFeedCardMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`
const MetaChip = styled((props) => <Chip {...props} />)`
  margin-left: 0.5rem;
  padding-top: 3px;
  padding-bottom: 3px;

  &.accent {
    background: ${String(d3Hsl("#eb568c").brighter(0.15))};
    color: white;
  }
`

export default function EventFeedCard({ event }) {
  const router = useRouter()

  const renderDateRange = () => {
    if (event.ongoing) return <MetaChip text="Ongoing" chipType={ChipType.SECONDARY} chipSize="sm" />
    if (!event.date) return null

    return <MetaChip text={formatDate(new Date(event.date))} chipType={ChipType.SECONDARY} chipSize="sm" />
  }

  const renderPrice = () => {
    if (Number(event.price) === 0)
      return <MetaChip className="accent" text={`FREE`} chipType={ChipType.SECONDARY} chipSize="sm" />

    return <MetaChip className="accent" text={`$${event.price}`} chipType={ChipType.SECONDARY} chipSize="sm" />
  }

  const renderVirtual = () => {
    if (event.virtual) {
      return <MetaChip text={"Virtual"} chipType={ChipType.PRIMARY} chipSize="sm" />
    }

    return null
  }

  return (
    <EventFeedCardWrapper onClick={() => router.push(URLS.event_by_slug(event.slug))}>
      <StyledEventImage event={event} />
      <EventFeedCardBody>
        <h3>{event.name}</h3>
        <MarkdownDisplay source={truncate(event.description, 140, true)} />
        <EventFeedCardMeta>
          <span className="dates">{renderDateRange()}</span>
          <span className="price">{renderPrice()}</span>
          <span className="virtual">{renderVirtual()}</span>
        </EventFeedCardMeta>
      </EventFeedCardBody>
    </EventFeedCardWrapper>
  )

  // return (
  //   <EventFeedCardWrapper onClick={() => router.push(`/events/s/${event.slug}/`)}>
  //     <StyledEventImage event={event} />
  //     <EventFeedCardBody>
  //       <h3>{event.name}</h3>
  //       <MarkdownDisplay source={truncate(event.description, 140, true)} />
  //       <EventFeedCardMeta>
  //         <span className="dates">{renderDateRange()}</span>
  //         <span className="price">{renderPrice()}</span>
  //         <span className="virtual">{renderVirtual()}</span>
  //       </EventFeedCardMeta>
  //     </EventFeedCardBody>
  //   </EventFeedCardWrapper>
  // )
}
