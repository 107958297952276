import styled from "styled-components"

const ProviderCatalogWrapper = styled.div``

export default function ProviderCatalog() {
  return (
    <ProviderCatalogWrapper>
      <>ProviderCatalog</>
    </ProviderCatalogWrapper>
  )
}
