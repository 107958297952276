import styled from "styled-components"

const LandingEventsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export default function LandingEvents() {
  return (
    <LandingEventsWrapper>
      <>events gallery</>
    </LandingEventsWrapper>
  )
}
