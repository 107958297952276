import { useState } from "react"
import { Badge, Button, Dropdown } from "components"
import styled from "styled-components"

import { BadgeType } from "types/ui"

const SearchOptionsWrapper = styled.div`
  width: 100%;

  .row {
    display: flex;
    align-items: center;

    &.split {
      justify-content: space-between;
    }
  }
`

export default function SearchOptions() {
  const [state, setState] = useState({
    searchType: "Events",
  })
  return (
    <SearchOptionsWrapper>
      <div className="row split">
        <h2>All {state.searchType}</h2>

        <div className="row">
          {["Events", "Providers", "People"].map((searchType) => (
            <Badge
              badgeType={BadgeType.PRIMARY}
              text={searchType}
              key={searchType}
              onClick={() => setState((s) => ({ ...s, searchType }))}
            />
          ))}
        </div>
      </div>
      <div className="row"></div>
    </SearchOptionsWrapper>
  )
}
