// import Link from "next/link"
// import { useRouter } from "next/router"
import { formatDate } from "utils/dates"
import { truncate } from "utils/format"
import { Avatar, Card, IconSuccess, MarkdownDisplay, Spacer, Stars, UserAvatar } from "components"
import styled from "styled-components"

import { Review } from "types/reviews"

const ReviewCardWrapper = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: row;
  border-radius: 0.25rem;
  padding: 1rem 2rem 1rem 1rem;
`
const ReviewCardMeta = styled.div`
  display: flex;
  align-items: center;
  color: var(--font-color-subdued-header);
  font-size: 15px;
`
const ReviewUsername = styled.p`
  font-weight: bold;
  color: var(--color-primary);
  font-size: 22px;
`
const ReviewBody = styled.div`
  margin: 5px 0;
`

const AvatarColumn = styled.div<{ $size: "sm" | "md" | "lg" }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${(props) => `
    ${props.$size === "sm" ? "width: 70px;" : ""}
    ${props.$size === "md" ? "width: 100px;" : ""}
    ${props.$size === "lg" ? "width: 120px;" : ""}
  `}
`
const ContentColumn = styled.div`
  flex: 1;
`
const StyledSuccessIcon = styled((props) => <IconSuccess stroke="var(--color-secondary)" strokeWidth={3} {...props} />)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`

interface IReviewCard {
  review: Review
  size?: "sm" | "md" | "lg"
  maxLength?: number
}
export default function ReviewCard({ review, size = "sm", maxLength }: IReviewCard) {
  const renderReviewCardMeta = () => {
    const reviewDate = formatDate(new Date(review.created_at))
    return (
      <ReviewCardMeta>
        {review.verified ? (
          <>
            {" "}
            <StyledSuccessIcon /> <p>Verified via {review.verified}</p> <Spacer size="sm" />
            <p>{"|"}</p>
            <Spacer size="sm" />
          </>
        ) : null}
        <p>{reviewDate}</p>
      </ReviewCardMeta>
    )
  }

  return (
    <ReviewCardWrapper>
      <AvatarColumn $size={size}>
        {/* <Avatar name={review.username} image={image} avatarSize={size} /> */}
        <UserAvatar
          username={review.username}
          image={review.image}
          imgix_src={review.imgix_src}
          is_social={review.is_social}
          avatarSize={size}
        />
      </AvatarColumn>
      <ContentColumn>
        <ReviewUsername>{review.username}</ReviewUsername>
        {renderReviewCardMeta()}
        <Stars rating={review.rating} max={5} />
        <ReviewBody>
          {/* <h4>{review.headline}</h4> */}
          <MarkdownDisplay source={maxLength ? truncate(review.comment, maxLength, true) : review.comment} />
        </ReviewBody>
      </ContentColumn>
    </ReviewCardWrapper>
  )
}
