import { Card } from "components"
import styled from "styled-components"

const EmptyPromptCardWrapper = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  font-weight: bold;
  border-radius: 0.25rem;
  z-index: var(--z-index-small);
  position: relative;
`

export default function EmptyPromptCard({ children, className = "", ...props }) {
  return (
    <EmptyPromptCardWrapper className={className} {...props}>
      <>{children}</>
    </EmptyPromptCardWrapper>
  )
}
