import { Fragment } from "react"
import { Spacer } from "components"
import { BlogPreviewCard } from "views"
import styled from "styled-components"
import media from "utils/mediaQueryTemplates"

import { BlogPost } from "types/blog"

const LandingBlogPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 650px;
  margin: 2rem 0;
  padding: 2rem var(--page-side-padding);

  & > h3 {
    font-size: 2.4rem;
  }
`
const BlogPreviewList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  ${media.tablet`
  flex-direction: row;  
  `}
`

const defaultBlogPosts = [
  {
    author: {
      name: "Jeff Astor",
      username: "jastornaut",
      image: "astor_teaching.jpeg",
    },
    slug: "first-post",
    cover_image:
      "https://i2.wp.com/digital-photography-school.com/wp-content/uploads/2019/05/joseph-barrientos-49318-unsplash-e1558728034701.jpg?resize=1500%2C1000&ssl=1",
    category: "ed tech",
    title: "Blended Learning",
    created_at: new Date(),
    updated_at: new Date(),
    time_to_read: 4,
    snippet: `Let's explore what a blended learning classroom looks like and why that matters.`,
  },
  {
    author: {
      name: "Jeff Astor",
      username: "jastornaut",
      image: "astor_teaching.jpeg",
    },
    slug: "second-poster",
    cover_image:
      "https://images.unsplash.com/photo-1546188994-07c34f6e5e1b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8ZnV0dXJlfGVufDB8fDB8&ixlib=rb-1.2.1&w=1000&q=80",
    category: "ed tech",
    title: "Flipped Classrooms",
    created_at: new Date(),
    updated_at: new Date(),
    time_to_read: 4,
    snippet: `Video learning has revolutionized how the world approaches knowledge consumption.`,
  },
  {
    author: {
      name: "Jeff Astor",
      username: "jastornaut",
      image: "astor_teaching.jpeg",
    },
    slug: "third-post",
    cover_image:
      "https://images.unsplash.com/photo-1471958680802-1345a694ba6d?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MXx8d2F5fGVufDB8fDB8&ixlib=rb-1.2.1&w=1000&q=80",
    category: "ed tech",
    title: "The Road Ahead",
    created_at: new Date(),
    updated_at: new Date(),
    time_to_read: 4,
    snippet: `What will teaching look like 20 years down the road when our students are grown?`,
  },
]

interface ILandingBlogPreview {
  blogPosts?: BlogPost[]
}
export default function LandingBlogPreview({ blogPosts = defaultBlogPosts }: ILandingBlogPreview) {
  return (
    <LandingBlogPreviewWrapper>
      <h3>Hear from experts in the field</h3>
      <Spacer size="md" />
      <p>Interested in writing a post for us? Send us your thoughts!</p>
      <Spacer size="xl" />
      <BlogPreviewList>
        <Spacer size="xxxl" />
        {blogPosts.map((blogPost) => (
          <Fragment key={blogPost.slug}>
            <BlogPreviewCard blogPost={blogPost} />
            <Spacer size="xxxl" />
          </Fragment>
        ))}
      </BlogPreviewList>
    </LandingBlogPreviewWrapper>
  )
}
