import styled from "styled-components"
import media from "utils/mediaQueryTemplates"

type ContainerHeights = {
  [key: string]: string
}

const Background = styled.div`
  display: flex;
  align-items: center;
  background: rgb(255, 252, 240);
  flex-flow: column nowrap;
  padding: 0 3rem 3rem 3rem;

  @media (max-width: 414px) {
    padding: 0 1rem 3rem 1rem;
  }
  @media (max-width: 321px) {
    padding: 0 0.5rem 2rem 0.5rem;
  }
`
const TermsWrapper = styled.div`
  max-width: 100vw;
  margin: 0 auto;

  ${media.medium`
    max-width: 1050px;
  `}
`
const Header = styled.h2`
  font-size: 2.5rem;
  font-weight: 300;
  padding: 2rem 0;
  padding-top: 6rem;

  @media (max-width: 414px) {
    font-size: 2.2rem;
  }
  @media (max-width: 321px) {
    font-size: 1.9rem;
    padding: 1.3rem 0;
  }
`
const Intro = styled.div`
  font-size: 1rem;
  padding: 1rem 0;
`
// const Container = styled.div`
//   display: flex;
//   flex-flow: column wrap;
//   height: 8000px;
// `
const EmulatedFlexGap = styled.div`
  --gap: 22px;
  display: inline-flex;
  flex-wrap: wrap;
  margin: calc(-1 * var(--gap)) 0 0 calc(-1 * var(--gap));
  width: calc(100% + var(--gap));

  & > * {
    margin: var(--gap) 0 0 var(--gap);
  }
`
const Container = styled(EmulatedFlexGap)<{
  $containerHeight: number
  $containerHeights: ContainerHeights
  $alwaysFull?: boolean
}>`
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  max-width: 100vw;

  ${(props) =>
    props.$alwaysFull
      ? `
    /* No need to set heights, it's always 100% */
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-width: 100vw;
    height: 100%
    margin: 0;

    & > * {
      margin: 1rem;
    }

    & p {
      width: 100% !important;
    }

  `
      : `
    @media screen and (min-width: 1100px) {
      flex-flow: column wrap;
      ${props.$containerHeights?.large ? `height: ${props.$containerHeights.large};` : null}
    }

    ${
      Object.keys(props.$containerHeights).length
        ? Object.keys(props.$containerHeights)
            .filter((bp) => bp !== "large")
            .map((bp) =>
              media?.[bp]
                ? media[bp]`
      height: ${props.$containerHeights[bp]};
    `
                : null
            )
        : `
      height: ${props.$containerHeight}px;
    `
    }
  `}
`
const Paragraph = styled.p`
  text-align: justify;
  color: #333;
  line-height: 1.3;
  box-sizing: border-box;
  padding: 0.5rem 0;
  width: 50%;
  max-width: 100vw;

  @media (max-width: 1100px) {
    width: 90%;
  }
  @media (max-width: 321px) {
    font-size: 0.8em;
  }
`
const Number = styled.strong`
  color: #666;
  padding-right: 15px;
`
const Bold = styled.strong`
  font-weight: bold;
`
const Underline = styled.span`
  text-decoration: underline;
  padding-right: 5px;
`

// NEED TO HANDLE TERMS AND CONDITIONS FOR SCREEN SIZES SMALLER THAN 1300
interface ITermsAndConditions {
  containerHeight?: number
  containerHeights?: ContainerHeights
  alwaysFull?: boolean
}
export default function TermsAndConditions({
  containerHeight = 12000,
  containerHeights = {},
  alwaysFull = false,
}: ITermsAndConditions) {
  return (
    <Background>
      <TermsWrapper>
        <Header>Velup Software License Agreement</Header>
        <Intro>
          This Velup Software License Agreement (the <Bold>“Agreement”</Bold>) is between you and Velup LLC. If you are
          agreeing to this Agreement not as an individual but on behalf of your company, government, or other entity for
          which you are acting (for example, as an employee or governmental official), then <Bold>“you”</Bold> means
          your entity and you are binding your entity to this Agreement. Velup may modify this Agreement from time to
          time, subject to the terms in Section 21 (Changes to this Agreement) below.
        </Intro>
        <Intro>
          The <Bold>“Effective Date”</Bold> of this Agreement is the date which is the earlier of (a) your initial
          access to or use of the Software (as defined below) or (b) the effective date of the first Order referencing
          this Agreement.
        </Intro>
        <Intro>
          <Bold>
            By clicking on the “I agree” (or similar button or checkbox) that is presented to you at the time of your
            Order, or by using or accessing the Software, you indicate your assent to be bound by this Agreement. If you
            do not agree to this Agreement, do not use or access the Software.
          </Bold>
        </Intro>
        <Container $alwaysFull={alwaysFull} $containerHeight={containerHeight} $containerHeights={containerHeights}>
          {/* */}
          <Paragraph>
            <Number>1.</Number>
            <Bold>SCOPE OF AGREEMENT</Bold>
          </Paragraph>

          <Paragraph>
            <Number>1.1</Number>
            <Underline>Software.</Underline>
            This Agreement governs your initial licensing of Velup’s Software, Support and Maintenance for the Software,
            and any Additional Services, as well as any future purchases made by you that reference this Agreement. This
            Agreement includes each Order, the Velup Policies, the Product-Specific Terms, and any other referenced
            policies and terms.
          </Paragraph>
          <Paragraph>
            <Number>1.2</Number>
            <Underline>Cloud Products.</Underline>
            This Agreement does not apply to any Velup hosted or cloud-based solutions (either current or prospective),
            use of which requires a separate agreement with Velup.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>2.</Number>
            <Bold>ACCOUNTS; AUTHORIZED USERS</Bold>
          </Paragraph>

          <Paragraph>
            <Number>2.1</Number>
            <Underline>Account Registration.</Underline>
            You must register for an account with us in order to place Orders or access or receive Software. Your
            registration information must be accurate, current and complete. You must keep your registration current so
            that we may send notices, statements and other information to you by email or through your account. You are
            responsible for all actions taken through your account, including Orders made or Apps enabled (which may
            incur fees).
          </Paragraph>
          <Paragraph>
            <Number>2.2</Number>
            <Underline>Authorized Users.</Underline>
            Only Authorized Users may access and use the Software. Some Software may allow you to designate different
            types of Authorized Users, in which case pricing and functionality may vary according to the type of
            Authorized User. You are responsible for compliance with this Agreement by all Authorized Users, including
            what Authorized Users do with your data, and for all fees incurred by Authorized Users (or from adding
            Authorized Users). All use of Software must be solely for the benefit of you or your Affiliates (except as
            expressly permitted in Section 2.3 below) and must be within the Scope of Use.
          </Paragraph>
          <Paragraph>
            <Number>2.3</Number>
            <Underline>User Types.</Underline>
            There are two primary types of users for Velup products and services: (i) Professional Development Event
            Providers (“Event Providers”), and; (ii) “Educators.” Event Providers can join, create an organization, and
            register with the Velup platform to host events. Educators can sign up through the Velup platform for
            registration and payment to event providers. Payment is received by Velup and dispersed directly to
            providers. Payments are on a per-event basis. There is no subscription fee. Instead, Velup receives $1 for
            each event registration. Some events are free of charge and sign-up is at no cost. Providers will also have
            the option of purchasing a premium promotional spot on Velup’s Navbar or in their search rankings, for an
            additional fee.
          </Paragraph>
          <Paragraph>
            <Number>2.4</Number>
            <Underline>Secondary Users.</Underline>
            Certain Software may be used as part of your support (or similar) resources related to your own products.
            Subject to the terms and conditions of this Agreement, you may grant your own customers’ end users (
            <Bold>“Secondary Users”</Bold>) limited rights to use the Software solely so that they may view and interact
            with such resources. You may not permit Secondary Users to use the Software for purposes unrelated to
            supporting your own offerings or grant Secondary Users administrator, configuration or similar use of the
            Software. You may not charge Secondary Users a specific fee for use of the Software but you may charge an
            overall fee for your own offerings. You are responsible under Section 2.2 (Authorized Users) for all
            Secondary Users as “Authorized Users” and are otherwise solely responsible for your own products, support
            offerings and Secondary relationships. Notwithstanding anything to the contrary in this Agreement, Velup has
            no direct or indirect warranty, indemnity or other liability or obligations of any kind to Secondary Users.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>3.</Number>
            <Bold>USE OF THE SOFTWARE</Bold>
          </Paragraph>

          <Paragraph>
            <Number>3.1</Number>
            <Underline>Your License Rights.</Underline>
            Subject to the terms and conditions of this Agreement, Velup grants you a non-exclusive, non-sublicensable
            and non-transferable license to install and use the Software during the applicable License Term for your own
            business purposes, in accordance with this Agreement, your applicable Scope of Use, the Documentation and
            all Laws.
          </Paragraph>
          <Paragraph>
            <Number>3.2</Number>
            <Underline>Restrictions.</Underline>
            Except as otherwise expressly permitted in this Agreement, you will not: (a) reproduce, modify, adapt or
            create derivative works of any part of the Software; (b) rent, lease, distribute, sell, sublicense,
            transfer, or provide access to the Software to a third party; (c) use the Software for the benefit of any
            third party; (d) incorporate the Software into a product or service you provide to a third party; (e)
            interfere with any license key mechanism in the Software or otherwise circumvent mechanisms in the Software
            intended to limit your use; (f) reverse engineer, disassemble, decompile, translate, or otherwise seek to
            obtain or derive the source code, underlying ideas, algorithms, file formats or non-public APIs to the
            Software, except to the extent expressly permitted by applicable law (and then only upon advance notice to
            us); (g) remove or obscure any proprietary or other notices contained in the Software; (h) use the Software
            for competitive analysis or to build competitive products; (i) publicly disseminate information regarding
            the performance of the Software; or (j) encourage or assist any third party to do any of the foregoing.
          </Paragraph>
          <Paragraph>
            <Number>3.3</Number>
            <Underline>Number of Instances.</Underline>
            Unless otherwise specified in your Order, for each Software license that you purchase, you may install one
            (1) production instance of the Software on systems owned or operated by you or one of your Authorized Users.
            We may also make available “developer” licenses free of charge for certain of our Software offerings to
            allow you to deploy non-production instances.
          </Paragraph>
          <Paragraph>
            <Number>3.4</Number>
            <Underline>Product-Specific Terms.</Underline>
            Some Software may be subject to additional terms specific to that Software as set forth in the
            Product-Specific Terms. By accessing or using a product covered by the Product-Specific Terms, you agree to
            the Product-Specific Terms.
          </Paragraph>
          <Paragraph>
            <Number>3.5</Number>
            <Underline>Your Modifications.</Underline>
            Subject to the terms and conditions of this Agreement (including Section 2 (Combining the Products with Open
            Source Software) of Third Party Code in Velup Products), for any elements of the Software provided by Velup
            in source code form and to the extent permitted in the Documentation, you may modify such source code solely
            for purposes of developing bug fixes, customizations and additional features for the Software. Any modified
            source code or Documentation constitutes “Your Modifications”. You may use Your Modifications solely in
            support of your permitted use of the Software (and only with your own instances of the Software), but you
            may not distribute the code to Your Modifications to any third party. Notwithstanding anything in this
            Agreement to the contrary, Velup has no support, warranty, indemnification or other obligation or liability
            with respect to Your Modifications or their combination, interaction or use with our Software.
          </Paragraph>
          <Paragraph>
            <Number>3.6</Number>
            <Underline>Attribution.</Underline>
            In any use of the Software, you must not remove, obscure, or alter in any way the following attribution to
            Velup on all user interfaces to the Software: “Powered by Velup,” which must in every case include a
            hyperlink to http://www.Velup.com, and which must be in the same format as delivered in the Software.
          </Paragraph>
          <Paragraph>
            <Number>3.7</Number>
            <Underline>System Requirements.</Underline>
            You are solely responsible for ensuring that your systems meet the hardware, software and any other
            applicable system requirements for the Software as specified in the Documentation. Velup will have no
            obligations or responsibility under this Agreement for issues caused by your use of any third-party hardware
            or software not provided by Velup.
          </Paragraph>
          <Paragraph>
            <Number>3.8</Number>
            <Underline>Indemnification by You.</Underline>
            You will defend, indemnify and hold harmless Velup from and against any loss, cost, liability or damage
            (including attorney’s fees) arising from or relating to any claim brought against Velup (a) arising from or
            related to your breach of Section 2.3 (Secondary Users) or any claims or disputes brought by Secondary Users
            arising out of their use of the Software; (b) by a third party relating to Your Modifications (including but
            not limited to any representations or warranties you make about Your Modifications of the Software) or your
            breach of Section 3.5 (Your Modifications); (c) by a third party related to your Customer Materials, as
            defined in Section 6.2(b); or (d) by a third party relating to any non-Velup content or data used by you or
            your Secondary Users in connection with the Software. This indemnification obligation is subject to your
            receiving (i) prompt written notice of such claim (but in any event notice in sufficient time for you to
            respond without prejudice); (ii) the exclusive right to control and direct the investigation, defense, or
            settlement of such claim; and (iii) all reasonably necessary cooperation of Velup at your expense.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>4.</Number>
            <Bold>APPS AND THIRD-PARTY PRODUCTS</Bold>
          </Paragraph>

          <Paragraph>
            <Number>4.1</Number>
            <Underline>Third Party Products.</Underline>
            You (including your Authorized Users) may choose to use or procure other third-party products or services in
            connection with the Software, including Third Party Apps (see Section 4.2 (Marketplace Apps)) or
            implementation, customization, training or other services. Your receipt or use of any third-party products
            or services is subject to a separate agreement between you and the third-party provider. If you enable or
            use third-party products or services (including Third Party Apps as referenced in Section 4.2 (Marketplace
            Apps)) with the Software, you acknowledge that the third-party providers may access or use your data as
            required for the interoperation of their products and services with the Software. This may include
            transmitting, transferring, modifying or deleting your data, or storing your data on systems belonging to
            the third-party providers or other third parties. Any third-party provider’s use of your data is subject to
            the applicable agreement between you and such third-party provider.{" "}
            <Bold>
              We are not responsible for any access to or use of your data by third-party providers or their products or
              services, or for the security or privacy practices of any third-party provider or its products or
              services. You are solely responsible for your decision to permit any third-party provider or third-party
              product or service to use your data. It is your responsibility to carefully review the agreement between
              you and the third-party provider, as provided by the applicable third-party provider. VELUP DISCLAIMS ALL
              LIABILITY AND RESPONSIBILITY FOR ANY THIRD-PARTY PRODUCTS OR SERVICES (WHETHER SUPPORT, AVAILABILITY,
              SECURITY OR OTHERWISE) OR FOR THE ACTS OR OMISSIONS OF ANY THIRD-PARTY PROVIDERS OR VENDORS.
            </Bold>
          </Paragraph>
          <Paragraph>
            <Number>4.2</Number>
            <Underline>Marketplace Apps.</Underline>
            As further described in the Velup Marketplace Terms of Use, the Velup Marketplace lists a variety of Apps
            that may be used with the Software, including both Velup Apps and Third Party Apps. Velup Apps for Software
            are considered “Software” under this Agreement (unless Velup specifies otherwise through the Velup
            Marketplace). Third Party Apps are not Software and remain subject to their own applicable Vendor Terms.
            Velup may enable interoperation of the Software with Third Party Apps as set forth in Section 4.1 (Third
            Party Products). The terms “Apps”, “Velup Apps”, “Third Party Apps”, “Vendor” and “Vendor Terms” are defined
            in the Velup Marketplace Terms of Use, which is a separate agreement governing use of the Velup Marketplace.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>5.</Number>
            <Bold>PRIVACY TERMS.</Bold> Velup may collect certain data and information from you and Secondary Users in
            connection with your and Secondary Users’ use of the Software and otherwise in connection with this
            Agreement. All such data and information will be collected and used by Velup in accordance with Velup’s
            Privacy Policy, which you acknowledge. You may disable data collection by the Software through the settings
            in the Software. Velup will never sell your user data without your express permission. All data, both
            personal and aggregate are kept private and confidential are NOT in the business of selling user data. All
            user data is theirs and theirs for good. All data, both personal and aggregate is kept private and
            confidential from all individuals and parties not on the Velup platform. User actions on the site will be
            displayed solely on a user feed so long as the user specifies that the specific action is publicly
            accessible.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>6.</Number>
            <Bold>VELUP COMMITMENTS</Bold>
          </Paragraph>

          <Paragraph>
            <Number>6.1</Number>
            <Underline>Support and Maintenance.</Underline>
            During the period for which you have paid the applicable Support and Maintenance fee, Velup will provide
            Support and Maintenance for the Software in accordance with the Velup Support Policy and Enterprise Support
            and Services Policy (if applicable). Support and Maintenance for Software includes access to New Releases,
            if and when available, and any references to “Software” in this Agreement include New Releases.
          </Paragraph>
          <Paragraph>
            <Number>6.2</Number>
            <Underline>Additional Services.</Underline>
            Subject to this Agreement, you may purchase Additional Services from Velup, which Velup will provide to you
            pursuant to the applicable Order.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>6.</Number>
            <Bold>VELUP COMMITMENTS</Bold>
          </Paragraph>

          <Paragraph>
            <Number>6.1</Number>
            <Underline>Support and Maintenance.</Underline>
            During the period for which you have paid the applicable Support and Maintenance fee, Velup will provide
            Support and Maintenance for the Software in accordance with the Velup Support Policy and Enterprise Support
            and Services Policy (if applicable). Support and Maintenance for Software includes access to New Releases,
            if and when available, and any references to “Software” in this Agreement include New Releases.
          </Paragraph>
          <Paragraph>
            <Number>6.2</Number>
            <Underline>Additional Services.</Underline>
            Subject to this Agreement, you may purchase Additional Services from Velup, which Velup will provide to you
            pursuant to the applicable Order.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>7.</Number>
            <Bold>LICENSE TERM, RETURNS AND PAYMENT</Bold>
          </Paragraph>

          <Paragraph>
            <Number>7.1</Number>
            <Underline>License Term and Renewals.</Underline>
            The License Term, Support and Maintenance period, will be indicated in the Order (as applicable). The
            License Term and any applicable service periods will commence on the Order date (unless a different start
            date is designated in the Order) and expire on the expiration date indicated in your account. Unless earlier
            terminated in accordance with this Agreement, each right to use Software will expire at the end of the
            applicable License Term. Unless you have selected the “autorenewal” option in your account, any renewals
            must be mutually agreed upon by the parties in writing. All renewals are subject to the applicable Software
            or Support and Maintenance continuing to be offered and will be charged at the then-current rates.
          </Paragraph>
          <Paragraph>
            <Number>7.2</Number>
            <Underline>Delivery.</Underline>
            We will deliver the applicable license keys to your account no later than when we have received payment of
            the applicable fees. You are responsible for accessing your account to determine that we have received
            payment and your Order has been processed. All deliveries under this Agreement will be electronic. For the
            avoidance of doubt, you are responsible for installation of the Software, and you acknowledge that Velup has
            no further delivery obligation with respect to the Software after delivery of the license keys.
          </Paragraph>
          <Paragraph>
            <Number>7.3</Number>
            <Underline>Return Policy.</Underline>
            As part of our commitment to customer satisfaction, you may terminate your initial Order of the applicable
            Software under this Agreement, for no reason or any reason, by providing notice of termination and returning
            any applicable Software to Velup no later than thirty (30) days after the Order date for such Software. In
            the event you terminate your initial Order under this Section 7.3, Velup may disable the license key that
            allowed the Software to operate and, at your request (which may be made through your account), Velup will
            refund you the amount paid under such Order. This termination and refund right applies only to your initial
            Order and only if you exercise your termination right within the period specified above, and does not apply
            to Additional Services. You understand that Velup may change this practice in the future in accordance with
            Section 21 (Changes to this Agreement).
          </Paragraph>
          <Paragraph>
            <Number>7.4</Number>
            <Underline>Increased Scope of Use.</Underline>
            During your License Term, you may increase your Scope of Use (e.g., adding Authorized Users, licenses,
            copies or instances) by placing a new Order or, if made available by Velup, directly through the applicable
            Software. Any increases to your Scope of Use will be subject to additional fees, as set forth in the
            applicable Order.
          </Paragraph>
          <Paragraph>
            <Number>7.5</Number>
            <Underline>Payment.</Underline>
            You will pay all fees in accordance with each Order, by the due dates and in the currency specified in the
            Order. If a purchase order number is required in order for an invoice to be paid, then you must provide such
            purchase order number to Velup by emailing the purchase order number to info.velup@gmail.com.
          </Paragraph>
          <Paragraph>
            <Number>7.6</Number>
            <Underline>Taxes.</Underline>
            Your fees under this Agreement exclude any taxes or duties payable in respect of the Software in the
            jurisdiction where the payment is either made or received. To the extent that any such taxes or duties are
            payable by Velup, you must pay to Velup the amount of such taxes or duties in addition to any fees owed
            under this Agreement. Notwithstanding the foregoing, you may have obtained an exemption from relevant taxes
            or duties as of the time such taxes or duties are levied or assessed. In that case, you will have the right
            to provide to Velup any such exemption information, and Velup will use reasonable efforts to provide such
            invoicing documents as may enable you to obtain a refund or credit for the amount so paid from any relevant
            revenue authority if such a refund or credit is available.
          </Paragraph>
          <Paragraph>
            <Number>7.7</Number>
            <Underline>Witholding Taxes.</Underline>
            You will pay all fees net of any applicable withholding taxes. You and Velup will work together to avoid any
            withholding tax if exemptions, or a reduced treaty withholding rate, are available. If Velup qualifies for a
            tax exemption, or a reduced treaty withholding rate, Velup will provide you with reasonable documentary
            proof. You will provide Velup reasonable evidence that you have paid the relevant authority for the sum
            withheld or deducted.
          </Paragraph>
          <Paragraph>
            <Number>7.8</Number>
            <Underline>Reseller Orders.</Underline>
            This Section 7.8 applies if you purchase the Software, Support and Maintenance or any Additional Services
            through an authorized partner or reseller of Velup (“Reseller”).
          </Paragraph>
          <Paragraph>
            <Number>(a)</Number>
            Instead of paying Velup, you will pay the applicable amounts to the Reseller, as agreed between you and the
            Reseller. Velup may suspend or terminate your rights to use Software if Velup does not receive the
            corresponding payment from the Reseller.
          </Paragraph>
          <Paragraph>
            <Number>(b)</Number>
            Instead of an Order with Velup, your order details (e.g., Software, Scope of Use and License Term) will be
            as stated in the order placed with Velup by the Reseller on your behalf, and the Reseller is responsible for
            the accuracy of any such order as communicated to Velup.
          </Paragraph>
          <Paragraph>
            <Number>(c)</Number>
            If you are entitled to a refund under this Agreement, then unless otherwise specified by Velup, Velup will
            refund any applicable fees to the Reseller and the Reseller will be solely responsible for refunding the
            appropriate amounts to you.
          </Paragraph>
          <Paragraph>
            <Number>(d)</Number>
            Resellers are not authorized to modify this Agreement or make any promises or commitments on Velup’s behalf,
            and Velup is not bound by any obligations to you other than as set forth in this Agreement.
          </Paragraph>
          <Paragraph>
            <Number>(e)</Number>
            The amount paid or payable by the Reseller to us for your use of the applicable Software under this
            Agreement will be deemed the amount actually paid or payable by you to us under this Agreement for purposes
            of calculating the liability cap in Section 15.2 (Liability Cap).
          </Paragraph>
          <Paragraph>
            <Number>7.9</Number>
            <Underline>Future Functionality; Separate Purchases.</Underline>
            You acknowledge that the Software and Additional Services referenced in an Order are being purchased
            separately from any of our other products or services. Payment obligations for any products or services are
            not contingent on the purchase or use of any of our other products (and for clarity, any purchases of
            Software and Additional Services are separate and not contingent on each other, even if listed on the same
            Order). You agree that your purchases are not contingent on the delivery of any future functionality or
            features (including future availability of any Software beyond the current License Term or any New
            Releases), or dependent on any oral or written public comments made by Velup regarding future functionality
            or features.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>8.</Number>
            <Bold>NO-CHARGE SOFTWARE. </Bold>
            We may offer certain Software (including some Velup Apps) to you at no charge, including free accounts,
            trial use, and Beta Versions as defined below (collectively, “No-Charge Software”). Your use of No-Charge
            Software is subject to any additional terms that we specify and is only permitted during the License Term we
            designate (or, if not designated, until terminated in accordance with this Agreement). Except as otherwise
            set forth in this Section, the terms and conditions of this Agreement governing Software, including Section
            3.2 (Restrictions), fully apply to No-Charge Software. We may terminate your right to use No-Charge Software
            at any time and for any reason in our sole discretion, without liability to you. You understand that any
            pre-release and beta versions of Software, and any pre-release and beta features within generally available
            Software, that we make available (collectively, “Beta Versions”) are still under development, may be
            inoperable or incomplete and are likely to contain more errors and bugs than generally available Software.
            We make no promises that any Beta Versions will ever be made generally available. In some circumstances, we
            may charge a fee in order to allow you to use Beta Versions, but the Beta Versions will still remain subject
            to this Section 8. All information regarding the characteristics, features or performance of any No-Charge
            Software (including Beta Versions) constitutes Velup’s Confidential Information. To the maximum extent
            permitted by applicable law, we disclaim all obligations or liabilities with respect to No-Charge Software,
            including any Support and Maintenance, warranty, and indemnity obligations. NOTWITHSTANDING ANYTHING ELSE IN
            THIS AGREEMENT, VELUP’S MAXIMUM AGGREGATE LIABILITY TO YOU IN RESPECT OF NO-CHARGE SOFTWARE WILL BE US$100.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>9.</Number>
            <Bold>USE OF VELUP DEVELOPER ASSETS. </Bold>
            Access to any Velup APIs, SDKs or other Velup developer assets is subject to the Velup Developer Terms and
            not this Agreement.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>10.</Number>
            <Bold>LICENSE OF CERTIFICATIONS AND AUDITS. </Bold>
            At our request, you agree to provide a signed certification that you are using all Software pursuant to the
            terms of this Agreement, including the Scope of Use. You agree to allow us, or our authorized agent, to
            audit your use of the Software (including that of your Authorized Users). We will provide you with at least
            ten (10) days advance notice prior to the audit, and the audit will be conducted during normal business
            hours. We will bear all out-of-pocket costs that we incur for the audit, unless the audit reveals that you
            have exceeded the Scope of Use. You will provide reasonable assistance, cooperation, and access to relevant
            information in the course of any audit at your own cost. If you exceed your Scope of Use, we may invoice you
            for any past or ongoing excessive use, and you will pay the invoice promptly after receipt. This remedy is
            without prejudice to any other remedies available to Velup at law or equity or under this Agreement. To the
            extent we are obligated to do so, we may share audit results with certain of our third-party licensors or
            assign the audit rights specified in this Section 10 to such licensors.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>11.</Number>
            <Bold>OWNERSHIP AND FEEDBACK. </Bold>
            The Software is made available on a limited license or access basis, and no ownership right is conveyed to
            you, irrespective of the use of terms such as “purchase” or “sale”. Velup and its licensors have and retain
            all right, title and interest, including all intellectual property rights, in and to Velup Technology
            (including the Software). From time to time, you may choose to submit Feedback to us. Velup may in
            connection with any of its products or services freely use, copy, disclose, license, distribute and exploit
            any Feedback in any manner without any obligation, royalty or restriction based on intellectual property
            rights or otherwise. No Feedback will be considered your Confidential Information, and nothing in this
            Agreement limits Velup's right to independently use, develop, evaluate, or market products or services,
            whether incorporating Feedback or otherwise.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>12.</Number>
            <Bold>CONFIDENTIALITY. </Bold>
            Except as otherwise set forth in this Agreement, each party agrees that all code, inventions, know-how and
            business, technical and financial information disclosed to such party (“Receiving Party”) by the disclosing
            party (“Disclosing Party”) constitute the confidential property of the Disclosing Party (“Confidential
            Information”), provided that it is identified as confidential at the time of disclosure or should be
            reasonably known by the Receiving Party to be confidential or proprietary due to the nature of the
            information disclosed and the circumstances surrounding the disclosure. Any Velup Technology and any
            performance information relating to the Software will be deemed Confidential Information of Velup without
            any marking or further designation. Except as expressly authorized herein, the Receiving Party will (1) hold
            in confidence and not disclose any Confidential Information to third parties and (2) not use Confidential
            Information for any purpose other than fulfilling its obligations and exercising its rights under this
            Agreement. The Receiving Party may disclose Confidential Information to its employees, agents, contractors
            and other representatives having a legitimate need to know, provided that they are bound to confidentiality
            obligations no less protective of the Disclosing Party than this Section 12 and that the Receiving Party
            remains responsible for compliance by them with the terms of this Section 12. The Receiving Party's
            confidentiality obligations will not apply to information which the Receiving Party can document: (i) was
            rightfully in its possession or known to it prior to receipt of the Confidential Information; (ii) is or has
            become public knowledge through no fault of the Receiving Party; (iii) is rightfully obtained by the
            Receiving Party from a third party without breach of any confidentiality obligation; or (iv) is
            independently developed by employees of the Receiving Party who had no access to such information. The
            Receiving Party may also disclose Confidential Information if so required pursuant to a regulation, law or
            court order (but only to the minimum extent required to comply with such regulation or order and with
            advance notice to the Disclosing Party). The Receiving Party acknowledges that disclosure of Confidential
            Information would cause substantial harm for which damages alone would not be a sufficient remedy, and
            therefore that upon any such disclosure by the Receiving Party the Disclosing Party will be entitled to
            appropriate equitable relief in addition to whatever other remedies it might have at law.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>13.</Number>
            <Bold>TERM AND TERMINATION</Bold>
          </Paragraph>

          <Paragraph>
            <Number>13.1</Number>
            <Underline>Term.</Underline>
            This Agreement is effective as of the Effective Date and continues until expiration of all License Terms,
            unless earlier terminated as set forth herein.
          </Paragraph>
          <Paragraph>
            <Number>13.2</Number>
            <Underline>Termination for Cause.</Underline>
            Either party may terminate this Agreement (including all related Orders) if the other party (a) fails to
            cure any material breach of this Agreement within thirty (30) days after written notice; (b) ceases
            operation without a successor; or (c) seeks protection under any bankruptcy, receivership, trust deed,
            creditors’ arrangement, composition or comparable proceeding, or if any such proceeding is instituted
            against that party (and not dismissed within sixty (60) days thereafter).
          </Paragraph>
          <Paragraph>
            <Number>13.3</Number>
            <Underline>Termination for Convenience.</Underline>
            You may choose to stop using the Software and terminate this Agreement (including all Orders) at any time
            for any reason upon written notice to Velup, but, unless you are exercising your right to terminate early
            pursuant to Section 7.3 (Return Policy), upon any such termination (i) you will not be entitled to a refund
            of any pre-paid fees and (ii) if you have not already paid all applicable fees for the then-current License
            Term or related services period (as applicable), any such fees that are outstanding will become immediately
            due and payable.
          </Paragraph>
          <Paragraph>
            <Number>13.4</Number>
            <Underline>Effects of Termination.</Underline>
            Upon any expiration or termination of this Agreement, your license to the Software terminates (even if the
            License Term is identified as “perpetual” or if no expiration date is specified in your Order) and you must
            cease using and delete (or at our request, return) all Software and Confidential Information or other
            materials of Velup in your possession, including on any third-party systems operated on your behalf. You
            will certify such deletion upon our request. If this Agreement is terminated by you in accordance with
            Section 13.2 (Termination for Cause), Velup will refund you any prepaid Software fees covering the remainder
            of the then-current License Term after the effective date of termination. If this Agreement is terminated by
            Velup in accordance with Section 13.2 (Termination for Cause), you will pay any unpaid fees covering the
            remainder of the then-current License Term after the effective date of termination. In no event will
            termination relieve you of its obligation to pay any fees payable to Velup for the period prior to the
            effective date of termination. Except where an exclusive remedy may be specified in this Agreement, the
            exercise by either party of any remedy, including termination, will be without prejudice to any other
            remedies it may have under this Agreement, by law, or otherwise.
          </Paragraph>
          <Paragraph>
            <Number>13.5</Number>
            <Underline>Survival.</Underline>
            The following Sections will survive any termination or expiration of this Agreement: 3.2 (Restrictions), 3.8
            (Indemnification by You), 4.1 (Third Party Products), 7.5 (Payment), 7.6 (Taxes), 8 (No-Charge Software)
            (disclaimers and use restrictions only), 10 (License Certifications and Audits), 11 (Ownership and
            Feedback), 12 (Confidentiality), 13 (Term and Termination), 14.3 (Warranty Disclaimer), 15 (Limitations of
            Liability), 16 (IP Indemnification by Velup) (but solely with respect to claims arising from your use of the
            Software during the License Term), 18 (Dispute Resolution), 19 (Export Restrictions), and 22 (General
            Provisions).
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>14.</Number>
            <Bold>WARRANTIES AND DISCLAIMER</Bold>
          </Paragraph>

          <Paragraph>
            <Number>14.1</Number>
            <Underline>General Warranties.</Underline>
            Each party represents and warrants that it has the legal power and authority to enter into this Agreement.
            If you are an entity, you represent and warrant that this Agreement and each Order is entered into by an
            employee or agent of such entity with all necessary authority to bind such entity to the terms and
            conditions of this Agreement.
          </Paragraph>
          <Paragraph>
            <Number>14.2</Number>
            <Underline>Virus Warranty.</Underline>
            Velup further represents and warrants that it will take reasonable commercial efforts to ensure that the
            Software, in the form and when provided to you, will be free of any viruses, malware, or other harmful code.
            For any breach of the foregoing warranty, your sole and exclusive remedy, and Velup’s sole obligation, is to
            provide a replacement copy of the Software promptly upon notice.
          </Paragraph>
          <Paragraph>
            <Number>14.3</Number>
            <Underline>Warranty Disclaimer.</Underline>
            EXCEPT AS EXPRESSLY SET FORTH IN SECTION 14.1 (GENERAL WARRANTIES) AND 14.2 (VIRUS WARRANTY), ALL SOFTWARE,
            SUPPORT AND MAINTENANCE AND ANY ADDITIONAL SERVICES ARE PROVIDED “AS IS,” AND VELUP AND ITS SUPPLIERS
            EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND, INCLUDING ANY WARRANTY OF
            NON-INFRINGEMENT, TITLE, FITNESS FOR A PARTICULAR PURPOSE, FUNCTIONALITY, OR MERCHANTABILITY, WHETHER
            EXPRESS, IMPLIED, OR STATUTORY. VELUP WILL NOT BE LIABLE FOR DELAYS, INTERRUPTIONS, SERVICE FAILURES OR
            OTHER PROBLEMS INHERENT IN USE OF THE INTERNET AND ELECTRONIC COMMUNICATIONS OR OTHER SYSTEMS OUTSIDE THE
            REASONABLE CONTROL OF VELUP. TO THE MAXIMUM EXTENT PERMITTED BY LAW, NEITHER VELUP NOR ANY OF ITS THIRD
            PARTY SUPPLIERS MAKES ANY REPRESENTATION, WARRANTY OR GUARANTEE AS TO THE RELIABILITY, TIMELINESS, QUALITY,
            SUITABILITY, TRUTH, AVAILABILITY, ACCURACY OR COMPLETENESS OF ANY SOFTWARE OR ANY CONTENT THEREIN OR
            GENERATED THEREWITH, OR THAT: (A) THE USE OF ANY SOFTWARE WILL BE SECURE, TIMELY, UNINTERRUPTED OR
            ERROR-FREE; (B) THE SOFTWARE WILL OPERATE IN COMBINATION WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM, OR DATA;
            (C) THE SOFTWARE (OR ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU
            THROUGH THE SOFTWARE) WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS); (D) ERRORS OR DEFECTS WILL BE CORRECTED;
            OR (E) EXCEPT AS EXPRESSLY SET FORTH IN SECTION 14.2 (VIRUS WARRANTY), THE SOFTWARE IS FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. YOU MAY HAVE OTHER STATUTORY RIGHTS, BUT THE DURATION OF STATUTORILY REQUIRED
            WARRANTIES, IF ANY, WILL BE LIMITED TO THE SHORTEST PERIOD PERMITTED BY LAW.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>15.</Number>
            <Bold>LIMITATIONS OF LIABILITY</Bold>
          </Paragraph>

          <Paragraph>
            <Number>15.1</Number>
            <Underline>Consequential Damages Waiver.</Underline>
            EXCEPT FOR EXCLUDED CLAIMS (DEFINED BELOW), NEITHER PARTY (NOR ITS SUPPLIERS) WILL HAVE ANY LIABILITY
            ARISING OUT OF OR RELATED TO THIS AGREEMENT FOR ANY LOSS OF USE, LOST OR INACCURATE DATA, LOST PROFITS,
            FAILURE OF SECURITY MECHANISMS, INTERRUPTION OF BUSINESS, COSTS OF DELAY OR ANY INDIRECT, SPECIAL,
            INCIDENTAL, RELIANCE OR CONSEQUENTIAL DAMAGES OF ANY KIND, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH
            DAMAGES IN ADVANCE.
          </Paragraph>
          <Paragraph>
            <Number>15.2</Number>
            <Underline>Liability Cap.</Underline>
            EXCEPT FOR EXCLUDED CLAIMS, EACH PARTY’S AND ITS SUPPLIERS’ AGGREGATE LIABILITY TO THE OTHER ARISING OUT OF
            OR RELATED TO THIS AGREEMENT WILL NOT EXCEED THE AMOUNT ACTUALLY PAID OR PAYABLE BY YOU TO US UNDER THIS
            AGREEMENT IN THE TWELVE (12) MONTHS IMMEDIATELY PRECEDING THE CLAIM.
          </Paragraph>
          <Paragraph>
            <Number>15.3</Number>
            <Underline>Excluded Claims.</Underline>
            “Excluded Claims” means (1) amounts owed by you under any Orders, (2) either party’s express indemnification
            obligations in this Agreement, and (3) your breach of Section 3.2 (Restrictions) or of Section 2 (Combining
            the Products with Open Source Software) of Third Party Code in Velup Products.
          </Paragraph>
          <Paragraph>
            <Number>15.4</Number>
            <Underline>Nature of Claims and Failure of Essential Purpose.</Underline>
            The parties agree that the waivers and limitations specified in this Section 15 (Limitations of Liability)
            apply regardless of the form of action, whether in contract, tort (including negligence), strict liability
            or otherwise and will survive and apply even if any limited remedy specified in this Agreement is found to
            have failed of its essential purpose.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>16.</Number>
            <Bold>IP INDEMNIFICATION BY VELUP. </Bold>
            We will defend you against any claim brought against you by a third party alleging that the Software, when
            used as authorized under this Agreement, infringes any third-party patent, copyright or trademark, or
            misappropriates any third-party trade secret enforceable in any jurisdiction that is a signatory to the
            Berne Convention (a “Claim”), and we will indemnify you and hold you harmless against any damages and costs
            finally awarded on the Claim by a court of competent jurisdiction or agreed to via settlement executed by
            Velup (including reasonable attorneys’ fees), provided that we have received from you: (a) prompt written
            notice of the Claim (but in any event notice in sufficient time for us to respond without prejudice); (b)
            reasonable assistance in the defense and investigation of the Claim, including providing us a copy of the
            Claim, all relevant evidence in your possession, custody, or control, and cooperation with evidentiary
            discovery, litigation, and trial, including making witnesses within your employ or control available for
            testimony; and (c) the exclusive right to control and direct the investigation, defense, and settlement (if
            applicable) of the Claim. If your use of the Software is (or in our opinion is likely to be) enjoined,
            whether by court order or by settlement, or if we determine such actions are reasonably necessary to avoid
            material liability, we may, at our option and in our discretion: (i) procure the right or license for your
            continued use of the Software in accordance with this Agreement; (ii) substitute substantially functionally
            similar Software; or (iii) terminate your right to continue using the Software and refund any license fees
            pre-paid by you for use of the Software for the terminated portion of the applicable License Term or, in the
            case of any “perpetual” licenses, the license fee paid by you as reduced to reflect a three (3) year
            straight-line depreciation from the license purchase date. Velup’s indemnification obligations above do not
            apply: (1) if the total aggregate fees received by Velup with respect to your license to Software in the
            twelve (12) month period immediately preceding the Claim is less than US$50,000; (2) if the Software is
            modified by any party other than Velup, but solely to the extent the alleged infringement is caused by such
            modification; (3) if the Software is used in combination with any non-Velup product, software, service or
            equipment, but solely to the extent the alleged infringement is caused by such combination; (4) to
            unauthorized use of Software; (5) to any Claim arising as a result of (y) circumstances covered by your
            indemnification obligations in Section 3.8 (Indemnification by You) or (z) any third-party deliverables or
            components contained with the Software; (6) to any unsupported release of the Software; or (7) if you settle
            or make any admissions with respect to a Claim without Velup’s prior written consent. THIS SECTION 16 STATES
            OUR SOLE LIABILITY AND YOUR EXCLUSIVE REMEDY FOR ANY INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS IN
            CONNECTION WITH ANY SOFTWARE OR OTHER ITEMS PROVIDED BY VELUP UNDER THIS AGREEMENT.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>17.</Number>
            <Bold>PUBLICITY RIGHTS. </Bold>
            We may identify you as a Velup customer in our promotional materials. We will promptly stop doing so upon
            your request sent to sales@velup.io.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>18.</Number>
            <Bold>DISPUTE RESOLUTION</Bold>
          </Paragraph>

          <Paragraph>
            <Number>18.1</Number>
            <Underline>Informal Resolution.</Underline>
            In the event of any controversy or claim arising out of or relating to this Agreement, the parties will
            consult and negotiate with each other and, recognizing their mutual interests, attempt to reach a solution
            satisfactory to both parties. If the parties do not reach settlement within a period of sixty (60) days,
            either party may pursue relief as may be available under this Agreement pursuant to Section 18.2 (Governing
            Law; Jurisdiction). All negotiations pursuant to this Section 18.1 will be confidential and treated as
            compromise and settlement negotiations for purposes of all rules and codes of evidence of applicable
            legislation and jurisdictions.
          </Paragraph>
          <Paragraph>
            <Number>18.2</Number>
            <Underline>Governing Law; Jurisdiction.</Underline>
            This Agreement will be governed by and construed in accordance with the applicable laws of the State of
            California, USA, without giving effect to the principles of that State relating to conflicts of laws. Each
            party irrevocably agrees that any legal action, suit or proceeding arising out of or related to this
            Agreement must be brought solely and exclusively in, and will be subject to the service of process and other
            applicable procedural rules of, the State or Federal court in San Francisco, California, USA, and each party
            irrevocably submits to the sole and exclusive personal jurisdiction of the courts in San Francisco,
            California, USA, generally and unconditionally, with respect to any action, suit or proceeding brought by it
            or against it by the other party. In any action or proceeding to enforce a party’s rights under this
            Agreement, the prevailing party will be entitled to recover its reasonable costs and attorneys’ fees.
          </Paragraph>
          <Paragraph>
            <Number>18.3</Number>
            <Underline>Injunctive Relief; Enforcement.</Underline>
            Notwithstanding the provisions of Section 18.1 (Informal Resolution) and Section 18.2 (Governing Law;
            Jurisdiction), nothing in this Agreement will prevent Velup from seeking injunctive relief with respect to a
            violation of intellectual property rights, confidentiality obligations or enforcement or recognition of any
            award or order in any appropriate jurisdiction.
          </Paragraph>
          <Paragraph>
            <Number>18.4</Number>
            <Underline>Exclusion of UN Convention and UCITA.</Underline>
            The terms of the United Nations Convention on Contracts for the Sale of Goods do not apply to this
            Agreement. The Uniform Computer Information Transactions Act (UCITA) will not apply to this Agreement
            regardless of when or where adopted.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>19.</Number>
            <Bold>EXPORT RESTRICTIONS. </Bold>
            The Software is subject to export restrictions by the United States government and may be subject to import
            restrictions by certain foreign governments, and you agree to comply with all applicable export and import
            laws and regulations in your download of, access to, and use of the Software. You shall not (and shall not
            allow any third-party to) remove or export from the United States or allow the export or re-export of any
            part of the Software or any direct product thereof: (a) into (or to a national or resident of) any embargoed
            or terrorist-supporting country; (b) to anyone on the U.S. Commerce Department’s Denied Persons, Entity, or
            Unverified Lists or the U.S. Treasury Department’s list of Specially Designated Nationals and Consolidated
            Sanctions list (collectively, “Prohibited Persons”); (c) to any country to which such export or re-export is
            restricted or prohibited, or as to which the United States government or any agency thereof requires an
            export license or other governmental approval at the time of export or re-export without first obtaining
            such license or approval; or (d) otherwise in violation of any export or import restrictions, laws or
            regulations of any United States or foreign agency or authority. You represent and warrant that you are not
            located in, under the control of, or a national or resident of any such prohibited country. You also certify
            that you are not a Prohibited Person nor owned, controlled by, or acting on behalf of a Prohibited Person.
            You agree not to use or provide the Software for any prohibited end use, including to support any nuclear,
            chemical, or biological weapons proliferation, or missile technology, without the prior permission of the
            United States government.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>20.</Number>
            <Bold>THIRD PARTY CODE. </Bold>
            The Software includes code and libraries licensed to us by third parties, including open source software.
            See Third Party Code in Velup Products for additional provisions regarding our use of third party code.
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>21.</Number>
            <Bold>CHANGES TO THIS AGREEMENT.</Bold>
          </Paragraph>

          <Paragraph>
            <Number>21.1</Number>
            <Underline>Modifications Generally.</Underline>
            We may modify the terms and conditions of this Agreement (including Velup Policies) from time to time, with
            notice given to you by email, through the Software or through our website. Together with notice, we will
            specify the effective date of the modifications.
            <br />
            <Underline>No-Charge Software:</Underline> You must accept the modifications to continue using the No-Charge
            Software. If you object to the modifications, your exclusive remedy is to cease using the No-Charge
            Software.
            <br />
            <Underline>Paid Licenses:</Underline> Typically, when we make modifications to the main body of this
            Agreement (excluding the Velup Policies), the modifications will take effect at the next renewal of your
            License Term and will automatically apply as of the renewal date unless you elect not to renew pursuant to
            Section 7.1 (License Term and Renewals). In some cases – e.g., to address compliance with Laws, or as
            necessary for new features – we may specify that such modifications become effective during your
            then-current License Term. If the effective date of such modifications is during your then-current License
            Term and you object to the modifications, then (as your exclusive remedy) you may terminate your affected
            Orders upon notice to us, and we will refund to you any fees you have pre-paid for use of the affected
            Software for the terminated portion of the applicable License Term. To exercise this right, you must provide
            us with notice of your objection and termination within thirty (30) days of us providing notice of the
            modifications. For the avoidance of doubt, any Order is subject to the version of this Agreement in effect
            at the time of the Order.
            <br />
            <Underline>Velup Policies:</Underline> Our products and business are constantly evolving, and we may modify
            the Velup Policies from time to time, including during your then-current License Term in order to respond to
            changes in our products, our business, or Laws. In this case, unless required by Laws, we agree not to make
            modifications to the Velup Policies that, considered as a whole, would substantially diminish our
            obligations during your then-current License Term. Modifications to the Velup Policies will take effect
            automatically as of the effective date specified for the updated policies.
            <br />
          </Paragraph>
          {/* */}
          {/* */}
          <Paragraph>
            <Number>22.</Number>
            <Bold>GENERAL PROVISIONS.</Bold>
          </Paragraph>

          <Paragraph>
            <Number>22.1</Number>
            <Underline>Notices.</Underline>
            Any notice under this Agreement must be given in writing. We may provide notice to you via email or through
            your account. You agree that any such electronic communication will satisfy any applicable legal
            communication requirements, including that such communications be in writing. Our notices to you will be
            deemed given upon the first business day after we send it. You will provide notice to us by post to Velup
            Pty Ltd, c/o Velup, Inc., 350 Bush Street, Level 13, San Francisco, CA, USA 94104, Attn: General Counsel.
            Your notices to us will be deemed given upon our receipt.
          </Paragraph>

          <Paragraph>
            <Number>22.2</Number>
            <Underline>Force Majeure.</Underline>
            Neither party will be liable to the other for any delay or failure to perform any obligation under this
            Agreement (except for a failure to pay fees) if the delay or failure is due to events which are beyond the
            reasonable control of such party, such as a strike, blockade, war, act of terrorism, riot, natural disaster,
            failure or diminishment of power or telecommunications or data networks or services, or refusal of a license
            by a government agency.
          </Paragraph>

          <Paragraph>
            <Number>22.3</Number>
            <Underline>Assignment.</Underline>
            You may not assign or transfer this Agreement without our prior written consent. As an exception to the
            foregoing, you may assign this Agreement in its entirety (including all Orders) to your successor resulting
            from your merger, acquisition, or sale of all or substantially all of your assets or voting securities,
            provided that you provide us with prompt written notice of the assignment and the assignee agrees in writing
            to assume all of your obligations under this Agreement. Any attempt by you to transfer or assign this
            Agreement except as expressly authorized above will be null and void. We may assign our rights and
            obligations under this Agreement (in whole or in part) without your consent. We may also permit our
            Affiliates, agents and contractors to exercise our rights or perform our obligations under this Agreement,
            in which case we will remain responsible for their compliance with this Agreement. Subject to the foregoing,
            this Agreement will inure to the parties’ permitted successors and assigns.
          </Paragraph>

          <Paragraph>
            <Number>22.4</Number>
            <Underline>Government End Users.</Underline>
            Any United States federal, state, or local government customers are subject to the Government Amendment in
            addition to this Agreement.
          </Paragraph>

          <Paragraph>
            <Number>22.5</Number>
            <Underline>Entire Agreement.</Underline>
            This Agreement is the entire agreement between you and Velup relating to the Software and supersedes all
            prior or contemporaneous oral or written communications, proposals and representations between you and Velup
            with respect to the Software or any other subject matter covered by this Agreement. No provision of any
            purchase order or other business form employed by you will supersede or supplement the terms and conditions
            of this Agreement, and any such document relating to this Agreement will be for administrative purposes only
            and will have no legal effect.
          </Paragraph>

          <Paragraph>
            <Number>22.6</Number>
            <Underline>Conflicts.</Underline>
            In event of any conflict between the main body of this Agreement and either the Velup Policies or
            Product-Specific Terms, the Velup Policies or Product-Specific Terms (as applicable) will control with
            respect to their subject matter.
          </Paragraph>

          <Paragraph>
            <Number>22.7</Number>
            <Underline>Wavers; Modifications.</Underline>
            No failure or delay by the injured party to this Agreement in exercising any right, power or privilege
            hereunder will operate as a waiver thereof, nor will any single or partial exercise thereof preclude any
            other or further exercise thereof or the exercise of any right, power or privilege hereunder at law or
            equity. Except as set forth in Section 21 (Changes to this Agreement), any amendments or modifications to
            this Agreement must be executed in writing by the authorized representatives of Velup and you.
          </Paragraph>

          <Paragraph>
            <Number>22.8</Number>
            <Underline>Interpretation.</Underline>
            As used herein, “including” (and its variants) means “including without limitation” (and its variants).
            Headings are for convenience only. If any provision of this Agreement is held to be void, invalid,
            unenforceable or illegal, the other provisions will continue in full force and effect.
          </Paragraph>

          <Paragraph>
            <Number>22.9</Number>
            <Underline>Independent Contractors.</Underline>
            The parties are independent contractors. This Agreement will not be construed as constituting either party
            as a partner of the other or to create any other form of legal association that would give either party the
            express or implied right, power or authority to create any duty or obligation of the other party.
          </Paragraph>
          {/* */}
        </Container>
      </TermsWrapper>
    </Background>
  )
}
