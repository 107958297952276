import { useRouter } from "next/router"
import { Avatar, Spacer, NextImage } from "components"
import styled from "styled-components"

import { NormalizedProfile } from "types/user"

const ProviderMembersListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const MemberWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  & h4 {
    font-family: var(--font-primary);
    font-weight: 400;
    color: var(--font-color-subdued-header);
  }

  & p {
    font-family: var(--font-primary);
    font-weight: 400;
    font-size: 15px;
    color: var(--font-color-subdued);
  }
`
const StyledNextImage = styled((props) => <NextImage pointer={false} {...props} />)``
const ImageWrapper = styled.div`
  max-height: 150px;
  max-width: 150px;
  min-height: 50px;
  min-width: 50px;
  position: relative;
`

interface IMember {
  member: NormalizedProfile
  isAdmin?: boolean
  isOwner?: boolean
}
const Member: React.FC<IMember> = ({ member, isAdmin, isOwner }) => {
  const router = useRouter()
  const avatarImage = member?.image ? (
    <ImageWrapper>
      <StyledNextImage src={member.is_social ? member.image : member.imgix_src} />
    </ImageWrapper>
  ) : null

  return (
    <MemberWrapper>
      <Avatar
        avatarSize="sm"
        name={member.username}
        image={avatarImage}
        onClick={() => router.push(`/profile/u/${member.username}/`)}
      />
      <Spacer size="lg" />
      <div>
        <h4>{member.username}</h4>
        {isAdmin && <p>Admin</p>}
        {isOwner && <p>Owner</p>}
      </div>
    </MemberWrapper>
  )
}

export default function ProviderMembersList({ members, admin, owner, className = "" }) {
  return (
    <ProviderMembersListWrapper className={className}>
      <h3>Team Members</h3>
      <Spacer size="lg" />
      {owner && <Member member={owner} isOwner />}
      {admin.map((member: NormalizedProfile) => member && <Member isAdmin member={member} key={member.id} />)}
      {members.map((member: NormalizedProfile) => member && <Member member={member} key={member.id} />)}
    </ProviderMembersListWrapper>
  )
}
