import styled from "styled-components"
import { Card, Spacer, Spinner, VelupLogo } from "components"

const LoadingScreenWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #dde2ea;
`
const StyledCard = styled((props) => <Card {...props} />)`
  padding: 2rem;
`
const StyledLogo = styled((props) => <VelupLogo {...props} />)`
  // padding: 2rem;
`

const LoadingScreen: React.FC = () => {
  return (
    <LoadingScreenWrapper>
      <StyledCard elevation="med">
        <Spinner type="primary" size="lg" rotateColors />
        {/* <Spinner type="secondary" size="lg" />
        <Spinner type="accent" size="lg" /> */}
        <Spacer size="lg" />
        <StyledLogo logoSize="xl" />
      </StyledCard>
    </LoadingScreenWrapper>
  )
}

export default LoadingScreen
