import * as R from "ramda"

export const capitalize = (str: string) => (str ? str[0].toUpperCase() + R.tail(str) : str)

export function truncate(str: string, n: number, useWordBoundary: boolean, ellipsesEl: string = "&hellip;"): string {
  if (!str) return str
  if (str.length <= n) return str
  const subString = str.substr(0, n - 1) // the original check
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + ellipsesEl
}

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

export function formatPrice(price: number): string {
  // if (price === 0) return "FREE TO REGISTER"
  if (price === 0) return "FREE"
  return formatter.format(price)
}

const percentageFormatter = new Intl.NumberFormat("en-US", {
  minimumIntegerDigits: 2,
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

export function formatPercentage(perc: number): string {
  return `${percentageFormatter.format(perc)}%`
}
