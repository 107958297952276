import * as R from "ramda"
import { isEventInstance } from "utils/events"
// import { isStripeAccountInstance } from "utils/payments"
import { isUserInstance } from "utils/users"
import { isStringOrNumber } from "utils/parseUtils"

// import { Provider, ProviderFull } from "models/providers"
// import { Event, EventFull } from "models/events"
// import { User, UserID } from "models/auth"
import { Provider } from "types/providers"
import { ProviderSimple } from "types/events"
// import { User, UserID } from "types/auth"
import { User, UserID } from "types/user"

export const normalizeProvidersFromServer = (providers: Provider[]) => {
  return R.map(
    (provider) => ({
      ...provider,
      events: R.map((event) => (isEventInstance(event) ? event.id : event), provider.events),
      // stripe_account: isStripeAccountInstance(provider?.stripe_account)
      //   ? provider.stripe_account.stripe_id
      //   : provider?.stripe_account,
      // details_submitted: isStripeAccountInstance(provider?.stripe_account)
      //   ? provider.stripe_account.details_submitted
      //   : false,
      // details_submitted: provider?.stripe_account?.stripe_id
      //   ? R.prop("details_submitted", provider.stripe_account)
      //   : false,
      owner: isUserInstance(provider?.owner) ? provider.owner.id : provider?.owner,

      // TODO:
      // handle below:
      // members and admin have been moved to the "teams" model
      admin: R.isNil(provider.admin) ? [] : R.map((a) => (isUserInstance(a) ? a.id : a), provider.admin),
      members: R.isNil(provider.members) ? [] : R.map((m) => (isUserInstance(m) ? m.id : m), provider.members),
    }),
    providers
  )
}

export const isProviderInstance = (provider: any): provider is Provider => {
  if (!provider) return false

  if (isStringOrNumber(provider)) return false

  if (
    R.all(
      (p) => provider.hasOwnProperty(p) // check that provider contains all required
    )(
      ["id", "name", "approval_status", "is_active"] // required props for a provider model
    )
  ) {
    return true
  }

  return false
}

export const isProviderSimpleInstance = (provider: any): provider is ProviderSimple => {
  if (!provider) return false

  if (isStringOrNumber(provider)) return false

  if (
    R.all(
      (p) => provider.hasOwnProperty(p) // check that provider contains all required
    )(
      ["id", "name", "approval_status", "is_active"] // required props for a provider model
    )
  ) {
    return true
  }

  return false
}

export const isAnyProviderInstance = (provider: any): provider is Provider | ProviderSimple => {
  return isProviderInstance(provider) || isProviderSimpleInstance(provider)
}

export const userIsOwnerOfProvider = (provider: Provider, user: User | UserID): boolean => {
  if (!isProviderInstance(provider)) return false

  const userId = isUserInstance(user) ? user.id : user

  return isUserInstance(provider.owner) ? provider.owner.id === userId : provider.owner === userId
}

export const userIsAdminOfProvider = (provider: Provider, user: User | UserID): boolean => {
  // base checks
  if (!isAnyProviderInstance(provider)) return false
  if (!provider.admin?.length) return false

  const userId = isUserInstance(user) ? user.id : user

  const isAdmin = isUserInstance(provider.admin[0])
    ? R.includes(
        userId,
        R.map((u) => u.id, provider.admin as User[])
      )
    : R.includes(userId, provider.admin as UserID[])

  // if they are admin or the owner, consider them admin
  return Boolean(isAdmin) || userIsOwnerOfProvider(provider, user)
}

export const userIsMemberOfProvider = (provider: Provider, user: User | UserID): boolean => {
  // base checks
  if (!isAnyProviderInstance(provider)) return false
  if (!provider.admin?.length) return false

  const userId = isUserInstance(user) ? user.id : user

  const isMember = isUserInstance(provider.members[0])
    ? R.includes(
        userId,
        R.map((u) => u.id, provider.members as User[])
      )
    : R.includes(userId, provider.members as UserID[])

  // if they are admin or regular member, consider them a member of the provider
  return Boolean(isMember) || userIsAdminOfProvider(provider, user)
}
