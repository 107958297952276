import { useState } from "react"
import Link from "next/link"
import { connect } from "react-redux"

// import { useAuthenticatedUserAndProfile } from "hooks/useAuthenticatedUserAndProfile"
import { Button, Card, IconEmail, Input } from "components"
import { validateEmailRegex } from "utils/validation"
import styled from "styled-components"
import media from "utils/mediaQueryTemplates"
import { ApiClientCallbackResponse } from "services/apiClient"

import { Actions as authActions, PasswordResetEmailParams } from "models/auth"
// import { Actions as authActions, ConfirmAccountParams } from "models/auth"
import { useAuthedUserProfile } from "hooks/profiles/useAuthedUserProfile"
// import {} from "models/u"

const svg = "/assets/images/undraw_monitor.svg"

const PasswordResetError = styled.p`
  color: red;
  font-size: 16px;
`
const PasswordResetMessage = styled.p`
  font-size: 1rem;
`

const MonitorSVG = styled.img`
  padding: 1rem;
`

const PasswordResetCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  margin: 0 auto;
  position: relative;

  z-index: var(--z-index-small);
  border-radius: 0.25rem;

  max-width: var(--mobile-width);
  ${media.medium`
    width: 500px;
  `}
`
const StyledButton = styled(Button)`
  margin-top: auto;
`
const ToSignUpSection = styled.p`
  margin: 20px 0 0;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
`

interface IPasswordResetForm {
  sendPasswordResetEmail: (p: PasswordResetEmailParams) => Promise<ApiClientCallbackResponse>
}
function PasswordResetForm({ sendPasswordResetEmail }: IPasswordResetForm) {
  // const { user, profile } = useAuthenticatedUserAndProfile()
  const { userProfile } = useAuthedUserProfile()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(userProfile?.email ? userProfile.email : "")
  const [err, setError] = useState(null)
  const [inputError, setInputError] = useState(null)
  const [message, setMessage] = useState(null)

  const handleSubmit = async () => {
    setLoading(true)

    if (!email?.length) {
      setError("Please fill in the required fields.")
      setInputError(true)
      setLoading(false)
      return
    } else {
      setInputError(false)
    }

    // validate email
    if (!validateEmailRegex(email)) {
      setError("Invalid email.")
      setLoading(false)
      return
    } else {
      setError(null)
    }

    const res = await sendPasswordResetEmail({ email })
    if (res.success) {
      setError(null)
      setMessage(`Success! If that account exists in our system, you should receive an email shortly.`)
    } else {
      setError("An error has occurred. Contact support.")
    }

    setLoading(false)
  }

  return (
    <PasswordResetCard>
      {err && <PasswordResetError>{err}</PasswordResetError>}
      {message && <PasswordResetMessage>{message}</PasswordResetMessage>}
      <MonitorSVG src={svg} alt="monitor svg" />
      <Input
        placeholder="Email"
        iconLeft={<IconEmail />}
        name="email"
        type="email"
        label="email"
        value={email}
        onChange={(email) => setEmail(email)}
        providedError={inputError}
        required
      />
      <StyledButton type="submit" onClick={() => handleSubmit()} isLoading={loading}>
        Send Reset Email
      </StyledButton>
      <ToSignUpSection>
        {/* Need an account? Sign up <Link to="/app/register/">here</Link>. */}
        Need an account? Sign up <Link href="/authenticate/">here</Link>.
      </ToSignUpSection>
    </PasswordResetCard>
  )
}

export default connect(null, {
  sendPasswordResetEmail: authActions.sendPasswordResetEmail,
})(PasswordResetForm)
