// import * as R from "ramda"
import { ImgixPicture } from "components"
import styled from "styled-components"

import { Provider } from "types/providers"
import { ProviderSimple } from "types/events"

const StyledImage = styled.img``

const meetup = [
  "undraw_collaborating_event_meetup.svg",
  "undraw_collaborators_event_meetup.svg",
  "undraw_conference_call_event_meetup.svg",
  "undraw_team_page_event_meetup.svg",
]
const virtual = [
  "undraw_friends_online_event_virtual.svg",
  "undraw_meet_the_team_event_virtual.svg",
  "undraw_remote_meeting_event_virtual.svg",
  "undraw_remotely_event_virtual.svg",
  "undraw_team_chat_event_virtual.svg",
]
const conference = [
  "undraw_candidate_event_conference.svg",
  "undraw_conference_event_conference.svg",
  "undraw_conference_speaker_event_conference.svg",
  "undraw_progress_tracking_event_conference.svg",
  "undraw_teaching_event_conference.svg",
]
const immersive = [
  "undraw_apps_event_immersive.svg",
  "undraw_google_docs_event_immersive.svg",
  "undraw_online_everywhere_event_immersive.svg",
  "undraw_studying_event_immersive.svg",
]
const coaching = [
  "undraw_group_hangout_event_coaching.svg",
  "undraw_interview_event_coaching.svg",
  "undraw_work_together_event_coaching.svg",
]
const online = [
  "undraw_tutorial_video_event_online.svg",
  "undraw_video_influencer_event_online.svg",
  "undraw_youtube_tutorial_event_online.svg",
]
const talk = [
  "undraw_tutorial_video_event_online.svg",
  "undraw_video_influencer_event_online.svg",
  "undraw_youtube_tutorial_event_online.svg",
]
const workshop = [
  "undraw_presentation_event_workshop.svg",
  "undraw_public_discussion_event_workshop.svg",
  "undraw_quiz_event_workshop.svg",
  "undraw_interaction_design_event_workshop.svg",
]
const providersListingItemData = { meetup, virtual, conference, immersive, coaching, online, talk, workshop }

interface IProviderPlaceholderImage {
  provider?: Provider | ProviderSimple
}
export default function ProviderPlaceholderImage({ provider, ...props }: IProviderPlaceholderImage) {
  const getPlaceholderImage = () => {
    // get list of all svgs in that category
    const svgList = Object.keys(providersListingItemData)
      .map((k) => providersListingItemData[k])
      .flat()
    // const categoryList = eventsListingItemData[event?.event_type]
    //   ? eventsListingItemData[event?.event_type]
    //   : R.values(eventsListingItemData).flat()

    // use a combination of the event id and the provider id to get the placeholder svg
    // modulus by length of the list to always get a position in that list
    const idx =
      Number(`${provider?.id ? provider?.id : Number.isNaN(Number(provider)) ? 0 : provider}`) % svgList.length

    return svgList[Number.isNaN(idx) ? 0 : idx]
  }

  if (provider.imgix_src) {
    return <ImgixPicture src={provider.image} imgixSrc={provider.imgix_src} alt="provider cover" {...props} />
  }

  return <StyledImage src={`/assets/images/${getPlaceholderImage()}`} alt="provider cover" {...props} />
}
