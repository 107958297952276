import { useState, Fragment } from "react"
// import { useRouter } from "next/router"
import { Button, Card, Chip, Dropdown, IconMapPositionPin, IconSearch, Input, Spacer } from "components"
import { eventTypeDropdownItems, landingChipSearchShortcuts } from "constants/ui"
import styled from "styled-components"
import { DropdownItem } from "types/ui"

const EventSearchPreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;

  & > h1 {
    position: relative;
    z-index: 2;
    margin-bottom: 1.5rem;
    font-size: 3rem;
  }
`
const EventSearchPreviewCard = styled((props) => <Card noPadding elevation="high" {...props} />)`
  display: flex;
  flex-direction: row;
  border-right: none;
  border-bottom: none;
  max-width: 800px;

  & svg {
    height: 24px;
    width: 24px;
  }
`
const SubmitButton = styled((props) => <Button {...props} />)`
  border-radius: 0px 4px 4px 0px;
`

const StyledInput = styled((props) => <Input {...props} />)`
  width: 300px;

  & .styled__input-container {
    border: none;
    border-right: solid 1px var(--background-color-very-light-blue);
    border-radius: 0;
    flex: 1;
  }

  & .styled__input {
    font-size: 17px;
  }
`
const StyledLargeInput = styled(StyledInput)`
  flex: 1;

  & .styled__input-container {
    border-radius: 4px 0 0 4px;
  }
`
const StyledDropdown = styled((props) => <Dropdown {...props} />)`
  border-color: transparent;
  width: 250px;

  & .dropdown-card {
    border-color: transparent;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    height: 100%;
    border-radius: 0;
  }
`
const DropdownItemLabel = styled.span<{ $large?: boolean }>`
  font-size: ${(props) => (props.$large ? "18px" : "15px")};
  ${(props) => (props.$large ? "color: var(--font-color-subdued-header);" : "")}
`

const customRenderItemLabel = (item: DropdownItem, ctx?: string) => {
  if (ctx === "dropdown-label") return <DropdownItemLabel $large={true}>{item.label}</DropdownItemLabel>
  return <DropdownItemLabel>{item.label}</DropdownItemLabel>
}

export default function EventSearchPreview() {
  const [searchInput, setSearchInput] = useState("")
  const [locationInput, setLocationInput] = useState("Online")
  const [eventType, setEventType] = useState(eventTypeDropdownItems[0])

  return (
    <EventSearchPreviewWrapper>
      <h1>Find Professional Development</h1>
      <EventSearchPreviewCard>
        <StyledLargeInput
          name="searchInput"
          value={searchInput}
          label=""
          noLabel
          noHelperText
          iconLeft={<IconSearch stroke={"var(--font-color-subdued-header)"} />}
          placeholder="Search events..."
          onChange={(v: string) => setSearchInput(v)}
          fullWidth
        />
        <StyledInput
          name="locationInput"
          value={locationInput}
          label=""
          noLabel
          noHelperText
          iconLeft={<IconMapPositionPin stroke={"var(--font-color-subdued-header)"} />}
          placeholder="Enter a location"
          onChange={(v: string) => setLocationInput(v)}
        />
        <StyledDropdown
          items={eventTypeDropdownItems}
          selectedItem={eventType}
          onSelect={(item: DropdownItem) => setEventType(item)}
          // label="Any type"
          renderItemLabel={customRenderItemLabel}
        />
        <SubmitButton buttonType="primary" buttonSize="xl">
          Search
        </SubmitButton>
      </EventSearchPreviewCard>
    </EventSearchPreviewWrapper>
  )
}
