import { useState, Fragment } from "react"
import * as R from "ramda"
import { IconSuccess, IconComment, Spacer, Stars, StarsInput } from "components"
import { EmptyPromptCard, ReviewBox, ReviewTile } from "views"
import { formatPercentage } from "utils/format"
import styled from "styled-components"

import { Review } from "types/reviews"

const EventReviewsSectionWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  background: var(--background-color-lightest-blue);
  padding-bottom: 2rem;

  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-primary);
  }
`
const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`

export default function EventReviewsSection({ event, userReviewForEvent }) {
  return (
    <EventReviewsSectionWrapper>
      <Container>
        {/* FEEDBACK TITLE */}
        <h2>RATINGS</h2>

        <Spacer size="lg" />
        {/* AGGREGATES */}
        <Aggregates event={event} />

        <Spacer size="xxl" />

        {/* REVIEW BOX */}
        <ReviewBox event={event} userReviewForEvent={userReviewForEvent} />

        <Spacer size="xxl" />
        {/* REVIEWS TITLE AND SEARCH FILTER */}
        <h2>REVIEWS</h2>

        <Spacer size="xl" />
        {/* LIST OF REVIEWS */}
        <ReviewList event={event} />
      </Container>
    </EventReviewsSectionWrapper>
  )
}

const AggregatesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
const AggregatesAverageInfo = styled.div`
  display: flex;
  flex-direction: column;

  h1 {
    font-weight: 400;
    font-size: 3.5rem;
  }
`
const AggregatesStarsBreakdown = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem 0 2rem;
`
const RatingPercentageBarContainer = styled.div`
  display: flex;
  align-items: center;

  & span {
    width: 70px;
  }
`
const RatingPercentageBarWrapper = styled.div`
  flex: 1;
  background: var(--border-color);
  background: var(--background-color-very-light-blue);
  display: flex;
  height: 6px;
  width: 100%;
  border-radius: 10px;
`
const Bar = styled.div<{ $perc?: number | string }>`
  width: ${(props) => props.$perc}%;
  background: var(--color-secondary);
  border-radius: 10px;
`
const RatingPercentageBar = ({ count, numStars, totalRatings }) => {
  const fillPerc = totalRatings === 0 ? 0 : Number((count / totalRatings) * 100).toFixed(1)

  return (
    <RatingPercentageBarContainer>
      <span>{numStars} stars</span>
      <RatingPercentageBarWrapper>
        <Bar $perc={fillPerc} />
      </RatingPercentageBarWrapper>
      <Spacer size="md" />
      <span>{formatPercentage(Number(fillPerc))}</span>
    </RatingPercentageBarContainer>
  )
}

const Aggregates = ({ event }) => {
  // console.log({ event })

  const totalReviews = event?.aggregate_ratings?.total_ratings || 0

  return (
    <AggregatesWrapper>
      <AggregatesAverageInfo>
        <h1>{Number(event?.aggregate_ratings?.average_rating).toFixed(1)}</h1>
        <Stars rating={event?.aggregate_ratings?.average_rating} max={5} />
        {/* <p>Average Rating ({event?.aggregate_ratings?.total_ratings || 0} reviews) </p> */}
        <p>Average Rating</p>
        <p>
          ({totalReviews} total {totalReviews === 1 ? "review" : "reviews"})
        </p>
      </AggregatesAverageInfo>
      <AggregatesStarsBreakdown>
        {["five_stars", "four_stars", "three_stars", "two_stars", "one_stars"].map((countLabel, i) => (
          <Fragment key={countLabel}>
            <RatingPercentageBar
              count={event?.aggregate_ratings?.[countLabel]}
              numStars={5 - i}
              totalRatings={totalReviews}
            />
            {/* <Spacer size="xxs" /> */}
          </Fragment>
        ))}
      </AggregatesStarsBreakdown>
    </AggregatesWrapper>
  )
}

// const ReviewBoxInput = styled.div<{ $isFocused: boolean }>`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   height: 200px;
//   background: white;
//   border: solid 1px rgb(222, 222, 222);
//   width: 100%;
//   padding: 1rem 2rem;
//   border-radius: var(--border-radius-md);

//   & svg {
//     transform: translateY(0);
//     transition: all 0.3s ease;
//   }

//   & textarea {
//     transform: translateY(2rem);
//     margin-left: 1rem;
//     flex: 1;
//     border: none;
//     background: transparent;
//     outline: none !important;
//     resize: none;
//     height: 100px;
//     transition: all 0.3s ease;
//   }

//   ${(props) =>
//     props.$isFocused
//       ? `
//     & svg {
//       transform: translateY(-3rem);
//     }
//     & textarea {
//       transform: translateY(-1rem);
//     }
//   `
//       : ``}
// `

// const ReviewBox = ({ event }) => {
//   const [reviewText, setReviewText] = useState("")
//   const [isFocused, setIsFocused] = useState(false)
//   const [stars, setStars] = useState(null)

//   return (
//     <>
//       <ReviewBoxInput $isFocused={isFocused}>
//         <StarsInput value={stars} setValue={setStars} max={5} name="event-review-box" />
//         <IconComment />
//         <textarea
//           name="review"
//           value={reviewText}
//           onChange={(e) => setReviewText(e.target.value)}
//           onBlur={() => setIsFocused(false)}
//           onFocus={() => setIsFocused(true)}
//           // type="text"
//           placeholder="Write a review..."
//         />
//       </ReviewBoxInput>
//     </>
//   )
// }

const ReviewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 1px var(--background-color-very-light-blue);
  padding-top: 1rem;
`

const ReviewList = ({ event }) => {
  return (
    <ReviewListWrapper>
      {R.isNil(event?.reviews) || !event?.reviews?.length ? (
        <EmptyPromptCard elevation={"med"}>
          <h3>No reviews to show.</h3>
        </EmptyPromptCard>
      ) : null}
      {event?.reviews?.map((review: Review) => (
        <ReviewTile review={review} key={review.id} />
      ))}
    </ReviewListWrapper>
  )
}
