import { SyntheticEvent, useState } from "react"
import { Button, Input, Spacer } from "components"
import styled from "styled-components"

import { sleep } from "utils/async"

const AccountDeleteFormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 2 2 400%;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  input {
    width: 100%;
    margin-top: auto;
  }

  button {
    margin-top: auto;
  }
`

export default function AccountDeleteForm() {
  const [isLoading, setIsLoading] = useState(false)
  const [confirmDeletePassword, changeConfirmDeletePassword] = useState("")

  const handleContactSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    await sleep(500)
    setIsLoading(false)
  }

  return (
    <AccountDeleteFormWrapper>
      <form onSubmit={handleContactSubmit}>
        <h1>Delete Your Account</h1>
        <Spacer size="sm" />
        <Spacer size="sm" />
        <h2>Are you sure you want to delete your account?</h2>
        <Spacer size="sm" />
        <Spacer size="sm" />
        {/* <p>Enter your password below to confirm.</p> */}

        <Input
          fullWidth
          name="password"
          type="password"
          label="Enter your password to confirm account deletion"
          placeholder="Your password"
          value={confirmDeletePassword}
          onChange={(val) => changeConfirmDeletePassword(val)}
        />
        <Spacer size="sm" />
        <Spacer size="sm" />
        <Button
          type="submit"
          // buttonType="danger"
          isDestructive
          isLoading={isLoading}
          // onClick={() => handleDeleteAccountSubmit()}
        >
          DELETE ACCOUNT
        </Button>
      </form>
    </AccountDeleteFormWrapper>
  )
}
