import { Card, Spacer, UserAvatar } from "components"
import styled from "styled-components"

import { Profile } from "types/user"

const ProfileCardWrapper = styled((props) => <Card elevation="high" {...props} />)`
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
  margin-left: var(--page-side-padding);
  min-height: 500px;

  .username {
    color: var(--font-color-subdued);
    font-family: var(--font-primary);
    font-weight: 400;
  }

  & img {
    max-height: 300px;
    border-radius: var(--border-radius-lg);
    margin: 0 auto;
  }
`
const StyledAvatar = styled((props) => <UserAvatar {...props} />)`
  & img {
    // height: 300px;
    // width: 300px;
    // max-height: 300px;
    border-radius: var(--border-radius-lg);
    margin: 0 auto;
  }
`

interface IProfileCard {
  profile: Profile
}
export default function ProfileCard({ profile }: IProfileCard) {
  const defaultAvatarSrc = "vector_woman_avatar.png"
  const imageElement = (
    <picture>
      <source srcSet={profile?.image} />
      <img src={require(`images/${defaultAvatarSrc}?webp`)} alt="AN" />
    </picture>
  )

  return (
    <ProfileCardWrapper>
      {profile.image ? (
        <StyledAvatar
          noBackground={true}
          avatarSize="xl"
          image={profile.image}
          imgix_src={profile.imgix_src}
          name={profile.full_name}
          username={profile.username}
          is_social={profile.is_social}
        />
      ) : (
        <>{imageElement}</>
      )}

      <Spacer size="xs" />
      <h2 className="full_name">{profile.full_name}</h2>
      <Spacer size="xs" />
      <h3 className="username">@{profile.username}</h3>
      <Spacer size="lg" />
      <p>{profile.bio ? profile.bio : `This user hasn't written a bio yet.`}</p>
      <Spacer size="xs" />
    </ProfileCardWrapper>
  )
}
