import { SyntheticEvent, useState } from "react"
import { Button, TextArea, Spacer } from "components"
import styled from "styled-components"

import { sleep } from "utils/async"

const AccountPrivacyFormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    height: 200px;
    margin-top: auto;
  }

  button {
    margin-top: auto;
  }
`

export default function AccountPrivacyForm() {
  const [isLoading, setIsLoading] = useState(false)
  const [contactForm, setContactForm] = useState("")

  const handleContactSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    await sleep(500)
    setIsLoading(false)
  }

  return (
    <AccountPrivacyFormWrapper>
      <form onSubmit={handleContactSubmit}>
        <h1>Data Privacy</h1>
        <Spacer size="sm" />
        <Spacer size="sm" />
        <h3>We take privacy very seriously.</h3>
        <Spacer size="sm" />
        <p>Need to reach out about privacy concerns? Fill out the contact form below and we'll be in touch.</p>
        <Spacer size="sm" />
        <Spacer size="sm" />
        <Spacer size="sm" />
        <Spacer size="sm" />
        <TextArea
          name="privacy"
          resize="none"
          label="What would you like to inquire about?"
          value={contactForm}
          onChange={(val) => setContactForm(val)}
        />
        <Button
          isLoading={isLoading}
          // onClick={() => handleContactSubmit()}
          type="submit"
        >
          Contact us
        </Button>
      </form>
    </AccountPrivacyFormWrapper>
  )
}
