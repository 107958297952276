import { SyntheticEvent, useState } from "react"
import { Button, Input, Spacer } from "components"
import styled from "styled-components"

import { sleep } from "utils/async"

const AccountPreferencesFormWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  textarea {
    width: 100%;
    height: 200px;
    margin-top: auto;
  }

  button {
    margin-top: auto;
  }
`

export default function AccountPreferencesForm() {
  const [isLoading, setIsLoading] = useState(false)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [username, setUsername] = useState("")

  const handleContactSubmit = async (e: SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    await sleep(500)
    setIsLoading(false)
  }

  return (
    <AccountPreferencesFormWrapper>
      <form onSubmit={handleContactSubmit}>
        <h1>Preferences</h1>
        <Spacer size="sm" />

        <Input
          name="firstName"
          type="text"
          label="first name"
          placeholder="first name"
          value={firstName}
          onChange={(val) => setFirstName(val)}
        />
        <Input
          name="lastName"
          type="text"
          label="last name"
          value={lastName}
          placeholder="last name"
          onChange={(val) => setLastName(val)}
        />
        <Input
          name="username"
          type="text"
          label="username"
          value={username}
          placeholder="username"
          onChange={(val) => setUsername(val)}
        />
        <Button isLoading={isLoading} type="submit">
          Save Changes
        </Button>
      </form>
    </AccountPreferencesFormWrapper>
  )
}
