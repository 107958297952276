import * as R from "ramda"
// import { createSelector } from "reselect"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"
import initialState, { StateShape } from "models/initialState"
import { Actions as uiActions } from "models/ui"
import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"
// import { normalizeEventsFromServer, isEventInstance, extractEventsFromProvider } from "utils/events"
// import { normalizeReviewsFromServer } from "utils/reviews"
// import { isString, isNumber } from "utils/parseUtils"
import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"

import { ToastType } from "types/ui"
import { AuthAction, AuthActionType } from "types/auth"
import {
  EventID,
  EventFull,
  SingleEventSuccessAction,
  MultipleEventsSuccessAction,
  EventsActionType,
} from "types/events"
import {
  ReviewID,
  Review,
  ReviewCreate,
  ReviewUpdate,
  ReviewsState,
  ReviewAction,
  ReviewsActionType,
  ReviewSuccessAction,
  ReviewFailureAction,
  SubmitReviewAction,
  UpdateReviewAction,
  GenericReviewAction,
  FetchReviewAction,
} from "types/reviews"
import {
  MultipleProvidersSuccessAction,
  SingleProviderSuccessAction,
  ProvidersActionType,
  ProviderFull,
} from "types/providers"

const updateStateAfterSuccessfulReviewAction = (state: ReviewsState, action: ReviewSuccessAction) => {
  return {
    ...state,
    isCreating: false,
    isUpdating: false,
    isFetching: false,
    error: null,
    data: {
      ...state.data,
      [action.data.id]: action.data,
    },
    reviewIdToMetaLookup: {
      ...state.reviewIdToMetaLookup,
      [action.data.id]: {
        lastFetchedAt: new Date().toISOString(),
        fourOhFour: false,
      },
    },
  }
}

const updateStateAfterFailedReviewAction = (state: ReviewsState, action: ReviewFailureAction) => {
  return {
    ...state,
    isCreating: false,
    isUpdating: false,
    isFetching: false,
    error: action.error,
  }
}

const createStateUpdateForMultipleEvents = (state: ReviewsState, events: EventFull[]) => {
  const meta = { lastFetchedAt: new Date().toISOString(), fourOhFour: false }
  const reviews: Review[] = R.flatten(R.map((e) => e.reviews, events))
  const reviewIdToMetaLookup = reviews.reduce((acc, review) => {
    acc[review.id] = meta
    return acc
  }, {})

  return {
    ...state,
    data: {
      ...state.data,
      ...R.indexBy(R.prop("id"), reviews),
    },
    reviewIdToMetaLookup: {
      ...state.reviewIdToMetaLookup,
      ...reviewIdToMetaLookup,
    },
  }
}

const updateStateAfterSuccessfullyFetchingSingleEvent = (state: ReviewsState, action: SingleEventSuccessAction) => {
  return createStateUpdateForMultipleEvents(state, [action.data as EventFull])
}

const updateStateAfterSuccessfullyFetchingMultipleEvents = (
  state: ReviewsState,
  action: MultipleEventsSuccessAction
) => {
  return createStateUpdateForMultipleEvents(state, action.data as EventFull[])
}

const updateStateAfterSuccessfullyFetchingProvider = (state: ReviewsState, action: SingleProviderSuccessAction) => {
  const events = (action.data as ProviderFull).events
  return createStateUpdateForMultipleEvents(state, events as EventFull[])
}

const updateStateAfterSuccessfullyFetchingMultipleProviders = (
  state: ReviewsState,
  action: MultipleProvidersSuccessAction
) => {
  const events = R.flatten(R.map((provider) => provider.events as EventFull[], action.data as ProviderFull[]))
  return createStateUpdateForMultipleEvents(state, events as EventFull[])
}

export default function reviewsReducer(
  state = initialState.reviews,
  action:
    | ReviewAction
    | AuthAction
    | SingleEventSuccessAction
    | MultipleEventsSuccessAction
    | MultipleProvidersSuccessAction
    | SingleProviderSuccessAction
) {
  switch (action.type) {
    /* PROVIDER ACTIONS */
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleProviders(state, action)
    case ProvidersActionType.FETCH_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingProvider(state, action)

    /* EVENT ACTIONS */
    case EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleEvents(state, action)
    case EventsActionType.FETCH_EVENT_SUCCESS:
      return updateStateAfterSuccessfullyFetchingSingleEvent(state, action)
    case EventsActionType.FETCH_USER_EVENTS_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleEvents(state, action)

    /* REVIEW ACTIONS */
    case ReviewsActionType.SUBMIT_REVIEW:
      return { ...state, isCreating: true }
    case ReviewsActionType.SUBMIT_REVIEW_SUCCESS:
      return updateStateAfterSuccessfulReviewAction(state, action)
    case ReviewsActionType.SUBMIT_REVIEW_FAILURE:
      return updateStateAfterFailedReviewAction(state, action)
    case ReviewsActionType.FETCH_REVIEW_BY_ID:
      return { ...state, isFetching: true, error: null }
    case ReviewsActionType.FETCH_REVIEW_BY_ID_SUCCESS:
      return updateStateAfterSuccessfulReviewAction(state, action)
    case ReviewsActionType.FETCH_REVIEW_BY_ID_FAILURE:
      return updateStateAfterFailedReviewAction(state, action)
    case ReviewsActionType.UPDATE_REVIEW_BY_ID:
      return { ...state, isUpdating: true }
    case ReviewsActionType.UPDATE_REVIEW_BY_ID_SUCCESS:
      return updateStateAfterSuccessfulReviewAction(state, action)
    case ReviewsActionType.UPDATE_REVIEW_BY_ID_FAILURE:
      return updateStateAfterFailedReviewAction(state, action)
    case ReviewsActionType.SET_REVIEW_FOUR_OH_FOUR_ID:
      return {
        ...state,
        reviewIdToMetaLookup: {
          ...state.reviewIdToMetaLookup,
          [action.data]: {
            lastFetchedAt: new Date().toISOString(),
            fourOhFour: false,
          },
        },
      }
    case ReviewsActionType.CLEAR_USER_REVIEW_STATE:
      return initialState.reviews
    case AuthActionType.SIGN_USER_OUT:
      return initialState.events
    default:
      return state
  }
}

/**
 * REVIEWS ACTIONS
 */
const clearUserReviewState = () => ({ type: ReviewsActionType.CLEAR_USER_REVIEW_STATE })
const setReviewFourOhFourId = (reviewId: ReviewID) => ({
  type: ReviewsActionType.SET_REVIEW_FOUR_OH_FOUR_ID,
  data: reviewId,
})

export type SubmitReviewParams = { eventId: EventID; reviewCreate: ReviewCreate }
export type SubmitReviewResult = ThunkAction<Promise<ApiClientCallbackResponse>, StateShape, void, SubmitReviewAction>
const submitReview = ({ eventId, reviewCreate }: SubmitReviewParams): SubmitReviewResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/reviews/events/${eventId}/`,
        method: `POST`,
        types: {
          REQUEST: ReviewsActionType.SUBMIT_REVIEW,
          SUCCESS: ReviewsActionType.SUBMIT_REVIEW_SUCCESS,
          FAILURE: ReviewsActionType.SUBMIT_REVIEW_FAILURE,
        },
        options: {
          data: { review_create: reviewCreate },
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data, res }),
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Unable to create review.",
              contents:
                error === "Could not validate token credentials."
                  ? "You must be logged in to leave a review."
                  : makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

export type ReviewUpdateParams = { reviewId: ReviewID; reviewUpdate: ReviewUpdate }
export type ReviewUpdateResult = ThunkAction<Promise<ApiClientCallbackResponse>, StateShape, void, UpdateReviewAction>
const updateReview = ({ reviewId, reviewUpdate }: ReviewUpdateParams): ReviewUpdateResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/reviews/${reviewId}/`,
        method: `PUT`,
        types: {
          REQUEST: ReviewsActionType.UPDATE_REVIEW_BY_ID,
          SUCCESS: ReviewsActionType.UPDATE_REVIEW_BY_ID_SUCCESS,
          FAILURE: ReviewsActionType.UPDATE_REVIEW_BY_ID_FAILURE,
        },
        options: {
          data: { review_update: reviewUpdate },
          params: {},
        },
        onSuccess: (res) => {
          const toast = {
            title: "Success!",
            contents: "Your review has been updated.",
            type: ToastType.SUCCESS,
          }
          dispatch(uiActions.addUiToast({ toast }))

          return { success: true, status: res.status, data: res.data, res }
        },
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              title: "Unable to update review.",
              contents: makeUserFriendlyErrorMessage(error),
              type: ToastType.DANGER,
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

export type ReviewFetchByIdParams = { reviewId: ReviewID }
export type ReviewFetchByIdResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchReviewAction | GenericReviewAction
>
const fetchReviewById = ({ reviewId }: ReviewFetchByIdParams): ReviewFetchByIdResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/reviews/${reviewId}/`,
        method: `GET`,
        types: {
          REQUEST: ReviewsActionType.FETCH_REVIEW_BY_ID,
          SUCCESS: ReviewsActionType.FETCH_REVIEW_BY_ID_SUCCESS,
          FAILURE: ReviewsActionType.FETCH_REVIEW_BY_ID_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data, res }),
        onFailure: (res) => {
          dispatch(Actions.setReviewFourOhFourId(reviewId) as GenericReviewAction)
          return { success: false, status: res.status, error: res.error, res }
        },
      })
    )
  }
}

export const Actions = {
  clearUserReviewState,
  setReviewFourOhFourId,
  submitReview,
  updateReview,
  fetchReviewById,
}

/************************************
         REVIEW SELECTORS
*************************************/

export const selectReviewsForEventID = (state: StateShape, eventId: EventID): Record<ReviewID, Review> => {
  return R.pickBy((v, k) => R.equals(R.prop("event_id", v), eventId), state.reviews.data)
}

export const selectReviewsFromReviewIdList = (state: StateShape, eventIdList: EventID[]): Record<ReviewID, Review> => {
  return R.pick(eventIdList, state.reviews.data)
}

export const selectListOfReviewsFromReviewIdList = (state: StateShape, eventIdList: EventID[]): Review[] => {
  return R.values(selectReviewsFromReviewIdList(state, eventIdList))
}
