import { useState } from "react"
import { Avatar, Card, MarkdownDisplay, NextImage } from "components"
import styled from "styled-components"
import { formatDate } from "utils/dates"
import media from "utils/mediaQueryTemplates"

import { BlogPost } from "types/blog"

const StyledCard = styled((props) => <Card {...props} noPadding />)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  width: 100%;

  ${media.tablet`
  
  `}
`
const BlogPreviewImage = styled.div<{ $image: string }>`
  flex: 1 1 250px;
  background-image: url(${(props) => props.$image});
  background-size: cover;
`
const BlogPreviewBody = styled.div`
  flex: 1.5;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
`
const BlogPostCategory = styled.p`
  color: var(--color-secondary);
  text-transform: uppercase;
`
const BlogPostTitle = styled.h3`
  font-size: 26px;
  font-family: var(--font-primary);
  margin-top: 0.75rem;
`
const BlogPostSnippet = styled.div`
  font-size: 16px;
  color: var(--font-color-subdued-header);
  margin-top: 1rem;
`
const BlogPostMeta = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  align-items: center;
  margin-top: 1rem;
`
const BlogPostAuthor = styled.div`
  display: flex;
  flex-direction: column;

  & h4 {
    font-family: var(--font-primary);
    font-size: 14px;
  }
  & p {
    font-size: 15px;
    color: var(--font-color-subdued-header);
  }
`
const StyledNextImage = styled((props) => <NextImage pointer={false} {...props} />)``
const ImageWrapper = styled.div`
  max-height: 150px;
  max-width: 150px;
  min-height: 50px;
  min-width: 50px;
  position: relative;
`
interface IBlogPreviewCard {
  blogPost: BlogPost
}
export default function BlogPreviewCard({ blogPost }: IBlogPreviewCard) {
  const [hovered, setHovered] = useState(false)

  const avatarImage = (
    <ImageWrapper>
      <StyledNextImage src={blogPost.author.image} />
    </ImageWrapper>
  )

  return (
    <StyledCard
      elevation={hovered ? "very-high" : "med"}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <BlogPreviewImage
        $image={blogPost.cover_image}
        // onMouseOver={() => setHovered(true)}
        // onMouseOut={() => setHovered(false)}
      />
      <BlogPreviewBody
      //  onMouseOver={() => setHovered(true)}
      //  onMouseOut={() => setHovered(false)}
      >
        <BlogPostCategory>{blogPost.category}</BlogPostCategory>
        <BlogPostTitle>{blogPost.title}</BlogPostTitle>
        <BlogPostSnippet>
          <MarkdownDisplay source={blogPost.snippet} />
        </BlogPostSnippet>
        <BlogPostMeta>
          <Avatar avatarSize="sm" name={blogPost.author.name} image={avatarImage} />
          <BlogPostAuthor>
            <h4>{blogPost.author.name}</h4>
            <p>
              {formatDate(new Date(blogPost.created_at))} - {blogPost.time_to_read} min read
            </p>
          </BlogPostAuthor>
        </BlogPostMeta>
      </BlogPreviewBody>
    </StyledCard>
  )
}
