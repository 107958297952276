import { Fragment } from "react"
import { Button, Carousel, Spacer } from "components"
import { EventCard } from "views"
import styled from "styled-components"

import { EventCardPreview } from "types/events"

const EventCatalogWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  min-height: 500px;
  height: 100vh;
  max-height: 800px;
  padding: 1rem;
`
const EventCatalogCarousel = styled.div`
  // overflow-x: hidden;
  min-height: 420px;
`
const StyledEventCard = styled(EventCard)`
  margin: 0 1rem;
  min-width: 250px;
`

interface IEventCatalog {
  featuredEvents: EventCardPreview[]
}
export default function EventCatalog({ featuredEvents }: IEventCatalog) {
  const featuredEventElements = featuredEvents.map((event) => (
    <Fragment key={event.id}>
      <StyledEventCard event={event} />
      <Spacer size="xl" />
    </Fragment>
  ))

  return (
    <EventCatalogWrapper>
      <EventCatalogCarousel>
        <Carousel
          naturalSlideHeight={420}
          naturalSlideWidth={300}
          items={featuredEventElements}
          visibleSlides={3}
          title={<h2>Explore Upcoming Events</h2>}

          // slidesToShow
        />
      </EventCatalogCarousel>

      <Button buttonType="primary">Explore All Events</Button>
    </EventCatalogWrapper>
  )
}
