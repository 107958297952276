// import React from "react"
import {
  // Button,
  Card,
  IconClock,
  IconMapPositionPin,
} from "components"
import { UserFollowButtons } from "views"
import { formatDateShort } from "utils/dates"
import { formatFollowerAndFollowingCounts } from "utils/profiles"
import styled from "styled-components"

const UserInfoCard = styled(Card)`
  padding: 2rem;
  border-radius: 0.25rem;
  flex-direction: column;
  z-index: 1;
  position: relative;

  & > span.following-info {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > h4 {
      font-size: 1.1rem;
      font-family: var(--font-primary);
    }
  }

  & > p {
    display: grid;
    // grid-template-columns: 50px auto;
    margin-top: 1rem;
  }

  & .edit-profile-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
  }

  .location,
  .member-since {
    display: grid;
    margin-top: 1rem;
    grid-template-columns: 50px auto;
    align-items: center;
    color: var(--background-color-very-light-blue);
    color: var(--font-color-subdued);
  }
`

export default function ProfileStatsCard({ currentProfile }) {
  return (
    <UserInfoCard elevation={"med"}>
      <UserFollowButtons profile={currentProfile} />
      <span className="following-info">
        <h4>
          {formatFollowerAndFollowingCounts(currentProfile.num_followers)} follower
          {currentProfile.num_followers === 1 ? "" : "s"}
        </h4>
        <h4>{formatFollowerAndFollowingCounts(currentProfile.num_following)} following</h4>
      </span>

      <p className="location">
        <IconMapPositionPin />
        <span>{currentProfile.location ? currentProfile.location.address : `No location specified.`}</span>
      </p>
      <p className="member-since">
        <IconClock />
        <span>Member since {formatDateShort(new Date(currentProfile.created_at))}</span>
      </p>
    </UserInfoCard>
  )
}
