import { Fragment } from "react"
import { Spacer } from "components"
import { ReviewCard } from "views"
import styled from "styled-components"

import { ReviewFull } from "types/reviews"

const EventReviewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  margin: 0 auto;
  padding-top: 2rem;
  border-top: solid 1px var(--border-color);
`

export default function EventReviewList({ event }) {
  return (
    <EventReviewListWrapper>
      {event.reviews?.map((review: ReviewFull) => (
        <Fragment key={review.id}>
          <ReviewCard review={review} />
          <Spacer size="lg" />
        </Fragment>
      ))}
    </EventReviewListWrapper>
  )
}
