import { useState } from "react"
import Link from "next/link"
import { useRouter } from "next/router"
import { useDispatch } from "react-redux"
import { Actions as authActions } from "models/auth"
import { Button, Card, Input } from "components"
import { validatePasswordLength } from "utils/validation"
import styled from "styled-components"
import media from "utils/mediaQueryTemplates"

const ChangePasswordFormCard = styled((props) => <Card {...props} />)`
  position: relative;
  z-index: var(--z-index-small);
  display: flex;
  flex-direction: column;
  padding: 2rem;
  border-radius: 0.25rem;
  min-width: 500px;
  max-width: 900px;
  margin: 2rem auto;

  ${media.mobileOnly`
    min-width: 280px;
    max-width: var(--mobile-width);
  `}
`

const SuccessTitle = styled.p`
  font-size: 1.4em;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: var(--z-index-small);
`
const SuccessLinkText = styled.p`
  font-size: 0.9em;
`
const SuccessSvg = styled.img`
  max-width: 280px;
  max-height: 280px;
  margin: 20px;
`
const Red = styled.p`
  color: var(--red-500);
`
const StyledButton = styled((props) => <Button {...props} />)`
  margin-top: auto;
`

const svg = "/assets/images/undraw_environment.svg"

export default function ResetPasswordConfirmationForm() {
  const dispatch = useDispatch()
  const [success, setSuccess] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [password, setPassword] = useState<string>("")
  const [passwordConfirm, setPasswordConfirm] = useState<string>("")
  const [formError, setError] = useState<string | null>(null)
  const [providedError, setProvidedError] = useState<string | null>(null) // check if passwords match

  const router = useRouter()
  const { token } = router.query

  const handleOnSubmit = async () => {
    setLoading(true)

    if (!validatePasswordLength(password) || !validatePasswordLength(passwordConfirm)) {
      setError("Passwords must be at least 7 characters long.")
      return setLoading(false)
    } else {
      setError(null)
    }

    if (passwordConfirm !== password) {
      setError("Password's don't match")
      return setLoading(false)
    } else {
      setError(null)
    }

    try {
      dispatch(authActions.sendPasswordChangeRequest({ password, token } as { password: string; token: string }))
      setSuccess(true)
    } catch (error) {
      setError(`Error with password change: ${error}`)
      setPassword("")
      setPasswordConfirm("")
    }

    setLoading(false)
  }

  const handleConfirmPasswordChange = (password_confirm_value: string): void => {
    if (password_confirm_value !== password) {
      setProvidedError("Password's don't match")
    } else {
      setProvidedError(null)
    }
    setPasswordConfirm(password_confirm_value)
  }

  const renderCardContents = () => {
    if (success) {
      return (
        <>
          <SuccessTitle>Password successfully reset!</SuccessTitle>
          <SuccessSvg src={svg} alt="environment logo" />
          <SuccessLinkText>
            When you're ready, login <Link href="/login">here</Link>.
          </SuccessLinkText>
        </>
      )
    }

    return (
      <>
        <h3>Enter your a new password below</h3>
        <br />
        {formError ? <Red>{formError}</Red> : null}
        <Input
          name="new_password"
          type="password"
          label="new password"
          placeholder="New password"
          value={password}
          onChange={(password) => setPassword(password)}
          required
        />
        <Input
          name="confirm_new_password"
          type="password"
          label="confirm new password"
          value={passwordConfirm}
          placeholder="Confirm new password"
          providedError={providedError}
          onChange={(passwordConfirm) => handleConfirmPasswordChange(passwordConfirm)}
          required
        />
        <StyledButton
          buttonType="primary"
          onClick={() => handleOnSubmit()}
          isLoading={isLoading}
          isDisabled={isLoading}
        >
          Change Password
        </StyledButton>
      </>
    )
  }

  return (
    <ChangePasswordFormCard>
      <>{renderCardContents()}</>
    </ChangePasswordFormCard>
  )
}
