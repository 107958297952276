import { useRouter } from "next/router"
import { Badge, Card, MarkdownDisplay } from "components"
import { ProviderCoverImage } from "views"
import { truncate } from "utils/format"
// import { formatDate } from "utils/dates"
import styled from "styled-components"

import { BadgeType } from "types/ui"

const ProviderFeatureCardWrapper = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
  width: 400px;
  height: 450px;
  cursor: pointer;
`
const ProviderFeatureCardImageSection = styled.div`
  flex: 1 0 200px;
  min-height: 200px;
  height: 200px;
  max-height: 200px;
  overflow: hidden;
`
const StyledProviderImage = styled((props) => <ProviderCoverImage {...props} />)`
  height: 200px;
`
const ProviderFeatureCardBody = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;

  & > h3 {
    margin-top: 1rem;
  }
  & p {
    font-size: 16px;
  }
`
const ProviderFeatureCardMeta = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
`
const StyledBadge = styled((props) => <Badge {...props} />)``

export default function ProviderFeatureCard({ provider }) {
  const router = useRouter()

  // const renderDateRange = () => {
  //   if (event.ongoing) return <MetaChip text="Ongoing" chipType={ChipType.SECONDARY} chipSize="sm" />
  //   if (!event.date) return null

  //   return <MetaChip text={formatDate(new Date(event.date))} chipType={ChipType.SECONDARY} chipSize="sm" />
  // }

  // const renderPrice = () => {
  //   if (Number(event.price) === 0)
  //     return <MetaChip className="accent" text={`FREE`} chipType={ChipType.SECONDARY} chipSize="sm" />

  //   return <MetaChip className="accent" text={`$${event.price}`} chipType={ChipType.SECONDARY} chipSize="sm" />
  // }

  // const renderVirtual = () => {
  //   if (event.virtual) {
  //     return <MetaChip text={"Virtual"} chipType={ChipType.PRIMARY} chipSize="sm" />
  //   }

  //   return null
  // }

  return (
    <ProviderFeatureCardWrapper onClick={() => router.push(`/providers/s/${provider.slug}/`)}>
      <ProviderFeatureCardImageSection>
        <StyledProviderImage provider={provider} />
      </ProviderFeatureCardImageSection>
      <ProviderFeatureCardBody>
        <h3>{provider.name}</h3>
        <MarkdownDisplay source={truncate(provider.description, 140, true)} />
        <ProviderFeatureCardMeta>
          <StyledBadge badgeType={BadgeType.SECONDARY} text={`${provider.events?.length || 0} events`} />
          {/* <span className="dates">{renderDateRange()}</span>
          <span className="price">{renderPrice()}</span>
          <span className="virtual">{renderVirtual()}</span> */}
        </ProviderFeatureCardMeta>
      </ProviderFeatureCardBody>
    </ProviderFeatureCardWrapper>
  )
}
