import Link from "next/link"
import { Badge, Card } from "components"
import { EventImage } from "views"
import styled from "styled-components"

import { URLS } from "utils/urls"

import { Event } from "types/events"
import { BadgeType } from "types/ui"

const ProfileEventListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const EmptyPromptCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  font-weight: bold;
  border-radius: 0.25rem;

  z-index: var(--z-index-small);
  position: relative;
`
const EventPreviewCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  padding: 1rem 2rem;
  border-radius: 0.25rem;
  margin-top: 1rem;
  z-index: var(--z-index-small);
  position: relative;
  color: #212121;

  & h3 {
    transition: color: 0.1s ease;
  }

  &:hover {
    & h3 {
      color: var(--color-primary);
    }
  }
`
const ImageHolder = styled.div`
  max-width: 150px;
  max-height: 150px;
  padding: 1rem;
`
const EventPreviewName = styled.div``
const EventPreviewMeta = styled.div``

const badgeTypeToDisplayMapping = {
  attended: "ATTENDED",
  is_owner: "EVENT HOST",
  is_registered: "REGISTERED",
  reviewed: "REVIEWED",
}

interface IEventPreview {
  event: Event
}
function EventPreview({ event }: IEventPreview) {
  return (
    <Link href={URLS.event_by_slug(event.slug)}>
      <EventPreviewCard elevation={"high"}>
        <ImageHolder>
          <EventImage event={event} />
        </ImageHolder>
        <EventPreviewName>
          {/* <Link href={`/events/s/${event.slug}/`}> */}
          <h3>{event.name}</h3>
          {/* </Link> */}
          <p>{event.event_type}</p>
        </EventPreviewName>
        <EventPreviewMeta>
          {["attended", "is_owner", "is_registered", "reviewed"]
            .filter((v) => Boolean(event[v]))
            .map((badge) => (
              <Badge text={badgeTypeToDisplayMapping[badge]} badgeType={BadgeType.PRIMARY} key={badge} />
            ))}
        </EventPreviewMeta>
      </EventPreviewCard>
    </Link>
  )
}

interface IProfileEventList {
  eventPreviews: Event[]
}
export default function ProfileEventList({ eventPreviews }: IProfileEventList) {
  return (
    <ProfileEventListWrapper>
      {eventPreviews?.length === 0 || !eventPreviews?.length ? (
        <EmptyPromptCard elevation={"med"}>
          <h3>No events to show.</h3>
        </EmptyPromptCard>
      ) : null}
      {eventPreviews?.map((eventPreview) => (
        <EventPreview event={eventPreview} key={eventPreview.slug} />
      ))}
    </ProfileEventListWrapper>
  )
}
