import styled from "styled-components"
import Link from "next/link"
import { Card, NextLink, Spacer } from "components"
import { ReviewCard } from "views"
import { Diamond } from "visualizations"
import { Icon } from "components"
import media from "utils/mediaQueryTemplates"

import { URLS } from "utils/urls"

const LandingHowItWorksWrapper = styled.div`
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`
const HowItWorksWrapper = styled.section`
  height: 80vh;
  min-height: 600px;
  flex: 1;
  display: flex;
  flex-direction: column;
`
const HowItWorksOneWrapper = styled(HowItWorksWrapper)`
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  flex: 1;

  ${media.small`
  
  `}

  ${media.tablet`
    flex-direction: row;  
    align-items: center;  
  `}
`
const HowItWorksTwoWrapper = styled(HowItWorksWrapper)`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  padding: 2rem 0;
  flex: 1;

  ${media.tablet`
    flex-direction: row;  
    align-items: center;  
  `}
`
const HowItWorksThreeWrapper = styled(HowItWorksWrapper)`
  max-width: 1300px;
  margin: 0 auto;

  & svg {
    height: 32px;
    width: 32px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  flex: 1;

  ${media.tablet`
    flex-direction: row;  
    align-items: center;  
  `}
`

const HowItWorksTextWrapper = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  // max-width: var(--mobile-width);
  width: 100%;

  & > h3 {
    font-family: var(--font-primary);
    font-size: 2.5rem;
  }

  & > p {
    font-size: 1.35rem;
    line-height: 2rem;
    margin: 1.5rem 0;
    max-width: var(--mobile-width);
  }

  ${media.small`
    max-width: 90%;

    & > h3 {
      font-size: 2.75rem;
    }

    & > p {
      font-size: 1.45rem;
      line-height: 2rem;
      margin: 1.5rem 0;
    }  
  `}

  ${media.medium`
    max-width: 80%;
  `}

  ${media.tablet`
    & > h3 {
      font-size: 2.75rem;
    }

    & > p {
      margin: 1.5rem 0;
      font-size: 1.45rem;
      line-height: 2rem;
      max-width: 80%;
    }
  `}
`
const HowItWorksIllustration = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(-30px);
  position: relative;
  z-index: 0;

  ${media.tablet`
    transform: translateY(0px);
  `}
`
const HowItWorksContent = styled(HowItWorksTextWrapper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 2rem 10px;

  ${media.small`
    padding: 2rem;
  `}
`
const RightHowItWorksContent = styled(HowItWorksContent)`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  margin-right: auto;

  ${media.small`
    margin-left: auto;
    margin-right: 0;
  `}
`
const RightHowItWorksContentText = styled(HowItWorksTextWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: auto;

  & > p {
    max-width: 100%;
    background: rgba(222, 222, 222, 0.1);
  }
`

const SampleReviewWrapper = styled.div`
  transform: translateX(0px) translateY(10px);

  ${media.tablet`
    transform: translateX(50px) translateY(10px);
  `}
`
const SearchPreviewWrapper = styled.div``

const TurqoiseCircleBg = styled.div`
  border-radius: 50%;
  background: var(--color-secondary);
  height: 500px;
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.5rem;
`
const EventImagePreview = styled.div``

const HowItWorksFeatures = styled(HowItWorksTextWrapper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`
const AccentCircleBg = styled.div`
  position: absolute;
  height: 440px;
  width: 440px;
  max-width: var(--mobile-width);
  right: 0px;
  top: -50px;
  border-radius: 50%;
  background: var(--color-accent);
  z-index: -1;
  transform: translateY(100px);

  ${media.small`
    right: 50px;  
  `}

  ${media.tablet`
    transform: translateY(0px);  
  `}
`
const HowItWorksGrid = styled.div`
  display: grid;

  grid-template-rows: 1fr 1fr;
  grid-template-columns: repeat(2, minmax(200px, 1fr));
  grid-gap: 20px;

  ${media.tablet`
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    grid-gap: 20px;  
  `}
  margin-top: 50px; ;
`
const StyledCard = styled((props) => <Card {...props} elevation={"high"} />)`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
`

const review = {
  username: "jastornaut",
  rating: 4.9,
  comment: `Computer science teacher training at its finest. This pd is anything but passive. You'll be working with other talented educators, evaluating their lessons, and getting gallons of feedback on your own. The days are long, but it's absolutely worth it.`,
  event_name: "Upperline Teacher Bootcamp",
  image: "https://www.jeffastor.com/static/ae5b8983beb959c74aa2f32564ab10c9/9a128/astor_teaching.jpg",
  owner: "0",
  event: "0",
  updated_at: new Date(),
  created_at: new Date(),
  date: new Date(),
  verified: "LinkedIn",
  is_social: true,
}

const SampleReview = () => {
  return (
    <SampleReviewWrapper>
      <ReviewCard review={review} maxLength={130} />
    </SampleReviewWrapper>
  )
}

const SearchPreview = () => {
  return <SearchPreviewWrapper></SearchPreviewWrapper>
}

const FeatureCard = ({ feature }) => {
  return (
    <StyledCard>
      <p>{feature.title}</p>
      <small>{feature.icon}</small>
    </StyledCard>
  )
}

const HowItWorksOne = () => {
  return (
    <HowItWorksOneWrapper>
      <HowItWorksContent>
        <h3>Create Reviews</h3>
        {/* <p>Had an amazing experience with professional development? Let other educators know.</p> */}
        <p>
          Leave a review for your favorite PD, find reviews from other educators, and engage with the community on where
          to find the best resources.
        </p>
        <NextLink to={URLS.reviews}>
          <span className="primary-link">
            Submit A Review <span>&#8594;</span>{" "}
          </span>
        </NextLink>
      </HowItWorksContent>
      <HowItWorksIllustration>
        <TurqoiseCircleBg>
          <EventImagePreview />
          <SampleReview />
        </TurqoiseCircleBg>
      </HowItWorksIllustration>
    </HowItWorksOneWrapper>
  )
}

const HowItWorksTwo = () => {
  return (
    <HowItWorksTwoWrapper>
      <HowItWorksIllustration>
        <Diamond>
          <>&nbsp;</>
        </Diamond>
      </HowItWorksIllustration>

      <RightHowItWorksContent>
        {/* <Spacer size="xxxl" /> */}
        <RightHowItWorksContentText>
          <h3>Search Events</h3>
          <p>
            Look through our comprehensive library of professional development opportunties and explore evaluations made
            by other educators.
          </p>
          {/* <p>Sort by highest rated, most reviewed, or find events in your area</p> */}
          <NextLink to={URLS.search} type="accent">
            <span>
              Start Searching <span>&#8594;</span>{" "}
            </span>
          </NextLink>
        </RightHowItWorksContentText>
      </RightHowItWorksContent>
    </HowItWorksTwoWrapper>
  )
}

const defaultFeatureCards = [
  {
    id: 1,
    title: `Find Events`,
    icon: <Icon iconName="exhibit" fillRect="var(--color-secondary-a40)" stroke={"var(--font-color-less-dark)"} />,
  },
  { id: 2, title: `Source Presenters`, icon: <Icon iconName="user_circle" stroke="var(--font-color-less-dark)" /> },
  {
    id: 3,
    title: `Follow Providers`,
    icon: (
      <Icon
        iconName="school"
        fillLight="var(--color-primary)"
        fillLightGrey="var(--pure-white)"
        fillDark="var(--background-color-very-light-blue)"
        fillGrey="var(--background-color-very-light-blue)"
        strokeGrey="var(--font-color-less-dark)"
      />
    ),
  },
  { id: 4, title: `Rate Services`, icon: <Icon iconName="star" /> },
  {
    id: 5,
    title: `Review Experiences`,
    icon: <Icon iconName="heart" fill={"var(--color-accent)"} />,
  },
  {
    id: 6,
    title: `Explore Data`,
    icon: <Icon iconName="statistics" fillSecond="var(--color-secondary)" stroke={"var(--font-color-less-dark)"} />,
  },
  {
    id: 7,
    title: `Drive Learning`,
    icon: (
      <Icon iconName="browser_paragraph" fillRect="var(--color-secondary-a40)" stroke={"var(--font-color-less-dark)"} />
    ),
  },
  {
    id: 8,
    title: `Join the Convo`,
    icon: (
      <Icon
        iconName="comments"
        fillSecondComment={"var(--color-secondary-a40)"}
        stroke={"var(--font-color-less-dark)"}
      />
    ),
  },
]

const HowItWorksThree = ({ featureCards = defaultFeatureCards }) => {
  return (
    <HowItWorksThreeWrapper>
      <HowItWorksFeatures>
        <h3>Design Your Own Development</h3>
        <HowItWorksGrid>
          <AccentCircleBg />
          {featureCards.map((feature) => (
            <FeatureCard key={feature.id} feature={feature} />
          ))}
        </HowItWorksGrid>
      </HowItWorksFeatures>
    </HowItWorksThreeWrapper>
  )
}

export default function LandingHowItWorks() {
  return (
    <LandingHowItWorksWrapper>
      <HowItWorksOne />
      <HowItWorksTwo />
      <HowItWorksThree />
    </LandingHowItWorksWrapper>
  )
}
