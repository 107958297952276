import * as R from "ramda"
import { isStringOrNumber } from "utils/parseUtils"
import { User } from "types/user"

export const isUserInstance = (user: any): user is User => {
  if (!user) return false

  if (isStringOrNumber(user)) return false

  if (
    R.all(
      (p) => user.hasOwnProperty(p) // check that user contains all required
    )(
      ["id", "username", "email"] // required props for a user model
    )
  ) {
    return true
  }

  return false
}

// import { isProviderInstance } from "utils/providers"
// import { isProfileInstance } from "utils/profiles"
// import { User } from "types/auth"
// import { Profile, NormalizedProfile } from "types/user"
// import { ProviderFull } from "types/providers"

// export const normalizeAuthenticatedUser = (user: User) => {
//   return R.omit(
//     ["preferences"],
//     // keep everything but preferences
//     {
//       ...user,
//       // override profile with profile id
//       profile: isProfileInstance(user?.profile) ? user.profile.id : user?.profile,
//     }
//   )
// }

// export const normalizeProfile = (profile: Profile): NormalizedProfile => {
//   return {
//     ...profile,
//     event_previews: R.map((e) => e.id, profile.event_previews || []),
//   }
// }

// export const extractUserProfilesFromProvider = (provider: ProviderFull): Profile[] => {
//   if (!isProviderInstance(provider)) return []

//   return R.filter(
//     (p) => isProfileInstance(p),
//     R.map(
//       (user) => (isStringOrNumber(user.profile) ? null : user.profile),
//       // extract a list of users from admin and members properties
//       R.concat(
//         R.values(R.pick(["admin", "members"], provider)).flat(),
//         // concat them with the provider owner in an array
//         [R.prop("owner", provider)]
//       ) as User[] // cast them as user objects
//     )
//   )
// }

// export const extractNormalizedUserProfilesFromProvider = (provider: ProviderFull): NormalizedProfile[] => {
//   return R.map((profile) => normalizeProfile(profile), extractUserProfilesFromProvider(provider))
// }

// export const extractNormalizedUserProfilesFromProvider = R.pipe(
//   extractUserProfilesFromProvider,
//   R.map(normalizeProfile),
// )

// export const extractNormalziedUserProfilesFromProvider = (provider: ProviderFull): NormalizedProfile[] => R.pipe(
//   extractUserProfilesFromProvider,
//   R.map(normalizeProfile)
// )
