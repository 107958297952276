import { useRef, useState } from "react"
import Link from "next/link"
import { signin } from "next-auth/client"
import styled from "styled-components"
import {
  Button,
  // Card,
  Checkbox,
  IconEmail,
  IconColoredTwitter as IconTwitter,
  IconColoredLinkedin as IconLinkedin,
  IconPassword,
  IconUserCircle,
  ReactHookFormInput,
  Spacer,
  SmallRedText,
} from "components"
import { useForm } from "react-hook-form"
import { useToasts } from "hooks/ui/useToasts"
import { isValidEmail, validateUsername } from "utils/validation"
import { noop } from "utils/misc"
import { sleep } from "utils/async"

import { Email, Username } from "types/common"
import { SignupCredentials } from "types/auth"
import { ToastType } from "types/ui"

const RegistrationFormWrapper = styled.div``

const FormHeader = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: var(--font-color-subdued-header);
  margin-bottom: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    height: 32px;
    width: 32px;
  }

  & small {
    color: var(--color-primary);
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
  }
`
const FormSubheader = styled.p`
  font-size: 16px;
  color: var(--font-color-subdued-header);
  margin-bottom: 1rem;
`
const StyledTextInput = styled((props) => <ReactHookFormInput {...props} />)`
  margin-top: 0.5rem;
`
const SignupButton = styled((props) => <Button {...props} />)`
  width: 100%;
`
const OAuthButton = styled((props) => <Button {...props} />)`
  width: 100%;

  & span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & svg {
      height: 26px;
      width: 26px;

      & path {
        // fill: var(--color-primary);
        // fill: var(--color-secondary);
      }
    }

    & span {
      flex: 1;
      display: flex;
      justify-content: center;
      padding-right: 10px;
    }
  }
`
const CheckboxLabel = styled.span`
  font-size: 14px;
`
const SmallIconUserCircle = styled((props) => <IconUserCircle {...props} />)`
  height: 24px;
  width: 24px;
`
const ErrorMessage = styled((props) => <SmallRedText {...props} />)`
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px !important;
`

interface RegistrationFormState {
  email: Email
  username: Username
  password: string
  passwordConfirm: string
}

interface IRegistrationForm {
  onClose?: () => void
  handleOnUserSignup: (creds: SignupCredentials) => Promise<any>
}
const RegistrationForm: React.FC<IRegistrationForm> = ({ onClose = noop, handleOnUserSignup }) => {
  const [isChecked, setIsChecked] = useState(false)
  const [checkboxError, setCheckboxError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [, toastHandlers] = useToasts()
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setError,
    reset,
    formState: { errors },
  } = useForm<RegistrationFormState>({
    mode: "onTouched",
  })
  const password = useRef({})
  password.current = watch("password", "")

  const toggleCheckbox = () => {
    setIsChecked((s) => !s)
  }

  const handleOnRegister = async (data: SignupCredentials) => {
    const values = getValues()
    // console.log(data)
    setIsLoading(true)

    if (!isChecked) {
      // console.log("Not checked!")
      setCheckboxError("Please agree to the terms.")
      setIsLoading(false)
      return
    }

    if (values?.password !== values?.passwordConfirm) {
      setError("passwordConfirm", {
        type: "manual",
        message: "Passwords must match",
      })
      setIsLoading(false)
      return
    }

    const res = await handleOnUserSignup(data)
    // console.log({ res })
    if (res?.error) {
      await sleep(1000)
      const toast = {
        title: "Registration unsuccessful.",
        type: ToastType.DANGER,
        contents: res.error,
      }
      toastHandlers.addUiToast(toast)
      reset({ password: "", passwordConfirm: "", email: values.email, username: values.username })
    }

    setIsLoading(false)
  }

  const handleOnError = async (errors, e) => {
    setIsLoading(true)
    const values = getValues()

    const hasErrors = errors?.email?.message || errors?.password?.message

    if (hasErrors) {
      const toast = {
        title: "Form error.",
        type: ToastType.DANGER,
        contents: `Please correct the errors in the form before logging in..`,
      }
      toastHandlers.addUiToast(toast)
      setIsLoading(false)
      return
    }

    // await sleep(1000)
    reset({ email: values.email, username: values.username, password: "", passwordConfirm: "" }, { keepErrors: true })
    setIsLoading(false)
  }

  return (
    <RegistrationFormWrapper>
      <FormHeader>
        <span>Sign up for Velup</span>
        <small tabIndex={0} role="button" onClick={onClose}>
          Login here
        </small>
      </FormHeader>
      {/* <FormSubheader>
        Velup is totally free to use. Sign up using your email address or social authentication below to get started.
      </FormSubheader> */}
      <FormSubheader>Use your email address or socials to get started.</FormSubheader>
      <form onSubmit={handleSubmit(handleOnRegister, handleOnError)}>
        <StyledTextInput
          label="Email"
          name="email"
          type="email"
          placeholder="user@email.io"
          fullWidth
          iconLeft={<IconEmail strokeWidth={2} stroke={"var(--font-color-subdued)"} />}
          register={{
            ...register("email", {
              required: "Email is required",
              validate: (value: string) => isValidEmail(value) || "Please enter a valid email",
            }),
          }}
          providedError={errors?.email?.message}
        />
        <StyledTextInput
          label="Username"
          name="username"
          placeholder="your_username"
          fullWidth
          iconLeft={<SmallIconUserCircle strokeWidth={4} stroke={"var(--font-color-subdued)"} />}
          register={{
            ...register("username", {
              required: "Username is required",
              validate: {
                minLength: (v) => v?.length >= 3 || "Username should be at least 3 characters",
                isValid: (v) =>
                  validateUsername(v) || "Username must consist of only letters, numbers, underscores, and dashes.",
              },
            }),
          }}
          providedError={errors?.username?.message}
        />
        <StyledTextInput
          label="Password"
          name="password"
          fullWidth
          iconLeft={<IconPassword strokeWidth={2} stroke={"var(--font-color-subdued)"} />}
          placeholder="Password"
          type="password"
          register={{
            ...register("password", {
              required: "Password is required",
              minLength: {
                value: 7,
                message: "Password must have at least 7 characters",
              },
            }),
          }}
          providedError={errors?.password?.message}
        />
        <StyledTextInput
          label="Confirm Password"
          name="passwordConfirm"
          fullWidth
          iconLeft={<IconPassword strokeWidth={2} stroke={"var(--font-color-subdued)"} />}
          placeholder="Confirm your password"
          type="password"
          register={{
            ...register("passwordConfirm", {
              required: "Confirm your password, please",
              validate: (value) => value === password.current || "Passwords do not match",
            }),
          }}
          providedError={errors?.passwordConfirm?.message}
        />

        <Spacer size="lg" />

        {checkboxError && <ErrorMessage>{checkboxError}</ErrorMessage>}
        <Checkbox
          isChecked={isChecked}
          toggleChecked={toggleCheckbox}
          label={
            <CheckboxLabel>
              I agree to the <Link href="/terms-of-service/">terms and conditions</Link>
            </CheckboxLabel>
          }
        />

        <Spacer size="lg" />

        <SignupButton isLoading={isLoading} buttonType="primary" type="submit">
          Create Account
        </SignupButton>
      </form>

      <Spacer size="xl" />

      <svg width="100%" height="20px">
        <line x1={0} x2={"45%"} y1={10} y2={10} stroke="var(--font-color-subdued)" strokeWidth={1} />
        <text x={"50%"} y={15} fill="var(--font-color-subdued-header)">
          or
        </text>
        <line x1={"57%"} x2={"100%"} y1={10} y2={10} stroke="var(--font-color-subdued)" strokeWidth={1} />
      </svg>

      <Spacer size="lg" />

      <OAuthButton buttonType="secondary" type="button" onClick={() => signin("linkedin")}>
        <span>
          <IconLinkedin /> <span>Continue with Linkedin</span>
        </span>
      </OAuthButton>

      <Spacer size="lg" />

      <OAuthButton buttonType="secondary" type="button" onClick={() => signin("twitter")}>
        <span>
          <IconTwitter /> <span>Continue with Twitter</span>
        </span>
      </OAuthButton>

      <Spacer size="lg" />
    </RegistrationFormWrapper>
  )
}

export default RegistrationForm
