// import { useState, Fragment } from "react"
import { IconSuccess, MarkdownDisplay, Title, Spacer, Stars, UserAvatar } from "components"
import { truncate } from "utils/format"
import styled from "styled-components"
import { formatDate } from "utils/dates"

const ReviewTileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 2rem 10px;
  border-bottom: solid 1px var(--background-color-very-light-blue);

  & .verification {
    display: flex;
    align-items: center;
    color: var(--font-color-subdued-header);
    font-size: 15px;
  }
`
const ReviewCardMeta = styled.div`
  display: flex;
  align-items: center;
  color: var(--font-color-subdued-header);
  font-size: 15px;
`
const ReviewUsername = styled.p`
  font-weight: bold;
  color: var(--color-primary);
  font-size: 22px;
`
const ReviewBody = styled.div`
  margin: 5px 0;
`
const AvatarColumn = styled.div<{ $size: "sm" | "md" | "lg" }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;

  ${(props) => `
  ${props.$size === "sm" ? "width: 70px;" : ""}
  ${props.$size === "md" ? "width: 100px;" : ""}
  ${props.$size === "lg" ? "width: 120px;" : ""}
`}
`
const ContentColumn = styled.div`
  flex: 1;
  padding-left: 1.15rem;

  time {
    color: var(--font-color-subdued);
    font-size: 16px;
  }
`
const ReviewActions = styled.div`
  display: flex;
  align-items: center;
`
const StyledSuccessIcon = styled((props) => <IconSuccess stroke="var(--color-secondary)" strokeWidth={3} {...props} />)`
  height: 20px;
  width: 20px;
  margin-right: 5px;
`

const ReviewTile = ({ review, size = "sm", maxLength = null }) => {
  const renderVerification = () => {
    return (
      <>
        {review.is_social ? (
          <span className="verification">
            <StyledSuccessIcon /> <p>Verified via {review.is_social}</p> <Spacer size="sm" />
          </span>
        ) : null}
      </>
    )
  }

  return (
    <ReviewTileWrapper>
      <AvatarColumn $size={size as "sm" | "md" | "lg"}>
        <UserAvatar
          username={review.username}
          image={review.image}
          imgix_src={review.imgix_src}
          is_social={review.is_social}
          avatarSize={size}
        />
      </AvatarColumn>
      <ContentColumn>
        <ReviewUsername>{review.full_name || review.username}</ReviewUsername>
        {renderVerification()}

        {/* {renderReviewCardMeta()} */}
        <ReviewCardMeta>
          <Stars rating={review.rating} max={5} size="sm" />
          <Spacer size="md" />
          <time>{formatDate(new Date(review.created_at))}</time>
        </ReviewCardMeta>

        <Spacer size="md" />

        <ReviewBody>
          <h4>{review.headline}</h4>
          <MarkdownDisplay source={maxLength ? truncate(review.comment, maxLength, true) : review.comment} />
        </ReviewBody>

        <Spacer size="lg" />

        <ReviewActions>
          <ReviewCardMeta>
            <span>Was this review helpful?</span>
            <Spacer size="md" />
            <span className="secondary-text bold pointer">YES</span>
            <Spacer size="md" />
            <span>NO</span>
            <Spacer size="md" />
            <span>Report</span>
          </ReviewCardMeta>
        </ReviewActions>
      </ContentColumn>
    </ReviewTileWrapper>
  )
}

export default ReviewTile
