import { UUID, Error, Slug, Timestamp, Username, ResourceMeta } from "types/common"
import { UserID } from "types/user"
import { EventID } from "types/events"

export type ReviewID = UUID

export interface ReviewBase {
  id: UUID
  username: Username
  image?: string
  rating: number
  headline?: string
  comment: string
  date: Timestamp
  imgix_src?: string
  event_name?: string
  event_slug?: Slug
  created_at: Timestamp
  updated_at: Timestamp
  is_social?: boolean
}
export interface ReviewTruncated extends ReviewBase {
  owner: UserID
  event: EventID
}
export interface ReviewFull extends ReviewBase {
  owner: UserID
  event: EventID
  verified?: string
}
export interface Review extends Partial<ReviewFull> {}
export interface ReviewCreate {
  rating: number
  headline?: string | null
  comment?: string | null
}
export interface ReviewUpdate {
  rating?: number
  headline?: string
  comment?: string
}
export interface AggregateRatings {
  average_rating: number
  total_ratings: number
  one_stars: number
  two_stars: number
  three_stars: number
  four_stars: number
  five_stars: number
}

export interface ReviewsState {
  isCreating: boolean
  isFetching: boolean
  isUpdating: boolean
  error: Error
  data: Record<UUID, Review>
  reviewIdToMetaLookup: Record<UUID, ResourceMeta>
}

export enum ReviewsActionType {
  SUBMIT_REVIEW = "@@reviews/SUBMIT_REVIEW",
  SUBMIT_REVIEW_SUCCESS = "@@reviews/SUBMIT_REVIEW_SUCCESS",
  SUBMIT_REVIEW_FAILURE = "@@reviews/SUBMIT_REVIEW_FAILURE",

  FETCH_REVIEW_BY_ID = "@@reviews/FETCH_REVIEW_BY_ID",
  FETCH_REVIEW_BY_ID_SUCCESS = "@@reviews/FETCH_REVIEW_BY_ID_SUCCESS",
  FETCH_REVIEW_BY_ID_FAILURE = "@@reviews/FETCH_REVIEW_BY_ID_FAILURE",

  UPDATE_REVIEW_BY_ID = "@@reviews/UPDATE_REVIEW_BY_ID",
  UPDATE_REVIEW_BY_ID_SUCCESS = "@@reviews/UPDATE_REVIEW_BY_ID_SUCCESS",
  UPDATE_REVIEW_BY_ID_FAILURE = "@@reviews/UPDATE_REVIEW_BY_ID_FAILURE",

  SET_REVIEW_FOUR_OH_FOUR_ID = "@@reviews/SET_REVIEW_FOUR_OH_FOUR_ID",
  CLEAR_USER_REVIEW_STATE = "@@reviews/CLEAR_USER_REVIEW_DATA",
}

// GENERIC REVIEW

export interface IClearUserReviewState {
  type: ReviewsActionType.CLEAR_USER_REVIEW_STATE
}
export interface ISetReviewFourOhFourId {
  type: ReviewsActionType.SET_REVIEW_FOUR_OH_FOUR_ID
  data: UUID
}
export type GenericReviewAction = IClearUserReviewState | ISetReviewFourOhFourId

// SUBMIT REVIEW
export interface ISubmitReview {
  type: ReviewsActionType.SUBMIT_REVIEW
}
export interface ISubmitReviewSuccess {
  type: ReviewsActionType.SUBMIT_REVIEW_SUCCESS
  data: Review
}
export interface ISubmitReviewFailure {
  type: ReviewsActionType.SUBMIT_REVIEW_FAILURE
  error: Error
}
export type SubmitReviewAction = ISubmitReview | ISubmitReviewSuccess | ISubmitReviewFailure

// FETCH REVIEW
export interface IFetchReview {
  type: ReviewsActionType.FETCH_REVIEW_BY_ID
}
export interface IFetchReviewSuccess {
  type: ReviewsActionType.FETCH_REVIEW_BY_ID_SUCCESS
  data: Review
}
export interface IFetchReviewFailure {
  type: ReviewsActionType.FETCH_REVIEW_BY_ID_FAILURE
  error: Error
}
export type FetchReviewAction = IFetchReview | IFetchReviewSuccess | IFetchReviewFailure

// UPDATE REVIEW
export interface IUpdateReview {
  type: ReviewsActionType.UPDATE_REVIEW_BY_ID
}
export interface IUpdateReviewSuccess {
  type: ReviewsActionType.UPDATE_REVIEW_BY_ID_SUCCESS
  data: Review
}
export interface IUpdateReviewFailure {
  type: ReviewsActionType.UPDATE_REVIEW_BY_ID_FAILURE
  error: Error
}
export type UpdateReviewAction = IUpdateReview | IUpdateReviewSuccess | IUpdateReviewFailure

export type ReviewAction = GenericReviewAction | SubmitReviewAction | FetchReviewAction | UpdateReviewAction

export type ReviewSuccessAction = ISubmitReviewSuccess | IFetchReviewSuccess | IUpdateReviewSuccess

export type ReviewFailureAction = ISubmitReviewFailure | IFetchReviewFailure | IUpdateReviewFailure
