import { createRef } from "react"
import Link from "next/link"
import { Accordian } from "components"
import styled from "styled-components"

import { URLS } from "utils/urls"

// const FAQWrapper = styled.section`
//   width: 100vw;
//   max-width: 100vw;
//   display: flex;
//   padding: 70px 0;
//   padding-bottom: 200px;
//   background: var(--grey-background);

//   & > .grid {
//     flex: 1;
//     max-width: 64rem;
//     margin: 0 auto;
//     display: grid;

//     grid-template-columns: 1fr;

//     ${media.medium`
//       grid-template-columns: 6rem 1fr;
//       padding-left: 2rem;
//     `}

//     grid-template-rows: 200px auto;
//     grid-gap: 5px;
//   }
// `
const FAQWrapper = styled.section`
  width: 100vw;
  max-width: 100vw;
  display: flex;
  padding: 70px 0;
  padding-bottom: 200px;
  background: var(--background-color-lightest-blue);

  & > .grid {
    flex: 1;
    max-width: 64rem;
    margin: 0 auto;
    display: grid;

    grid-template-columns: 1fr;

    grid-template-rows: 200px auto;
    grid-gap: 5px;
  }
`
// const FAQTitle = styled.div`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 3rem;
//   margin-left: 1rem;

//   grid-column: 1 / -1;

//   & h2 {
//     font-size: 3.5rem;
//     font-family: var(--font-heading-bold);
//   }

//   ${media.medium`
//     margin: 0;
//   `}
// `
const FAQTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 3rem;
  margin-left: 1rem;

  grid-column: 1 / -1;

  & h2 {
    font-size: 3.5rem;
    font-family: var(--font-heading-bold);
  }
`
// const FAQCategories = styled.div`
//   padding-top: 15px;
//   flex-direction: column;
//   display: none;

//   ${media.medium`
//     display: flex;
//   `}

//   & > .categories {
//     position: -webkit-sticky;
//     position: sticky;
//     top: calc(50px + var(--navbar-height));

//     & > p {
//       margin-bottom: 20px;
//       position: relative;
//       font-size: 20px;
//       cursor: pointer;
//       z-index: 1;

//       &::before {
//         transition: all 0.2s ease;
//         content: " ";
//         display: block;
//         position: absolute;
//         left: -25px;
//         top: 5px;
//         background-image: url(/assets/images/16px_triangle-right-2.svg);

//         background-size: 16px 16px;
//         height: 16px;
//         width: 16px;
//       }
//     }
//   }
// `
const FAQCategories = styled.div`
  padding-top: 15px;
  flex-direction: column;
  display: none;

  & > .categories {
    position: -webkit-sticky;
    position: sticky;
    top: calc(50px + var(--navbar-height));

    & > p {
      margin-bottom: 20px;
      position: relative;
      font-size: 20px;
      cursor: pointer;
      z-index: 1;

      &::before {
        transition: all 0.2s ease;
        content: " ";
        display: block;
        position: absolute;
        left: -25px;
        top: 5px;
        background-image: url(/assets/images/16px_triangle-right-2.svg);

        background-size: 16px 16px;
        height: 16px;
        width: 16px;
      }
    }
  }
`
// const FAQDisplaySection = styled.div`
//   padding-left: 1rem;
//   padding-right: 1rem;
//   display: flex;
//   flex-direction: column;

//   ${media.medium`
//     padding-left: 4rem;
//   `}
// `
const FAQDisplaySection = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
`
const FAQAccordianSection = styled.div`
  margin-bottom: 3rem;

  & > h3 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  & p {
    font-weight: 400;
  }
`

const BasicsAboutContent = () => (
  <>
    <p>
      Velup's primary goal is to quantify, organize, and curate the best digital and in-person professional development
      resources available in the education field. By providing a platform and community to highlight excellent PD, Velup
      hopes to be a home for sourcing the best professional development around.
    </p>
  </>
)

const BasicHowItWorksContent = () => (
  <>
    <p>
      If you're an educator and looking to find a professional development event or provider, search our library
      {` `}
      <Link href={URLS.search}>here</Link>. You can also leave a review for any event you've already attended so that
      other educators can find it more easily. For professional development provider, Velup offers an all-in-one
      platform where you can organize your team, register users for events you're hosting, and get invaluable feedback
      from educators that attend your events. If you plan on charging for your events, the cost will show up on your
      event page and users can pay directly through the platform thanks to our Stripe integration.
    </p>
  </>
)

const AccountSignUpContent = () => (
  <>
    <p>
      New users can sign up <Link href={URLS.register}>here</Link> or login <Link href={URLS.login}>here</Link> if you
      already have an account.
    </p>
  </>
)

const AccountNeedAnAccount = () => (
  <>
    <p>
      Anyone can search the event and provider library without signing up. Users who want to register for events or
      leave reviews must have an account with a confirmed email address, however.
    </p>
  </>
)

const AccountConfirmEmailAddress = () => (
  <>
    <p>
      You should receive an email as soon as you sign up for Velup. If you didn't get one or you can't find it, head to
      your <Link href={URLS.profiles}>profile</Link> page and request a new confirmation email.
    </p>
  </>
)

const AccountForgotPassword = () => (
  <>
    <p>
      Don't worry. You can reset your password <Link href={URLS.password_reset}>here</Link>.
    </p>
  </>
)

const PaymentsHandling = () => (
  <>
    <p>
      All payments are processed through Stripe so we can guarantee that any transactions are in good hands. Stripe is
      one of the most trusted online payments services in existence. Providers who register with Stripe will be served a
      dashboard to track and monitor registrations as they come in.
    </p>
  </>
)

const PaymentsVelupCostMoney = () => (
  <>
    <p>
      Velup is completely free to for educators, providers, and team members. Providers may choose to charge for their
      events and users can pay by credit card or through Google pay.
    </p>
  </>
)

const PrivacyData = () => (
  <>
    <p>
      Never. Plain and simple. Velup will never sell and never share your data with anyone unless you explicitly create
      public data to be shared with the community. This includes reviews, comments, and new events created through the
      site. All other data will remain private.
    </p>
  </>
)

const PrivacyDelete = () => (
  <>
    <p>
      Of course. Suspend or delete your account at any time. All suspended accounts will retain their data until
      explicitly deleted. Once an account is deleted, that data is lost forever.
    </p>
  </>
)

const sections = {
  Basics: [
    {
      title: `What's Velup all about?`,
      contents: <BasicsAboutContent />,
      contentHeight: 150,
    },
    {
      title: `How does it work?`,
      contents: <BasicHowItWorksContent />,
      contentHeight: 220,
    },
  ],
  Account: [
    {
      title: "How do I sign up?",
      contents: <AccountSignUpContent />,
      contentHeight: 80,
    },
    {
      title: `Do I need to make an account to see events?`,
      contents: <AccountNeedAnAccount />,
      contentHeight: 80,
    },
    {
      title: `How do I confirm my account email address?`,
      contents: <AccountConfirmEmailAddress />,
      contentHeight: 80,
    },
    {
      title: `I forgot my password. What do I do?`,
      contents: <AccountForgotPassword />,
      contentHeight: 80,
    },
  ],
  Payments: [
    {
      title: `How does Velup handle payments?`,
      contents: <PaymentsHandling />,
      contentHeight: 120,
    },
    {
      title: "Does Velup cost money?",
      contents: <PaymentsVelupCostMoney />,
      contentHeight: 100,
    },
  ],
  Privacy: [
    {
      title: `Does Velup share or sell my data?`,
      contents: <PrivacyData />,
      contentHeight: 100,
    },
    {
      title: `Can I delete my account?`,
      contents: <PrivacyDelete />,
      contentHeight: 80,
    },
  ],
}

export default function FAQ() {
  const refs = Object.keys(sections).reduce((acc, sectionTitle) => {
    acc[sectionTitle] = createRef()
    return acc
  }, {})

  const onScrollToSection = (ref) => {
    if (!ref?.current) return

    ref.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <FAQWrapper id="faq">
      <div className="grid">
        <FAQTitle>
          <h2>Frequently Asked Questions</h2>
        </FAQTitle>
        <FAQCategories>
          <div className="categories">
            {Object.keys(sections).map((sectionTitle) => (
              <p key={sectionTitle} role="presentation" onClick={() => onScrollToSection(refs[sectionTitle])}>
                {sectionTitle}
              </p>
            ))}
          </div>
        </FAQCategories>
        <FAQDisplaySection>
          {Object.keys(sections).map((sectionTitle) => (
            <FAQAccordianSection key={sectionTitle} ref={refs[sectionTitle]}>
              <h3>{sectionTitle}</h3>
              <Accordian items={sections[sectionTitle]} />
            </FAQAccordianSection>
          ))}
        </FAQDisplaySection>
      </div>
    </FAQWrapper>
  )
}
