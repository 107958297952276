// import { useTypedSelector } from "models/utils"
import { useDispatch, shallowEqual } from "react-redux"
import {
  Button,
  // IconEditColored
} from "components"
import styled from "styled-components"
//
// import { Username } from "models/utils"
// import { Profile, Actions as profileActions, selectIsFollowingCurrentUser } from "models/profiles"

import { Profile } from "types/user"

import { URLS } from "utils/urls"

const FollowButton = styled(Button)``

const EditProfileButtonSpan = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IUserFollowButtons {
  profile: Profile
}
export default function UserFollowButtons({ profile, ...props }: IUserFollowButtons) {
  // const dispatch = useDispatch()
  // const user = useTypedSelector((state) => state.auth.user, shallowEqual)
  // const isLoading = useTypedSelector((state) => state.profiles.isLoading)
  // const isFollowing = useTypedSelector((state) => selectIsFollowingCurrentUser(state))
  // const followUser = (username: Username) => {
  //   dispatch(profileActions.followUser({ username }))
  // }
  // const unfollowUser = (username: Username) => {
  //   dispatch(profileActions.unfollowUser({ username }))
  // }

  if (!profile?.id) return null

  const renderFollowButtons = () => {
    // if (profile.id === user?.id)
    //   return (
    //     <Button
    //       buttonType="primary"
    //       // to={URLS.profiles_edit}
    //     >
    //       <EditProfileButtonSpan className="edit-profile-button">
    //         Edit Profile &nbsp;
    //         {/* <IconEditColored fillSquare={"#FFF"} fillEraser={"#ABABB7"} /> */}
    //       </EditProfileButtonSpan>
    //     </Button>
    //   )

    // if (!isFollowing) {
    //   return (
    //     <FollowButton
    //       buttonType="primary"
    //       isDisabled={isLoading}
    //       isLoading={isLoading}
    //       onClick={() => followUser(profile.username)}
    //       {...props}
    //     >
    //       Follow {profile.username}
    //     </FollowButton>
    //   )
    // }

    return (
      <FollowButton
        buttonType="primary"
        // isDisabled={isLoading}
        // isLoading={isLoading}
        // onClick={() => unfollowUser(profile.username)}
        {...props}
      >
        Unfollow {profile.username}
      </FollowButton>
    )
  }

  return <>{renderFollowButtons()}</>
}
