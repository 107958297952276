import { useMemo } from "react"
import { Chart, NextImage } from "components"
import { useChartDimensions } from "hooks/charts/useChartDimensions"
import styled from "styled-components"
import media from "utils/mediaQueryTemplates"

import { ChartDimensions } from "types/charts"

const AbsoluteWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;

  ${media.medium`
    right: -100px;
  `}

  ${media.tablet`
    right: -50px;
  `}
  ${media.larger`
    right: 0px;
  `}
`
const LandingHeroIllustrationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  width: calc(100% + 200px);

  & svg {
    flex: 1;
  }

  ${media.small`
    right: -200px;
    width: calc(100% + 100px);
  `}

  ${media.medium`
    margin-left: auto;  
    width: 700px;
    right: -100px;
  `}

  ${media.large`
    right: -5px;
  `}
  ${media.desktop`
    width: 800px;
    right: 0px;
  `}
`
// ${media.medium`

// `}

// ${media.mediumPlus`
//   transform: translateX(50px);
// `}

// ${media.medium`
//   margin-left: auto;
//   width: 700px;
// `}
const AbsoluteContainer = styled.div`
  position: absolute;
  top: 560px;
  max-width: 400px;
  max-height: 230px;
  right: -150%;
  width: 510px;
  height: 370px;
  display: flex;

  transition: all 0.2s var(--juiced-up-ease);
  transform: translateX(230px) translateY(-60px);

  ${media.small`
    top: 500px;
    max-width: 450px;
    max-height: 280px;
    right: 50%;
  `}

  ${media.tablet`
    top: 210px;
    right: 50px;
    width: 510px;
    height: 370px;    
    transform: translateX(50px);
    max-width: 100%;
    max-height: 100%;
  `}

  ${media.desktop`
    top: 210px;
    right: 100px;
    width: 510px;
    height: 370px;
    transform: translateX(0px);
  `}
`

const BgVizContainer = styled.div`
  & .small-secondary {
    fill: var(--color-secondary);
  }

  & .small-accent {
    fill: var(--color-accent);
  }

  & .large-primary {
    fill: var(--color-primary);
    fill: var(--color-primary-a80);

    transform: translateX(50px) translatey(-100px);
  }

  ${media.tablet`
    & .small-secondary {
      fill: var(--color-secondary);
    }

    & .small-accent {
      fill: var(--color-accent);
    }

    & .large-primary {
      fill: var(--color-primary-a80);
    }    
  `}
`
const SkewedContainer = styled.div`
  position: relative;
  flex: 1;
  transform: skew(0.02turn, -6deg);

  ${media.tablet`
    transform: skew(0.02turn, -6deg);
  `}
`
const StyledAbsoluteSkewedImage = styled(NextImage)`
  position: absolute;
  border-radius: var(--border-radius-md);
  clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
  clip-path: polygon(99% 7%, 100% 8%, 100% 92%, 99% 93%, 0 100%, 0 0);

  ${media.tablet`
    clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
    clip-path: polygon(99% 7%, 100% 8%, 100% 92%, 99% 93%, 0 100%, 0 0);
  `}
`
const StyledCircle = styled.circle``

const AbsoluteSkewedImage = () => (
  <AbsoluteContainer>
    <SkewedContainer>
      <StyledAbsoluteSkewedImage pointer={false} src={"you-x-ventures-unsplash.jpg"} />
    </SkewedContainer>
  </AbsoluteContainer>
)

const makeCircles = (dimensions: ChartDimensions) => {
  const offset = 110
  const largeCircleSize = 420
  const smallCircleSizes = largeCircleSize * 0.08

  const circles = [
    {
      // big purple circle
      cx: dimensions.boundedWidth - offset,
      cy: offset,
      r: largeCircleSize,
      fill: "var(--color-primary-a80)",
      id: 1,
      className: `large-primary`,
    },
    {
      // small pink circle on left
      cx: 0,
      cy: dimensions.boundedHeight * 0.5,
      r: smallCircleSizes,
      fill: "var(--color-accent)",
      id: 2,
      className: `small-accent`,
    },
    {
      // small turqoise circle at bottom
      cx: dimensions.boundedWidth * 0.5,
      cy: dimensions.boundedHeight - 20,
      r: smallCircleSizes,
      fill: "var(--color-secondary)",
      id: 3,
      className: `small-secondary`,
    },
  ]

  return circles
}

const defaultDimensions = { height: 700 }

export default function LandingHeroIllustration() {
  const [ref, dimensions] = useChartDimensions(defaultDimensions)

  const circles = useMemo(() => {
    return makeCircles(dimensions)
  }, [dimensions])

  return (
    <AbsoluteWrapper>
      <LandingHeroIllustrationWrapper ref={ref}>
        <AbsoluteSkewedImage />

        <BgVizContainer>
          <Chart dimensions={dimensions}>
            {circles.map((c) => (
              <StyledCircle className={c.className} key={c.id} cx={c.cx} cy={c.cy} r={c.r} />
            ))}
          </Chart>
        </BgVizContainer>
      </LandingHeroIllustrationWrapper>
    </AbsoluteWrapper>
  )
}
