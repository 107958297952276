import { Button, Card, MarkdownDisplay } from "components"
import { ProviderCoverImage } from "views"
import styled from "styled-components"

import { NormalizedProvider, NormalizedProviderWithEvents } from "types/providers"

const ProviderCardWrapper = styled((props) => <Card elevation="high" {...props} />)`
  display: flex;
  flex-direction: column;
`
const ProviderCardImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const ProviderCardBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 1rem;
`
const BodyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const Desc = styled.div`
  width: 70%;
  margin: 0.5rem 0;
`

interface IProviderCard {
  provider: NormalizedProvider | NormalizedProviderWithEvents
  className?: string
}
export default function ProviderCard({ provider, className = "" }: IProviderCard) {
  return (
    <ProviderCardWrapper className={className}>
      <ProviderCardImage className="provider-card-image">
        <ProviderCoverImage provider={provider} />
      </ProviderCardImage>

      <ProviderCardBody>
        <BodyTitle>
          <h2>{provider.name}</h2>
          <Button buttonType="primary">Register</Button>
        </BodyTitle>
        <Desc>
          <MarkdownDisplay source={provider.description} />
        </Desc>
      </ProviderCardBody>
    </ProviderCardWrapper>
  )
}
