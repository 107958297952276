import { useMemo } from "react"
import { Dispatch } from "redux"
import { ThunkAction } from "redux-thunk"
import { useTypedSelector } from "models/redux"
import { useDispatch, shallowEqual } from "react-redux"
import { Actions as uiActions } from "models/ui"
import { Toast, ToastID, UiToastAction, UiToastThunkAction } from "types/ui"

// const removeUiToast = (dispatch: Dispatch<UiToastAction>, toastId: ToastID) => {
//   dispatch({ type: ToastActionType.REMOVE_UI_TOAST, toastId })
// }
// const addUiToast = (dispatch: Dispatch<UiToastAction>, toast: Toast) => {
//   const toastId = useUniqueId("toast")
//   setTimeout(() => removeUiToast(dispatch, toastId), config.uiToastDuration || 15000)
//   dispatch({ type: ToastActionType.ADD_UI_TOAST, toast: { ...toast, id: toastId } })
// }

export const useToasts = (): [
  Toast[],
  {
    addUiToast: (toast: Toast) => UiToastThunkAction
    removeUiToast: (toastId: ToastID) => UiToastAction
  }
] => {
  const dispatch = useDispatch()
  const toastList = useTypedSelector((state) => state.ui.toastList, shallowEqual)

  const handlers = useMemo(
    () => ({
      addUiToast: (toast: Toast) => dispatch(uiActions.addUiToast({ toast })),
      removeUiToast: (toastId: ToastID) => dispatch(uiActions.removeUiToast({ toastId })),
    }),
    []
  )

  return [toastList, handlers]
}

// import { useReducer, useMemo, createContext, Dispatch } from "react"
// import { useUniqueId } from "hooks/common/useUniqueId"
// import { Toast, ToastID } from "types/ui"
// import config from "config"

// export enum ToastActionType {
//   ADD_UI_TOAST = "@@ui/ADD_UI_TOAST",
//   REMOVE_UI_TOAST = "@@ui/REMOVE_UI_TOAST",
// }
// export interface IAddUiToast {
//   type: ToastActionType.ADD_UI_TOAST
//   toast: Toast
// }
// export interface IRemoveUiToast {
//   type: ToastActionType.REMOVE_UI_TOAST
//   toastId: ToastID
// }
// export type UiToastAction = IAddUiToast | IRemoveUiToast

// interface IToastState {
//   toastList: Toast[]
// }
// export const toastInitialState: IToastState = {
//   toastList: [],
// }

// export const toastReducer = (state = toastInitialState, action: UiToastAction) => {
//   switch (action.type) {
//     case ToastActionType.ADD_UI_TOAST:
//       return {
//         ...state,
//         toastList: [...state.toastList, action.toast],
//       }
//     case ToastActionType.REMOVE_UI_TOAST:
//       return {
//         ...state,
//         toastList: state.toastList.filter((toast) => toast.id !== action.toastId),
//       }
//     default:
//       return state
//   }
// }

// const removeUiToast = (dispatch: Dispatch<UiToastAction>, toastId: ToastID) => {
//   dispatch({ type: ToastActionType.REMOVE_UI_TOAST, toastId })
// }
// const addUiToast = (dispatch: Dispatch<UiToastAction>, toast: Toast) => {
//   const toastId = useUniqueId("toast")
//   setTimeout(() => removeUiToast(dispatch, toastId), config.uiToastDuration || 15000)
//   dispatch({ type: ToastActionType.ADD_UI_TOAST, toast: { ...toast, id: toastId } })
// }

// export const useToasts = (): [
//   IToastState,
//   { addUiToast: (toast: Toast) => void; removeUiToast: (toastId: ToastID) => void }
// ] => {
//   const [toastState, dispatch] = useReducer(toastReducer, toastInitialState)

// const handlers = useMemo(
//   () => ({
//     addUiToast: (toast: Toast) => addUiToast(dispatch, toast),
//     removeUiToast: (toastId: ToastID) => removeUiToast(dispatch, toastId),
//   }),
//   []
// )

//   return [toastState, handlers]
// }

// // const ToastContext = createContext()
