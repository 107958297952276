import { useState, useRef } from "react"
import {
  Button,
  Card,
  // IconQuestionnaireCheck,
  // Input,
  // Spinner,
  IconPassword,
  ReactHookFormInput,
  // ReactHookFormTextArea,
  Spacer,
  // Spinner,
} from "components"
import { useForm } from "react-hook-form"
import { useToasts } from "hooks/ui/useToasts"

import styled from "styled-components"

import { sleep } from "utils/async"

const AccountPasswordResetFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  & form {
    display: flex;
    flex-direction: column;
    height: 100%;

    & button {
      margin-top: auto;
    }
  }
`
const StyledH4 = styled.h4`
  position: relative;
  z-index: var(--z-index-small);
  margin-top: 1rem;
  font-size: 28px;
`

interface AccountPasswordResetFormState {
  oldPassword: string
  newPassword: string
  newPasswordConfirm: string
}

export default function AccountPasswordResetForm() {
  const [isLoading, setIsLoading] = useState(false)
  const [, handlers] = useToasts()
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    watch,
    setError,
    formState: { errors },
  } = useForm<AccountPasswordResetFormState>({
    mode: "onTouched",
  })

  const password = useRef({})
  password.current = watch("newPassword", "")

  const handleOnSubmit = async (e) => {
    setIsLoading(true)
    await sleep(500)

    const values = getValues()
    // console.log(data)

    if (values?.newPassword !== values?.newPasswordConfirm) {
      setError("newPasswordConfirm", {
        type: "manual",
        message: "Passwords must match",
      })
      setIsLoading(false)
      return
    }

    // const res = await handleOnUserSignup(data)
    // console.log({ res })
    // if (res?.error) {
    //   await sleep(1000)
    //   const toast = {
    //     title: "Registration unsuccessful.",
    //     type: ToastType.DANGER,
    //     contents: res.error,
    //   }
    //   toastHandlers.addUiToast(toast)
    //   reset({ password: "", passwordConfirm: "", email: values.email, username: values.username })
    // }

    console.log("Changed password FAKE")

    setIsLoading(false)
  }

  const handleOnError = async (errors) => {
    console.log({ errors })
    //
    const values = getValues()
    await sleep(500)

    if (values?.newPassword !== values?.newPasswordConfirm) {
      setError("newPasswordConfirm", {
        type: "manual",
        message: "Passwords must match",
      })
      setIsLoading(false)
      return
    }

    reset({ oldPassword: "", newPassword: "", newPasswordConfirm: "" })
  }

  return (
    <AccountPasswordResetFormWrapper>
      <h1>Reset your password.</h1>
      {/* <StyledH4>Reset your password.</StyledH4> */}
      <form onSubmit={handleSubmit(handleOnSubmit, handleOnError)}>
        <Spacer size="lg" />
        <ReactHookFormInput
          fullWidth
          name="oldPassword"
          type="password"
          label="Old Password"
          iconLeft={<IconPassword stroke={"var(--font-color-subdued)"} />}
          register={{ ...register("oldPassword", { required: "Enter your current password" }) }}
          providedError={errors?.oldPassword?.message}
        />
        <Spacer size="lg" />
        <ReactHookFormInput
          fullWidth
          name="newPassword"
          type="password"
          label="New Password"
          iconLeft={<IconPassword stroke={"var(--font-color-subdued)"} />}
          register={{ ...register("newPassword", { required: "Enter a new password" }) }}
          providedError={errors?.newPassword?.message}
        />
        <Spacer size="lg" />
        <ReactHookFormInput
          fullWidth
          name="newPasswordConfirm"
          type="password"
          label="Confirm New Password"
          iconLeft={<IconPassword stroke={"var(--font-color-subdued)"} />}
          register={{
            ...register("newPasswordConfirm", {
              required: "Confirm your new password",
              validate: { same: (v) => v === password?.current || "Password's don't match." },
            }),
          }}
          providedError={errors?.newPasswordConfirm?.message}
        />
        <Spacer size="xl" />
        <Button isLoading={isLoading} type="submit">
          Save
        </Button>
      </form>
    </AccountPasswordResetFormWrapper>
  )
}
