import { Fragment } from "react"
import { Spacer } from "components"
import { ProviderPreviewCard } from "views"
import styled from "styled-components"

const ProvidersOthersYouMightLikeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export default function ProvidersOthersYouMightLike({ otherProviders }) {
  return (
    <ProvidersOthersYouMightLikeWrapper>
      <h3>View Other Providers:</h3>
      <Spacer size="lg" />
      {otherProviders.map((provider) => (
        <Fragment key={provider.id}>
          <ProviderPreviewCard provider={provider} />
          <Spacer size="lg" />
        </Fragment>
      ))}
    </ProvidersOthersYouMightLikeWrapper>
  )
}
