import { useState } from "react"
import Link from "next/link"
import { signin } from "next-auth/client"
import styled from "styled-components"
import {
  Button,
  // Checkbox,
  IconEmail,
  IconColoredTwitter as IconTwitter,
  IconColoredLinkedin as IconLinkedin,
  IconPassword,
  // IconUserCircle,
  ReactHookFormInput,
  Spacer,
} from "components"
import { useForm } from "react-hook-form"
import { useToasts } from "hooks/ui/useToasts"
import { isValidEmail } from "utils/validation"
import { noop } from "utils/misc"
import { sleep } from "utils/async"

import { Email } from "types/common"
import { LoginCredentials } from "types/auth"
import { ToastType } from "types/ui"

const LoginFormWrapper = styled.div``

interface LoginFormState {
  email: Email
  password: string
}

const FormHeader = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: var(--font-color-subdued-header);
  margin-bottom: 1rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    height: 32px;
    width: 32px;
  }

  & small {
    color: var(--color-primary);
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
  }
`
const StyledTextInput = styled((props) => <ReactHookFormInput {...props} />)`
  margin-top: 0.5rem;
`
const LoginButton = styled((props) => <Button {...props} />)`
  width: 100%;
`
const OAuthButton = styled((props) => <Button {...props} />)`
  width: 100%;

  & span {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & svg {
      height: 26px;
      width: 26px;

      & path {
        // fill: var(--color-primary);
        // fill: var(--color-secondary);
      }
    }

    & span {
      flex: 1;
      display: flex;
      justify-content: center;
      padding-right: 10px;
    }
  }
`
const ForgotPasswordLink = styled.span`
  font-size: 14px;
  color: var(--font-color-subdued-header);
`

interface ILoginForm {
  onClose?: () => void
  handleOnUserLogin: (creds: LoginCredentials) => Promise<any>
}
const LoginForm: React.FC<ILoginForm> = ({ onClose = noop, handleOnUserLogin }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [, handlers] = useToasts()
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm<LoginFormState>({
    mode: "onBlur",
  })

  // console.log(errors)

  const handleOnLogin = async (data: LoginCredentials) => {
    // console.log(data)
    setIsLoading(true)

    const hasErrors = errors?.email?.message || errors?.password?.message

    if (hasErrors) {
      const toast = {
        title: "Form error.",
        type: ToastType.DANGER,
        contents: `Please correct the errors in the form before logging in..`,
      }
      handlers.addUiToast(toast)
      setIsLoading(false)
      return
    }

    // TODO:
    // do actual login here

    const res = await handleOnUserLogin(data)
    // console.log({ res })
    if (res.error) {
      // console.log("ERROR! Would send out an error toast in this case")
      await sleep(1000)
      const toast = {
        title: "Authentication unsuccessful",
        type: ToastType.DANGER,
        contents: `That email and password combination were not found in our system.`,
      }
      // console.log({ toast })
      handlers.addUiToast(toast)
      const values = getValues()
      reset({ password: "", email: values.email })
    }

    setIsLoading(false)
  }

  const handleOnError = async (errors, e) => {
    setIsLoading(true)
    // console.log(errors, e)
    const hasErrors = errors?.email?.message || errors?.password?.message

    // await sleep(1000)

    if (hasErrors) {
      const toast = {
        title: "Form error",
        type: ToastType.DANGER,
        contents: `Please correct the errors in the form before logging in.`,
      }
      handlers.addUiToast(toast)
    }

    const values = getValues()
    reset({ password: "", email: values.email }, { keepErrors: true })
    setIsLoading(false)
  }

  return (
    <LoginFormWrapper>
      <FormHeader>
        <span>Welcome back!</span>
        {/* <IconClose onClick={onClose} />
         */}
        <small tabIndex={0} role="button" onClick={onClose}>
          Need an account?
        </small>
      </FormHeader>

      <form onSubmit={handleSubmit(handleOnLogin, handleOnError)}>
        <StyledTextInput
          label="Email"
          name="email"
          type="email"
          placeholder="user@email.io"
          fullWidth
          iconLeft={<IconEmail strokeWidth={2} stroke={"var(--font-color-subdued)"} />}
          register={{
            ...register("email", {
              required: "Email is required",
              validate: (value) => isValidEmail(value) || "Please enter a valid email",
            }),
          }}
          providedError={errors?.email?.message}
          // register={register({
          //   required: "Email is required",
          //   validate: (value) => isValidEmail(value) || "Please enter a valid email",
          // })}
          // providedError={
          //   errors?.email?.type === "validate"
          //     ? "Please enter a valid email"
          //     : errors?.email?.type === "required"
          //     ? "Email is required"
          //     : null
          // }
        />
        <StyledTextInput
          label="Password"
          name="password"
          fullWidth
          iconLeft={<IconPassword strokeWidth={2} stroke={"var(--font-color-subdued)"} />}
          placeholder="Password"
          type="password"
          register={{
            ...register("password", {
              required: "Password is required",
            }),
          }}
          // register={register({
          //   required: "Password is required",
          // })}
          providedError={errors?.password?.message}
        />

        <Spacer size="xl" />

        <ForgotPasswordLink>
          Forgot your <Link href="/forgot-password/">password?</Link>
        </ForgotPasswordLink>

        <Spacer size="sm" />

        <LoginButton isLoading={isLoading} buttonType="primary" type="submit">
          Sign in
        </LoginButton>
      </form>

      <Spacer size="xl" />

      <svg width="100%" height="20px">
        <line x1={0} x2={"45%"} y1={10} y2={10} stroke="var(--font-color-subdued)" strokeWidth={1} />
        <text x={"50%"} y={15} fill="var(--font-color-subdued-header)">
          or
        </text>
        <line x1={"57%"} x2={"100%"} y1={10} y2={10} stroke="var(--font-color-subdued)" strokeWidth={1} />
      </svg>

      <Spacer size="lg" />

      <OAuthButton buttonType="secondary" type="button" onClick={() => signin("linkedin")}>
        <span>
          <IconLinkedin /> <span>Continue with Linkedin</span>
        </span>
      </OAuthButton>

      <Spacer size="lg" />

      <OAuthButton buttonType="secondary" type="button" onClick={() => signin("twitter")}>
        <span>
          <IconTwitter /> <span>Continue with Twitter</span>
        </span>
      </OAuthButton>

      <Spacer size="lg" />
    </LoginFormWrapper>
  )
}

export default LoginForm
