import styled from "styled-components"

const SearchResultsWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 1fr);
`

export default function SearchResults() {
  return (
    <SearchResultsWrapper>
      <>SearchResults</>
    </SearchResultsWrapper>
  )
}
