import { useState, Fragment } from "react"
import { useRouter } from "next/router"
import { Button, Card, Chip, Dropdown, IconMapPositionPin, IconSearch, Input, Spacer } from "components"
import { eventTypeDropdownItems, landingChipSearchShortcuts } from "constants/ui"
import styled from "styled-components"
import media from "utils/mediaQueryTemplates"

import { DropdownItem, ChipType } from "types/ui"

const LandingSearchBarWrapper = styled.div`
  flex-direction: column;
  position: absolute;
  top: 700px;

  width: calc(100% - 20px);
  margin: 0 10px;

  &.large {
    display: none;
  }
  &.small {
    display: flex;
  }

  ${media.medium`
    width: calc(100% - calc(var(--page-side-padding)) * 2);
    margin: 0 var(--page-side-padding);  
  `}

  ${media.mediumPlus`
    &.large {
      display: flex;
    }
    &.small {
      display: none;
    }  
  `}

  ${media.tablet`
    top: 670px;  
    width: calc(100% - calc(var(--page-side-padding) * 3.5));
    margin: 0 calc(var(--page-side-padding) * 3) 0 calc(var(--page-side-padding) * 2.5);
  `}
  ${media.large`
    top: 600px;
  `}

  ${media.larger`
    top: 600px;  
    width: calc(100% - calc(var(--page-side-padding) * 6.5));
    margin: 0 calc(var(--page-side-padding) * 3) 0 calc(var(--page-side-padding) * 2.5);
  `}
`
const LandingSearchBarCard = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: row;
  flex: 1;
  border-right: none;
  border-bottom: none;

  & svg {
    height: 24px;
    width: 24px;
  }
`
const ChipShortcutRow = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  display: none;

  ${media.tablet`
    display: flex;
  `}
`
const SubmitButton = styled((props) => <Button {...props} />)`
  border-radius: 0px 4px 4px 0px;
`
const StyledInput = styled((props) => <Input {...props} />)`
  width: 300px;
  min-width: 180px;

  & .styled__input-container {
    border: none;
    border-right: solid 1px var(--background-color-very-light-blue);
    border-radius: 0;
    flex: 1;
  }

  & .styled__input {
    font-size: 17px;
  }

  ${media.mobileOnly`
    & .styled__input-container {
      min-width: 180px;      
      max-width: 100%;
    }    
  `}

  ${media.small`
    min-width: 300px;
    & .styled__input-container {
      min-width: 300px;
      width: 100%;
    }  
  `}
`
const StyledLargeInput = styled(StyledInput)`
  flex: 1;

  & .styled__input-container {
    border-radius: 4px 0 0 4px;
  }
`
const StyledDropdown = styled((props) => <Dropdown {...props} />)`
  border-color: transparent;
  width: 250px;

  & .dropdown-card {
    border-color: transparent;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    height: 100%;
    border-radius: 0;
  }
`
const DropdownItemLabel = styled.span<{ $large?: boolean }>`
  font-size: ${(props) => (props.$large ? "18px" : "15px")};
  ${(props) => (props.$large ? "color: var(--font-color-subdued-header);" : "")}
`

const customRenderItemLabel = (item: DropdownItem, ctx?: string) => {
  if (ctx === "dropdown-label") return <DropdownItemLabel $large={true}>{item.label}</DropdownItemLabel>
  return <DropdownItemLabel>{item.label}</DropdownItemLabel>
}

export function LandingSearchBarLarge() {
  const router = useRouter()
  const [searchInput, setSearchInput] = useState("")
  const [locationInput, setLocationInput] = useState("Online")
  const [eventType, setEventType] = useState(eventTypeDropdownItems[0])

  return (
    <LandingSearchBarWrapper className="large">
      <LandingSearchBarCard noPadding elevation="high">
        <StyledLargeInput
          name="searchInput"
          value={searchInput}
          label=""
          noLabel
          noHelperText
          iconLeft={<IconSearch stroke={"var(--font-color-subdued-header)"} />}
          placeholder="Search events..."
          onChange={(v: string) => setSearchInput(v)}
          fullWidth
        />
        <StyledInput
          name="locationInput"
          value={locationInput}
          label=""
          noLabel
          noHelperText
          iconLeft={<IconMapPositionPin stroke={"var(--font-color-subdued-header)"} />}
          placeholder="Enter a location"
          onChange={(v: string) => setLocationInput(v)}
        />
        <StyledDropdown
          items={eventTypeDropdownItems}
          selectedItem={eventType}
          onSelect={(item: DropdownItem) => setEventType(item)}
          // label="Any type"
          renderItemLabel={customRenderItemLabel}
        />
        <SubmitButton buttonType="primary" buttonSize="xl" onClick={() => router.push(`/search/`)}>
          Search
        </SubmitButton>
      </LandingSearchBarCard>
      <ChipShortcutRow>
        {landingChipSearchShortcuts.map((chip) => (
          <Fragment key={chip.path}>
            <Chip text={chip.label} chipType={ChipType.PRIMARY} chipSize="xl" onClick={() => router.push(chip.path)} />
            <Spacer size="lg" />
          </Fragment>
        ))}
      </ChipShortcutRow>
    </LandingSearchBarWrapper>
  )
}

export function LandingSearchBarSmall() {
  const router = useRouter()
  const [searchInput, setSearchInput] = useState("")
  // const [locationInput, setLocationInput] = useState("Online")
  // const [eventType, setEventType] = useState(eventTypeDropdownItems[0])

  return (
    <LandingSearchBarWrapper className="small">
      <LandingSearchBarCard noPadding elevation="high">
        <StyledLargeInput
          name="searchInput"
          value={searchInput}
          label=""
          noLabel
          noHelperText
          iconLeft={<IconSearch stroke={"var(--font-color-subdued-header)"} />}
          placeholder="Search events..."
          onChange={(v: string) => setSearchInput(v)}
          fullWidth
        />
        <SubmitButton buttonType="primary" buttonSize="xl" onClick={() => router.push(`/search/`)}>
          Search
        </SubmitButton>
      </LandingSearchBarCard>
    </LandingSearchBarWrapper>
  )
}

export default function LandingSearchBar({ ...props }) {
  return (
    <>
      <LandingSearchBarLarge {...props} />
      <LandingSearchBarSmall {...props} />
    </>
  )
}
