import {
  Avatar,
  Card,
  IconColoredTwitter,
  IconColoredLinkedin,
  MarkdownDisplay,
  NextImage,
  IconLinkedin,
  Spacer,
} from "components"
import styled from "styled-components"
import { formatDateShort } from "utils/dates"
import media from "utils/mediaQueryTemplates"

const LandingReviewsPreviewWrapper = styled.section`
  width: 100%;
  max-width: 100vw;
  background: var(--background-color-lightest-blue);
  min-height: 500px;
  position: relative;
  padding: 0 1rem;

  ${media.small`
    padding: 0 4rem;
  `}

  ${media.tablet`
    padding: 0;
    height: 100vh;
    max-height: 700px;  
  `}
`
const LandingReviewsPreviewContent = styled.div`
  max-width: 1100px;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
`

const LandingReviewsPreviewTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 650px;
  margin: 2rem auto;
`
const LandingReviewsPreviewTitle = styled.h1`
  font-family: var(--font-primary);
  margin: 1rem auto;
`
const LandingReviewsPreviewSubtitle = styled.p`
  font-family: var(--font-primary);
  line-height: 2rem;
`

const LandingReviewsPreviewGallery = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  // grid-template-columns: 1fr 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  grid-gap: 25px;

  width: 100%;

  ${media.tablet`
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 25px;    
  `}
`

const StyledReviewPreviewCard = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: column;
  padding: 0.5rem 1rem;
`
const Meta = styled.div`
  flex: 1;
  display: flex;

  & .avatar {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    & img {
      border-radius: 50%;
    }
  }
  & .name {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  & .verified {
    width: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
`
const Body = styled.div`
  flex: 1;
  font-size: 15px;
`
const StyledTwitterIcon = styled((props) => <IconColoredTwitter {...props} />)`
  height: 24px;
  width: 24px;
`
const StyledLinkedinIcon = styled((props) => <IconColoredLinkedin {...props} />)`
  height: 24px;
  width: 24px;
`
const StyledNextImage = styled((props) => <NextImage pointer={false} {...props} />)``
const ImageWrapper = styled.div`
  max-height: 150px;
  max-width: 150px;
  min-height: 50px;
  min-width: 50px;
  position: relative;
`

const ReviewPreviewCard = ({ review }) => {
  const avatarImage = (
    <ImageWrapper>
      <StyledNextImage src={review.owner.image} />
    </ImageWrapper>
  )

  return (
    <StyledReviewPreviewCard elevation="med">
      <Meta>
        <div className="avatar">
          <Avatar avatarSize="sm" name={review.owner.name} image={avatarImage} />
        </div>
        <div className="name">
          <p>{review.owner.name}</p>
          <small>{formatDateShort(review.date)}</small>
        </div>
        <div className="verified">
          {review.owner.verified === "twitter" ? <StyledTwitterIcon /> : <StyledLinkedinIcon />}
        </div>
      </Meta>
      <Body>
        <MarkdownDisplay source={review.comment} />
      </Body>
    </StyledReviewPreviewCard>
  )
}

const dummyReviews = [
  {
    id: 1,
    owner: {
      name: "Danny Fenjves",
      verified: "twitter",
      image: "daniel_fenjves.jpeg",
    },
    date: new Date(),
    comment: `Learning to learn is the most important skill to have. Once you have that, the content is almost incidental...`,
  },
  {
    id: 2,
    owner: {
      name: "Shadeira Nesmith",
      verified: "linkedin",
      image: "Shadeira3.jpg",
    },
    date: new Date(),
    comment: `Finding the right professional network is key to continuing to improve yourself as an educator and as a learner...`,
  },

  {
    id: 3,
    owner: {
      name: "Jeff Astor",
      verified: "linkedin",
      image: "astor_teaching.jpeg",
    },
    date: new Date(),
    comment: `Computer science teacher training at its finest. PD in the modern era should never be passive. Take stock...`,
  },
  {
    id: 4,
    owner: {
      name: "Jessie Anderson",
      verified: "twitter",
      image: "Teacher_Leader_Hero.jpg",
    },
    date: new Date(),
    comment: `Blended learning is about using technology to create rich learning experiences out in the real world...`,
  },
  {
    id: 5,
    owner: {
      name: "Michael Jordan",
      verified: "twitter",
      image: "priscilla-du-preez-unsplash.jpg",
    },
    date: new Date(),
    comment: `Once you've understood your students' needs, you're no longer concerned with what to teach, but instead...`,
  },
  {
    id: 6,
    owner: {
      name: "Kathryn Anderson",
      verified: "linkedin",
      image: "liudmila-luchkina-smile-unsplash.jpg",
    },
    date: new Date(),
    comment: `Before I began the school year, I first take a step back and determine where I want to finish...`,
  },
]

export default function LandingReviewsPreview({ reviews = dummyReviews }) {
  return (
    <LandingReviewsPreviewWrapper>
      <LandingReviewsPreviewContent>
        <LandingReviewsPreviewTitleContainer>
          <LandingReviewsPreviewTitle>10,000+ reviews and counting</LandingReviewsPreviewTitle>
          <LandingReviewsPreviewSubtitle>
            Join the plethora of educators who have offered their own unique evaluations for a multitude of professional
            development.
          </LandingReviewsPreviewSubtitle>
        </LandingReviewsPreviewTitleContainer>

        <LandingReviewsPreviewGallery>
          {reviews.map((review) => (
            <ReviewPreviewCard key={review.id} review={review} />
          ))}
        </LandingReviewsPreviewGallery>
      </LandingReviewsPreviewContent>
    </LandingReviewsPreviewWrapper>
  )
}
