import { useState, useCallback } from "react"
import { useDispatch } from "react-redux"
import { useTypedSelector } from "models/redux"
import { Actions as reviewActions } from "models/reviews"

import { Event } from "types/events"

interface IUseReviewCreateForm {
  event?: Event
}
export function useReviewCreateForm({ event }: IUseReviewCreateForm) {
  const dispatch = useDispatch()
  const [reviewText, setReviewText] = useState("")
  const [headline, setHeadline] = useState("")
  const [isFocused, setIsFocused] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [stars, setStars] = useState(null)
  const [errors, setErrors] = useState({
    stars: null,
    reviewText: null,
  })

  const isCreating = useTypedSelector((state) => state.reviews.isCreating)

  const handleOnBlur = () => {
    setIsFocused(false)
  }
  const handleOnFocus = () => {
    setIsFocused(true)
    setIsExpanded(true)
  }

  const submitReview = useCallback(
    (reviewCreate) => {
      setErrors({ stars: null, reviewText: null })
      dispatch(reviewActions.submitReview({ eventId: event.id, reviewCreate }))
    },
    [event]
  )

  return {
    submitReview,
    isCreating,
    reviewText,
    setReviewText,
    headline,
    setHeadline,
    stars,
    setStars,
    isFocused,
    isExpanded: isExpanded || Boolean(reviewText),
    handleOnBlur,
    handleOnFocus,
    errors,
    setErrors,
  }
}
