import styled from "styled-components"

const SearchInputWrapper = styled.div``

export default function SearchInput() {
  return (
    <SearchInputWrapper>
      <>SearchInput</>
    </SearchInputWrapper>
  )
}
