import { useState } from "react"
import { useRouter } from "next/router"
import { Avatar, Chip, Card } from "components"
import { EventImage, ProviderCoverImage } from "views"
import styled from "styled-components"

import { formatDateShort } from "utils/dates"
import { formatPrice, truncate } from "utils/format"

import { EventCardPreview, ProviderSimple } from "types/events"

const StyledCard = styled((props) => <Card {...props} noPadding />)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
`
const EventImageSection = styled.div<{}>`
  flex: 1;
  height: 220px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & img {
    min-height: 220px;
    max-height: 220px;
  }
`
const EventCardBody = styled.div`
  flex: 1;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 200px;
`
const EventType = styled((props) => <Chip chipType="secondary" chipSize="sm" {...props} />)`
  text-transform: uppercase;
  display: inline-block;
  padding: 0 8px;
  background: var(--color-secondary);
`
const EventTitleSection = styled.div`
  flex: 1;
  margin-top: 0.95rem;
`
const EventTitle = styled.h3`
  font-size: 22px;
  font-family: var(--font-primary);
`
const EventMeta = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 50px;
  grid-column-gap: 5px;
  align-items: center;
  margin-top: 1rem;
`
const EventMetaProviderWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & h4 {
    font-family: var(--font-primary);
    font-size: 14px;
  }
  & p {
    font-size: 13px;
    color: var(--font-color-subdued-header);
  }
`
// const StyledNextImage = styled((props) => <ImgixPicture pointer={false} {...props} />)``
const ImageWrapper = styled.div`
  max-height: 150px;
  max-width: 150px;
  height: 50px;
  width: 50px;
  min-height: 50px;
  min-width: 50px;
  position: relative;
`
const EventSmallText = styled.small`
  font-size: 14px;
`
const EventPriceSmallText = styled(EventSmallText)`
  display: flex;
  justify-content: flex-end;
`
const EventCardBodyInfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EventDate = ({ event }) => {
  return <EventSmallText>{event.ongoing ? "Ongoing" : formatDateShort(new Date(event.date))}</EventSmallText>
}

const EventRegistrations = ({ event }) => {
  return <EventSmallText>{event.registrations_open}</EventSmallText>
}
const EventMetaProvider = ({ event }) => {
  return (
    <EventMetaProviderWrapper>
      <h4>{truncate(event?.provider?.name, 40, true, "...")}</h4>
      <p>{event?.provider?.approval_status}</p>
    </EventMetaProviderWrapper>
  )
}
const EventPrice = ({ event }) => {
  return <EventPriceSmallText>{formatPrice(event.price)}</EventPriceSmallText>
}

interface IEventCard {
  event: EventCardPreview
  className?: string
}
export default function EventCard({ event, className = "" }: IEventCard) {
  const router = useRouter()
  const [hovered, setHovered] = useState(false)

  if (!event) return null

  const providerAvatarImage = (
    <ImageWrapper>
      <ProviderCoverImage provider={event.provider} />
    </ImageWrapper>
  )

  return (
    <StyledCard
      elevation={hovered ? "very-high" : "med"}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      className={className}
      onClick={() => router.push(`/events/s/${event.slug}/`)}
    >
      <EventImageSection>
        <EventImage
          event={event}
          // providerId={event.provider?.id}
          // providerId={isProviderInstance(event.provider) ? event.provider.id : event.provider as string}
        />
      </EventImageSection>
      <EventCardBody>
        <EventCardBodyInfoRow>
          <EventDate event={event} />
          {/* <EventRegistrations event={event} /> */}
          <EventType text={event.event_type}>{event.event_type}</EventType>
        </EventCardBodyInfoRow>
        <EventTitleSection>
          <EventTitle>{event.name}</EventTitle>
        </EventTitleSection>
        <EventMeta>
          {event?.provider?.name ? (
            <Avatar avatarSize="sm" name={(event.provider as ProviderSimple).name} image={providerAvatarImage} />
          ) : null}
          {event?.provider?.name ? <EventMetaProvider event={event} /> : null}

          <EventPrice event={event} />
        </EventMeta>
      </EventCardBody>
    </StyledCard>
  )
}
