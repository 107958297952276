// import { Fragment } from "react"
import styled from "styled-components"
import { Card, NextImage, Row, Spacer } from "components"

const LandingFeaturedProvidersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 0;
`
const StyledImage = styled((props) => <NextImage {...props} />)`
  // border-radius: var(--border-radius-md);
`
const StyledRow = styled((props) => <Row {...props} />)`
  width: 100%;
  padding: 0 var(--page-side-padding);
`
const StyledProviderCard = styled((props) => <Card noPadding {...props} />)`
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 150px;
  border-radius: var(--border-radius-md);
`

const ProviderCard = ({ provider }) => {
  return (
    <>
      <StyledProviderCard elevation="md">
        <StyledImage alt={provider.name} src={provider.image} pointer />
        <h4>{provider.name}</h4>
      </StyledProviderCard>

      <Spacer size="lg" />
    </>
  )
}

const featuredProviders = [
  {
    name: "PowerMyLearning",
    slug: "power-my-learning",
    image: "liudmila-luchkina-smile-unsplash.jpg",
  },
  {
    name: "CUE",
    slug: "cue",
    image: "CUE_Logo.png",
  },
  {
    name: "Upperline Code",
    slug: "upperline-code",
    image: "jo-szczepanska-unsplash.jpg",
  },
]

export default function LandingFeaturedProviders() {
  return (
    <LandingFeaturedProvidersWrapper>
      <StyledRow justifyContent="space-between">
        <span>Explore pd providers</span>
        <span> {`Find more ->`} </span>
      </StyledRow>
      <StyledRow>
        {featuredProviders.map((provider) => (
          <ProviderCard provider={provider} key={provider.slug} />
        ))}
      </StyledRow>
    </LandingFeaturedProvidersWrapper>
  )
}

// import { Fragment } from "react"
// import styled from "styled-components"
// import { NextImage, Row, Spacer } from "components"

// const LandingFeaturedProvidersWrapper = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: 3rem 0;
// `
// const StyledRow = styled((props) => <Row {...props} />)`
//   width: 100%;
//   height: 150px;
//   padding: 0 var(--page-side-padding);
//   margin-bottom: 2rem;
// `
// const Column = styled.div`
//   flex: 1;
//   position: relative;
//   display: flex;
//   flex-direction: column;

//   align-items: center;
//   justify-content: flex-start;

//   & h3 {
//     margin-top: auto;
//     margin-bottom: auto;
//     text-align: center;
//     font-family: var(--font-primary);
//   }

//   & h4 {
//     margin-top: -1.5rem;
//     font-family: var(--font-primary);
//   }
// `
// const StyledImage = styled((props) => <NextImage {...props} />)`
//   border-radius: var(--border-radius-md);
// `

// const featuredProviders = [
//   {
//     name: "PowerMyLearning",
//     slug: "power-my-learning",
//     image: "liudmila-luchkina-smile-unsplash.jpg",
//   },
//   {
//     name: "Upperline Code",
//     slug: "upperline-code",
//     image: "Shadeira3.jpg",
//   },
//   {
//     name: "CUE",
//     slug: "cue",
//     image: "CUE_Logo.png",
//   },
//   {
//     name: "BetterLesson",
//     slug: "better-lesson",
//     image: "jo-szczepanska-unsplash.jpg",
//   },
//   {
//     name: "CS For All",
//     slug: "cs-for-all",
//     image: "neonbrand-unsplash.jpg",
//   },
// ]

// export default function LandingFeaturedProviders() {
//   return (
//     <LandingFeaturedProvidersWrapper>
//       <StyledRow>
//         <Column>
//           <h3>Featured Providers</h3>
//         </Column>

//         <Spacer size="lg" />

//         {featuredProviders.map((p) => (
//           <Fragment key={p.slug}>
//             <Column>
//               <h4>{p.name}</h4>
//               <StyledImage alt={p.name} src={p.image} pointer />
//             </Column>
//             <Spacer size="md" />
//           </Fragment>
//         ))}
//       </StyledRow>
//     </LandingFeaturedProvidersWrapper>
//   )
// }
