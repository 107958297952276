import { useState, useRef } from "react"
import { connect } from "react-redux"
import { useForm } from "react-hook-form"
import { Button, Card, ReactHookFormInput, ReactHookFormTextArea, Spacer, StarsInput, SmallRedText } from "components"
import styled from "styled-components"
import { sleep } from "utils/async"

import { Actions as reviewActions, SubmitReviewParams } from "models/reviews"
import { ApiClientCallbackResponse } from "services/apiClient"

import { Event } from "types/events"

const ReviewEventFormWrapper = styled((props) => <Card {...props} elevation="med" />)`
  padding: 1rem 2rem;
  flex: 1;
`
const StyledTextInput = styled((props) => <ReactHookFormInput {...props} />)`
  margin-top: 0.5rem;
`
const StyledTextArea = styled((props) => <ReactHookFormTextArea {...props} />)`
  margin-top: 0.5rem;
`
const SubmitButton = styled((props) => <Button {...props} />)`
  width: 100%;
`
interface ReviewEventFormState {
  rating: number
  headline?: string
  comment?: string
}
interface IReviewEventForm {
  event: Event
  className?: string
  submitReview: (p: SubmitReviewParams) => Promise<ApiClientCallbackResponse>
}
function ReviewEventForm({ event, submitReview, className = "" }: IReviewEventForm) {
  const [formError, setFormError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [stars, setStars] = useState(null)
  const {
    register,
    handleSubmit,
    getValues,
    // reset,
    setError,
    formState: { errors },
  } = useForm<ReviewEventFormState>({
    mode: "onBlur",
  })

  const handleOnSubmitEventReview = async (data) => {
    // console.log({ ...data, rating: stars })
    setIsLoading(true)

    if (!stars) {
      setError("headline", {
        type: "manual",
        message: "You must provide a rating. Click on a star to continue.",
      })
      setIsLoading(false)
      return
    }

    const values = getValues()

    try {
      const res = await submitReview({
        eventId: event.id,
        reviewCreate: { rating: stars, headline: values.headline, comment: values.comment },
      })
      if (res.success) {
        setSuccess(true)
        // setState((state) => ({ ...state, success: true, error: null }))
      }
    } catch (err) {
      // console.log(err)
      setFormError(err)
    }

    await sleep(3000)

    setIsLoading(false)
  }

  const handleOnError = async (errors, e) => {
    setIsLoading(true)
    // console.log(errors, e)
    await sleep(1000)
    setIsLoading(false)
  }

  if (success) {
    return (
      <ReviewEventFormWrapper elevation={1}>
        <p>Review submitted - Thank you!</p>
        <Spacer size="md" />
        {/* <IconQuestionnaireCheck /> */}
        <p>
          Congrats! We are processing your review. This may take several days, so we appreciate your patience. It will
          appear here when approved.
        </p>
      </ReviewEventFormWrapper>
    )
  }

  return (
    <ReviewEventFormWrapper className={className}>
      <h2>Leave a Review</h2>
      {formError ? <SmallRedText>{String(formError)}</SmallRedText> : null}
      <form onSubmit={handleSubmit(handleOnSubmitEventReview, handleOnError)}>
        <StarsInput value={stars} setValue={setStars} max={5} name="event-rating" />

        <StyledTextInput
          label="headline"
          name="headline"
          type="text"
          placeholder="Your headline..."
          fullWidth
          helperText="What's important to know?"
          register={{
            ...register("headline", {
              // required: `Your review needs a headline`,
              // shouldUnregister: true,
            }),
          }}
          providedError={errors?.headline?.message}
        />
        <StyledTextArea
          label="Review"
          name="comment"
          // placeholder="your_username"
          fullWidth
          helperText="Describe your experience..."
          register={{
            ...register("comment", {
              // required: `Please leave a comment with your review`,
              // shouldUnregister: true,
            }),
          }}
        />

        <Spacer size="lg" />

        <SubmitButton isLoading={isLoading} buttonType="primary" type="submit">
          Submit
        </SubmitButton>
      </form>
    </ReviewEventFormWrapper>
  )
}

export default connect(null, {
  submitReview: reviewActions.submitReview,
})(ReviewEventForm)
