import { useRouter } from "next/router"
import { Card } from "components"
import { ProviderCoverImage } from "views"
import styled from "styled-components"

const ProviderPreviewCardWrapper = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: row;
  height: 120px;
  padding: 0.5rem 1rem;
  width: 300px;
  cursor: pointer;
`
const StyledProviderCoverImage = styled((props) => <ProviderCoverImage {...props} />)`
  flex: 1;
  width: 100px;
  height: 100px;
  max-height: 120px;
  max-width: 120px;
`
const ProviderPreviewCardBody = styled.div`
  flex: 1;
  padding: 0.5rem 1rem;
  width: 180px;
  max-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  & h3 {
    font-family: var(--font-primary);
    font-size: 18px;
  }
`

export default function ProviderPreviewCard({ provider }) {
  const router = useRouter()

  return (
    <ProviderPreviewCardWrapper onClick={() => router.push(`/providers/s/${provider.slug}/`)}>
      <StyledProviderCoverImage provider={provider} />
      <ProviderPreviewCardBody>
        <h3>{provider.name}</h3>
      </ProviderPreviewCardBody>
    </ProviderPreviewCardWrapper>
  )
}
