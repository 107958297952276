import * as R from "ramda"
// import { ID } from "models/utils"
// import { Event, EventFull, EventAmbiguous } from "models/events"
// import { User, UserID } from "models/auth"
// import { ProviderFull } from "models/providers"
// import { Review, ReviewFull } from "models/reviews"
// import { listIncludesId } from "utils/misc"
import { isStringOrNumber } from "utils/parseUtils"
import { isUserInstance } from "utils/users"
import { isProviderInstance, isAnyProviderInstance } from "utils/providers"

import { Event, EventCardPreview, NormalizedEvent, EventFull } from "types/events"
import { Review } from "types/reviews"
import { User, UserID } from "types/user"
import { ProviderFull } from "types/providers"

const byOccurred = R.groupBy((event: Event) => {
  const { date, end_date, ongoing } = event
  const today = new Date()

  if (ongoing) return "ongoing"

  // no date decided
  if (!date && !end_date && !ongoing) return "undecided"

  // start date hasn't happened yet and isn't today
  if (new Date(date) > today) return "upcoming"

  // start date is today or after today and end_date is today or coming up
  if (end_date && today >= new Date(date) && new Date(end_date) >= today) return "ongoing"

  // event is occurring today
  if (new Date(date) === today) return "ongoing"

  // event has already occurred
  if (new Date(date) < today) return "hosted"

  return "undecided"
})

// export const groupEventsByDate = (events: Event[]) => byOccurred(isDefined(events) ? events : [])
export const groupEventsByDate = (events: Event[]) => byOccurred(!R.isNil(events) ? events : [])

export const groupEventsById = (events: Event[]) => R.indexBy((e) => e.id, events)

export const groupEventsBySlug = (events: Event[]) => R.indexBy((e) => e.slug, events)

// remove reviews and registrations, replace with ids
export const normalizeEventsFromServer = (events: Event[]): NormalizedEvent[] => {
  return R.map(
    (event) => ({
      ...event,
      provider: isAnyProviderInstance(event.provider) ? event.provider.id : event.provider,
      reviews: R.map((review: Review) => review.id, event.reviews),
      // registrations: R.map((registration) => roster.id, event.rosters),
    }),
    events
  )
}

export const userIsOwnerOfEvent = (event: Event, user: User | UserID): boolean => {
  if (!isEventInstance(event)) return false

  const userId = isUserInstance(user) ? user.id : user

  return isUserInstance(event.owner) ? event.owner.id === userId : event.owner === userId
}

export const checkIfUserHasReviewedEvent = ({ event, user }: { event: Event; user: User }): boolean => {
  if (!user) return false
  if (!event) return false

  const hasReviewed = isUserInstance(user)
    ? R.includes(
        user.id,
        R.map((r: Review) => r.owner, event.reviews)
      )
    : R.includes(
        user,
        R.map((r: Review) => r.owner, event.reviews)
      )

  return Boolean(hasReviewed)
}

export const isEventInstance = (event: any): event is Event => {
  if (!event) return false
  if (isStringOrNumber(event)) return false

  if (
    R.all(
      (p) => event.hasOwnProperty(p) // check that event contains all required
    )(
      ["id", "name", "event_type"] // required props for a event model
    )
  ) {
    return true
  }

  return false
}

export const createEventCardPreviewFromEvent = (event: Event): EventCardPreview => {
  return event as EventCardPreview
}

export const extractEventsFromProvider = (provider: ProviderFull): EventFull[] => {
  if (provider.events) {
    // return provider.events
    return R.filter((event) => isEventInstance(event), provider.events as EventFull[])
  }

  return []
}
