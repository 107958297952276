import { ID, ImageCreate, Error, ResourceMeta, Slug, Timestamp } from "types/common"
import { UserID, User } from "types/user"
import { Review, ReviewID, AggregateRatings } from "types/reviews"
import { ProviderApprovalStatus } from "constants/providers"

// import { Provider, ProviderID } from "types/providers"

/**
 * EVENT TYPES
 */
export type EventID = ID
export type EventType = "workshop" | "talk" | "meetup" | "coaching" | "immersive" | "conference"
export type EventStatus = "pending" | "in_development" | "confirmed" | "cancelled" | "ongoing" | "completed"

// Hierarchy:   EventBase   -> EventCore    -> EventStandard
// attributes:  Only common -> All required -> What to normally expected from the server

// HERE TO PREVENT CIRCULAR IMPORTS
type ProviderID = ID
export interface ProviderSimple {
  id: ProviderID
  name: string
  image?: string
  slug: Slug
  imgix_src?: string
  description?: string
  website?: string
  join_code?: string
  is_active: boolean
  verified: boolean
  approval_status: ProviderApprovalStatus
  owner: UserID | User
  location?: Location
  created_at: Timestamp
  updated_at: Timestamp
}

export interface EventBase {
  name?: string
  description?: string
  event_type?: EventType
  price?: number
  date?: Timestamp
  end_date?: Timestamp
  virtual?: boolean
  ongoing?: boolean
  allows_registrations?: boolean
  location?: Location
  status?: EventStatus
}
export interface EventCore extends EventBase {
  id: EventID
  name: string
  description: string
  event_type: EventType
  slug: Slug
  //  owner: User | UserID
  owner: UserID
  provider?: ProviderID | ProviderSimple
  image?: string
  imgix_src?: string
  created_at: Timestamp
  updated_at: Timestamp
}
export interface EventStandard extends EventCore {
  provider_slug?: Slug
  rating?: number
  aggregate_ratings?: AggregateRatings
  user_has_favorited?: boolean
  //  registrations?: Registration[]
  user_reported_attending?: boolean
}
export interface EventCreate extends EventBase {
  name: string
  description: string
  event_type: EventType
  provider?: ProviderID
  image?: ImageCreate
}
export interface EventUpdate extends EventBase {
  provider?: ProviderID
  image?: ImageCreate
}
export interface EventPreview extends Omit<EventCore, "description"> {
  description?: string
  is_owner?: boolean
  attended?: boolean
  reviewed?: boolean
  is_registered?: boolean
  provider_name?: string
}
export interface EventStandardWithRegistrations extends EventStandard {
  //  registrations?: Registration[]
}
export interface EventFull extends EventStandardWithRegistrations {
  provider: ProviderSimple
  // provider: ProviderSimple
  reviews: Review[]
  //  owner: User
}
export interface EventTruncated extends EventStandardWithRegistrations {
  provider: ProviderID
  reviews: ReviewID[]
  //  owner: UserID
}
export interface EventSimple extends Partial<EventStandardWithRegistrations> {
  provider?: ProviderID | ProviderSimple
}
export interface EventCardPreview extends EventStandard {
  provider: ProviderSimple
}
export interface NormalizedEvent extends EventStandard {
  provider: ProviderID
  registrations?: UserID[]
  owner: UserID
  reviews: ReviewID[]
}
export interface NormalizedEventWithProvider extends Omit<NormalizedEvent, "provider"> {
  provider: ProviderID | ProviderSimple
}
export interface NormalizedEventWithReviewsAndProvider extends Omit<NormalizedEvent, "provider" | "reviews"> {
  provider: ProviderID | ProviderSimple
  reviews: Review[]
}
export type Event = EventFull | NormalizedEvent | NormalizedEventWithProvider | NormalizedEventWithReviewsAndProvider

export interface EventsState {
  isCreating: boolean
  isLoading: boolean
  isUpdating: boolean
  error: Error
  data: Record<EventID, NormalizedEvent>
  eventSlugToIdLookup: Record<Slug, EventID>
  eventSlugToMetaLookup: Record<Slug, ResourceMeta>
}

export enum EventsActionType {
  CREATE_EVENT = "@@events/CREATE_EVENT",
  CREATE_EVENT_SUCCESS = "@@events/CREATE_EVENT_SUCCESS",
  CREATE_EVENT_FAILURE = "@@events/CREATE_EVENT_FAILURE",

  FETCH_EVENT = "@@events/FETCH_EVENT",
  FETCH_EVENT_SUCCESS = "@@events/FETCH_EVENT_SUCCESS",
  FETCH_EVENT_FAILURE = "@@events/FETCH_EVENT_FAILURE",

  FETCH_EVENTS_FROM_SLUG_LIST = "@@events/FETCH_EVENTS_FROM_SLUG_LIST",
  FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS = "@@events/FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS",
  FETCH_EVENTS_FROM_SLUG_LIST_FAILURE = "@@events/FETCH_EVENTS_FROM_SLUG_LIST_FAILURE",

  UPDATE_EVENT = "@@events/UPDATE_EVENT",
  UPDATE_EVENT_SUCCESS = "@@events/UPDATE_EVENT_SUCCESS",
  UPDATE_EVENT_FAILURE = "@@events/UPDATE_EVENT_FAILURE",

  FETCH_USER_EVENTS = "@@events/FETCH_USER_EVENTS",
  FETCH_USER_EVENTS_SUCCESS = "@@events/FETCH_USER_EVENTS_SUCCESS",
  FETCH_USER_EVENTS_FAILURE = "@@events/FETCH_USER_EVENTS_FAILURE",

  CLEAR_EVENT = "@@events/CLEAR_EVENT",
  CLEAR_EVENT_STATE = "@@events/CLEAR_EVENT_STATE",
  SET_EVENT_AS_CURRENT_EVENT = "@@events/SET_EVENT_AS_CURRENT_EVENT",
  SET_EVENT_FOUR_OH_FOUR_SLUG = "@@events/SET_EVENT_FOUR_OH_FOUR_SLUG",
}

// GENERIC EVENT ACTIONS
export interface IClearEventState {
  type: EventsActionType.CLEAR_EVENT_STATE
}
export interface IClearEvent {
  type: EventsActionType.CLEAR_EVENT
}
export interface ISetEventAsCurrentEvent {
  type: EventsActionType.SET_EVENT_AS_CURRENT_EVENT
  data: Event | ID
}
export interface ISetEventFourOhFourSlug {
  type: EventsActionType.SET_EVENT_FOUR_OH_FOUR_SLUG
  slug: string
}
export type GenericEventAction = IClearEventState | IClearEvent | ISetEventAsCurrentEvent | ISetEventFourOhFourSlug

// CREATE EVENT ACTIONS
export interface ICreateEvent {
  type: EventsActionType.CREATE_EVENT
}
export interface ICreateEventSuccess {
  type: EventsActionType.CREATE_EVENT_SUCCESS
  data: Event
}
export interface ICreateEventFailure {
  type: EventsActionType.CREATE_EVENT_FAILURE
  error: Error
}
export type CreateEventAction = ICreateEvent | ICreateEventSuccess | ICreateEventFailure

// FETCH EVENT ACTIONS
export interface IFetchEvent {
  type: EventsActionType.FETCH_EVENT
}
export interface IFetchEventSuccess {
  type: EventsActionType.FETCH_EVENT_SUCCESS
  data: Event
}
export interface IFetchEventFailure {
  type: EventsActionType.FETCH_EVENT_FAILURE
  error: Error
}
export type FetchEventAction = IFetchEvent | IFetchEventSuccess | IFetchEventFailure

// FETCH MULTIPLE EVENT ACTIONS
export interface IFetchEventsFromSlugList {
  type: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST
}
export interface IFetchEventsFromSlugListSuccess {
  type: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS
  data: Event[]
}
export interface IFetchEventsFromSlugListFailure {
  type: EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_FAILURE
  error: Error
}
export type FetchEventsFromSlugAction =
  | IFetchEventsFromSlugList
  | IFetchEventsFromSlugListSuccess
  | IFetchEventsFromSlugListFailure

// UPDATE EVENT ACTIONS
export interface IUpdateEvent {
  type: EventsActionType.UPDATE_EVENT
}
export interface IUpdateEventSuccess {
  type: EventsActionType.UPDATE_EVENT_SUCCESS
  data: Event
}
export interface IUpdateEventFailure {
  type: EventsActionType.UPDATE_EVENT_FAILURE
  error: Error
}
export type UpdateEventAction = IUpdateEvent | IUpdateEventSuccess | IUpdateEventFailure

// FETCH ALL USER EVENTS ACTIONS
export interface IFetchUserEvents {
  type: EventsActionType.FETCH_USER_EVENTS
}
export interface IFetchUserEventsSuccess {
  type: EventsActionType.FETCH_USER_EVENTS_SUCCESS
  data: Event[]
}
export interface IFetchUserEventsFailure {
  type: EventsActionType.FETCH_USER_EVENTS_FAILURE
  error: Error
}
export type FetchUserEventsAction = IFetchUserEvents | IFetchUserEventsSuccess | IFetchUserEventsFailure

export type EventAction =
  | GenericEventAction
  | CreateEventAction
  | FetchEventAction
  | FetchEventsFromSlugAction
  | UpdateEventAction
  | FetchUserEventsAction

export type SingleEventSuccessAction = IUpdateEventSuccess | IFetchEventSuccess | ICreateEventSuccess

export type SingleEventFailureAction = IUpdateEventFailure | IFetchEventFailure | ICreateEventFailure

export type MultipleEventsSuccessAction = IFetchEventsFromSlugListSuccess | IFetchUserEventsSuccess
