import Link from "next/link"
import { Card, Stars } from "components"
import { EmptyPromptCard } from "views"
import styled from "styled-components"

import { Review } from "types/reviews"

import { URLS } from "utils/urls"

const ProfileReviewListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
// const EmptyPromptCard = styled(Card)`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   justify-content: center;
//   padding: 1rem 2rem;
//   font-weight: bold;
//   border-radius: 0.25rem;
//   z-index: var(--z-index-small);
//   position: relative;
// `
const ReviewPreviewCard = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  padding: 1rem 2rem;
  border-radius: 0.25rem;
  margin-top: 1rem;
  z-index: var(--z-index-small);
  position: relative;
  color: #212121;

  &:hover {
    & strong {
      color: var(--color-primary);
    }
  }
`

// function ReviewPreview({ review }) {
//   return (
//     <Link href={`/reviews/v/${review.id}`}>
//       <ReviewPreviewCard elevation={1}>
//         <span>
//           Review for <strong>{review.event_name}</strong>{" "}
//         </span>
//         <span>
//           <Stars rating={review.rating} />
//         </span>
//       </ReviewPreviewCard>
//     </Link>
//   )
// }

interface IReviewPreview {
  review: Review
}
function ReviewPreview({ review }: IReviewPreview) {
  return (
    <Link href={URLS.reviews_by_id(review.id)}>
      <ReviewPreviewCard elevation={"med"}>
        <span>
          Review for <strong>{review.event_name}</strong>{" "}
        </span>
        <span>
          <Stars rating={review.rating} />
        </span>
      </ReviewPreviewCard>
    </Link>
  )
}

interface IProfileReviewList {
  reviews: Review[]
}
export default function ProfileReviewList({ reviews }: IProfileReviewList) {
  return (
    <ProfileReviewListWrapper>
      {reviews?.length === 0 || !reviews?.length ? (
        <EmptyPromptCard elevation={"med"}>
          <h3>No reviews to show.</h3>
        </EmptyPromptCard>
      ) : null}
      {reviews?.map((review) => (
        <ReviewPreview review={review} key={review.id} />
      ))}
    </ProfileReviewListWrapper>
  )
}
