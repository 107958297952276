import { useState } from "react"
import Link from "next/link"
import { connect } from "react-redux"
import { useRouter } from "next/router"
import {
  Button,
  Card,
  //  IconQuestionnaireCheck,
  Spinner,
} from "components"

import { Actions as authActions, ConfirmAccountParams } from "models/auth"
import { useAuthedUserProfile } from "hooks/profiles/useAuthedUserProfile"
import { ApiClientCallbackResponse } from "services/apiClient"

import styled from "styled-components"

const VerifyEmailCard = styled((props) => <Card {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin: 50px auto 0;
  min-height: 400px;

  padding: 2rem;

  // max-width: 64rem;
  width: 40rem;
  max-width: var(--mobile-width);
  /* margin-top: auto; */
  border-radius: 0.25rem;

  position: relative;
  z-index: var(--z-index-small);

  & > div {
    flex: 1;
    // max-width: 20rem;
  }
`

const CenteredDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & img {
    margin: 1rem;
    max-height: 250px;
  }

  & button {
    font-size: 2rem;
    padding: 1rem;
  }
`
const StyledButton = styled(Button)`
  margin-top: auto;
`
const SmallRed = styled.small`
  color: var(--color-accent);
  font-family: var(--font-primary);
  padding: 0.5rem 1rem;
  font-size: 18px;
`

interface IVerifyEmailForm {
  confirmAccount: (p: ConfirmAccountParams) => Promise<ApiClientCallbackResponse>
}
function VerifyEmailForm({ confirmAccount }: IVerifyEmailForm) {
  const router = useRouter()
  const { token } = router.query
  // const { user, authIsLoading, authError } = useAuthenticatedUserAndProfile()
  const { userProfile, authIsLoading, authError } = useAuthedUserProfile()

  const [state, setState] = useState({
    isLoading: false,
    password: { value: "", iconStroke: "rgb(152, 152, 172)" },
    localError: null,
    message: null,
    touched: false,
    token: null,
    inputErrors: {},
    confirmed: false,
    error: null,
  })

  const error = state.error || state.localError || authError

  const handleSubmit = async () => {
    const res = await confirmAccount({ token: token as string })
    if (!res.success) {
      setState((state) => ({ ...state, error: `Something went wrong. Contact support.` }))
    }
  }

  if (!userProfile) return <Spinner size="lg" />

  if (userProfile?.email_verified) {
    return (
      <VerifyEmailCard>
        <h3>Verify your email</h3>
        <CenteredDiv>
          <img src={"/assets/images/undraw_verified.svg"} alt="verified logo" />
          <section>
            {/* <IconQuestionnaireCheck /> */}
            <h2>Welcome to Velup!</h2>
            <p>Your account has been successfully verified.</p>
          </section>
        </CenteredDiv>
      </VerifyEmailCard>
    )
  }

  if (!token) {
    return (
      <VerifyEmailCard>
        <CenteredDiv style={{ justifyContent: "center" }}>
          <h2>Unable to verify account.</h2>
          <p>
            Your token has expired or is invalid. Click <Link href="/profile">here</Link> to get another one.
          </p>
        </CenteredDiv>
      </VerifyEmailCard>
    )
  }

  return (
    <VerifyEmailCard>
      {/* ONLY SHOW THE MESSAGE IF THE USER HASN'T TYPED ANYTHING */}
      {!state.touched && state.message && <SmallRed>{state.message}</SmallRed>}
      <h3>Click on the button below to verify your email</h3>
      {error && <SmallRed>{error}</SmallRed>}
      {/* <>{state?.error ? <ErrorBox message={state.error} /> : null}</> */}
      <CenteredDiv>
        <img src={"/assets/images/undraw_verified.svg"} alt="verified logo" />
        <StyledButton type="submit" onClick={() => handleSubmit()} isLoading={state.isLoading || authIsLoading}>
          Verify Account
        </StyledButton>
      </CenteredDiv>
    </VerifyEmailCard>
  )
}

export default connect(null, { confirmAccount: authActions.confirmAccount })(VerifyEmailForm)
