import { hsl as d3Hsl } from "d3-color"
import { Spacer, Stars } from "components"
import styled from "styled-components"

import { AggregateRatings as AggregateRatingsType } from "types/reviews"

// const EventReviewSummaryWrapper = styled.div`
//   --row-height: 20px;

//   min-width: 300px;
//   max-width: 500px;
//   margin: 2rem 0;

//   & > h2 {
//     font-size: 1.5rem;
//     margin-bottom: 0.3rem;
//   }

//   & .star-title {
//     color: #0066c0;
//   }

//   ${media.mobileOnly`
//     max-width: var(--mobile-width);
//     min-width: 200px;
//   `}
// `
const EventReviewSummaryWrapper = styled.div`
  --row-height: 20px;

  min-width: 300px;
  max-width: 500px;
  margin: 2rem 0;

  & > h2 {
    font-size: 1.5rem;
    margin-bottom: 0.3rem;
  }

  & .star-title {
    color: #0066c0;
    color: var(--color-primary);
  }
`
const StarsAndReviewCount = styled.span`
  display: flex;
  align-items: center;
  // justify-content: space-between;
`

const RatingPercentagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 70px 1fr 70px;
  grid-column-gap: 10px;
  margin-bottom: 5px;
  transform: translateX(8px);
  align-items: center;

  & .perc {
    border: inset 1px rgb(222, 222, 222);
    background: var(--star-color-empty);
    border-radius: 1px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.4), inset 0 0 0 1px rgba(0, 0, 0, 0.1);
    height: calc(var(--row-height) + 2px);
    display: flex;
  }
`

const FillPercBar = styled.span<{ $topColor: any; $botColor: any; $fillPerc?: number }>`
  background: linear-gradient(to bottom, #ffce00, #ffa700);
  background: linear-gradient(to bottom, gold, #ffa700);
  background: linear-gradient(to bottom, ${(props) => props.$topColor}, ${(props) => props.$botColor});
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.25), inset 0 -1px 0 rgba(0, 0, 0, 0.05);
  height: var(--row-height);

  ${(props) =>
    props.$fillPerc &&
    `
    width: ${props.$fillPerc}%;
  `}
`

const topColor = d3Hsl("gold").brighter(0.1)
const botColor = d3Hsl("#ffa700").brighter(1)

interface IRatingPercentages {
  totalRatings?: number
  numStars?: number
  count?: number
  className?: string
}
const RatingPercentages: React.FC<IRatingPercentages> = ({ totalRatings, numStars, count, className = "" }) => {
  const renderStarLabel = () => {
    if (numStars === 1) {
      return <span className="star-title">{numStars} star</span>
    }

    return <span className="star-title">{numStars} stars</span>
  }

  const fillPerc = totalRatings === 0 ? 0 : Number((count / totalRatings) * 100).toFixed(0)

  return (
    <RatingPercentagesWrapper className={className}>
      {renderStarLabel()}
      <span className="perc">
        <FillPercBar $topColor={topColor} $botColor={botColor} $fillPerc={fillPerc as number}></FillPercBar>
      </span>
      <span className="perc-label">{fillPerc}%</span>
    </RatingPercentagesWrapper>
  )
}

interface IAggregateRatings {
  aggregates: AggregateRatingsType
  className?: string
}
export default function AggregateRatings({ aggregates, className = "" }: IAggregateRatings) {
  if (!aggregates) return null

  const renderAggregates = () => {
    return (
      <h3>
        ({aggregates.total_ratings} {aggregates.total_ratings === 1 ? "review" : "reviews"})
      </h3>
    )
  }

  return (
    <EventReviewSummaryWrapper className={className}>
      <StarsAndReviewCount>
        <Stars rating={aggregates?.average_rating || null} />
        {renderAggregates()}
      </StarsAndReviewCount>
      <Spacer size="md" />

      {["five_stars", "four_stars", "three_stars", "two_stars", "one_stars"].map((countLabel, i) => (
        <RatingPercentages
          count={aggregates[countLabel]}
          numStars={5 - i}
          totalRatings={aggregates?.total_ratings || 0}
          key={countLabel}
        />
      ))}
    </EventReviewSummaryWrapper>
  )
}
