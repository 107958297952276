import { Fragment } from "react"
import { Spacer } from "components"
import { EventFeedCard } from "views"
import styled from "styled-components"

import { NormalizedProviderWithEvents, NormalizedProviderWithEventsAndProfiles } from "types/providers"

const ProviderEventListWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

interface IProviderEventList {
  provider: NormalizedProviderWithEvents | NormalizedProviderWithEventsAndProfiles
}
export default function ProviderEventList({ provider }: IProviderEventList) {
  return (
    <ProviderEventListWrapper>
      {provider?.events?.length ? (
        provider.events.map((event) => (
          <Fragment key={event.id}>
            <EventFeedCard event={{ ...event, provider }} />
            {/* <EventFeedCard event={event} /> */}
            <Spacer size="lg" />
            {/* <EventFeedCard event={event} /> */}
          </Fragment>
        ))
      ) : (
        <h3>This provider doesn't have any events yet.</h3>
      )}
    </ProviderEventListWrapper>
  )
}
